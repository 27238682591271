import utils from "@/utils";
import url from "./index"

// 获取微信公众号
export function getWXAccount(accountId) {
    return utils.getHttp(url.WX_ACCOUNT_PATH + accountId);
}

// 获取微信用户信息
export function getWXUserInfo(accountId, code) {
    return utils.getHttp(url.GET_WX_USER_INFO, {
        accountId: accountId,
        code: code
    });
}

// 获取微信网页参数
export function getWXParam(accountId, pageUrl) {
    return utils.getHttp(url.GET_WXPARAM, {
        accountId: accountId,
        pageUrl: pageUrl
    });
}

// 查询医生openId
export function getOpenId(accountId, doctorId) {
    return utils.getHttp(url.GET_OPEN_ID, {
        accountId: accountId,
        doctorId: doctorId
    });
}
