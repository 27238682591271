<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr lh24">
      <p class="f22 mt50 fb">{{ $t("lang.inputSms") }}</p>
      <p class="f14 c_gray1 mt10">{{ $t("lang.smsSend") }}{{ mobile }}</p>
      <div class="pas_area mt20">
        <!-- 密码输入框 -->
        <van-password-input
            :value="smsCode"
            :gutter="10"
            :focused="showKeyboard"
            @focus="showKeyboard = true"
        />

        <!-- 数字键盘 -->
        <van-number-keyboard
            v-model="smsCode"
            :show="showKeyboard"
            @blur="showKeyboard = false"
        />
      </div>
      <div v-show="showCountDown" class="mt15 f13 c_gray of">
        <van-count-down ref="countDown" :auto-start="false" @finish="timeFinish" class="left mt2" format="ss"
                        :time="time"/>
        <p class="left">{{ $t("lang.smsLast") }}</p>
      </div>
      <div v-show="!showCountDown" class="mt15 f13 c_gray">
        <a @click="getSmsCode">{{ $t("lang.reSendSms") }}</a>
      </div>
    </div>
  </section>
</template>

<script>
import {getSms, addDoctor} from "@/services/doctor";
import constants from "@/constants";
import {Toast} from 'vant';
import i18n from "@/lang";
import utils from "@/utils";

export default {
  name: "AuthCode",
  data() {
    return {
      showKeyboard: true,
      time: 60 * 1000,
      mobile: this.$store.state.doctorWholeObj.mobile,
      smsCode: '',
      encryptionCode: '',
      showCountDown: true,
    };
  },
  methods: {
    // 获取短信验证码
    getSmsCode: async function () {
      if (this.mobile) {
        let resultData = await getSms(this.mobile);
        if (constants.AJAX_OK === resultData.code && resultData.data) {
          this.showCountDown = true
          this.$refs.countDown.reset();
          this.$refs.countDown.start();

          this.encryptionCode = resultData.data
        }
      }
    },
    // 验证码超时
    timeFinish: function () {
      this.encryptionCode = ""
      this.showCountDown = false
    },
    // 错误提示
    errorTip: (tip) => {
      Toast({
        message: tip,
        position: 'bottom',
      });
    }
  },
  mounted() {
    this.getSmsCode()
  },
  watch: {
    async smsCode(value) {
      if (value.length === 6) {
        // 验证验证码
        if (utils.md5_16(value) !== this.encryptionCode) {
          this.errorTip(i18n.t("lang.smsError"));
        } else {
          let doc = this.$store.state.doctorWholeObj
          doc.accountId = this.$store.state.cacheAccountId
          doc.openId = this.$store.state.cacheOpenId
          doc.unionId = this.$store.state.unionId
          doc.smsEncodeCoder = utils.md5_16(value)

          let resultData = await addDoctor(doc);
          // 注册成功
          if (constants.AJAX_OK === resultData.code && resultData.data) {
            this.$store.commit("setDoctorWholeObj", resultData.data)

            // 保存openId，accountId进入缓存
            utils.setStorage(constants.WX_OPENID, doc.openId);
            utils.setStorage(constants.WX_ACCOUNT_ID, doc.accountId);

            await this.$router.push("/deviceCenter")

          } else if (constants.LOGIN_ERR === resultData.code) {
            this.errorTip(i18n.t("lang.loginFail"));
          } else if (constants.SMS_ERR === resultData.code) {
            this.errorTip(i18n.t("lang.smsError"));
          }
        }
      }
    },
  },
}
</script>

<style scoped>
.pas_area .van-password-input {
  margin: 0px;
}

.mt2 {
  margin-top: 2px;
}
</style>
