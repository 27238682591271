<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr lh24">
      <p class="f22 mt50 fb">{{ $store.state.doctorWholeObj.nameAlias }}</p>
      <p class="f14 c_gray1 mt10">{{ $t("lang.loginTip") }}</p>

      <!-- 姓名 -->
      <div class="bb_gray mt30">
        <van-cell class="list_radius van_bg_zdy1">
          <van-field v-model="login_name" :label="$t('lang.loginName')" :error-message="nameErrorMsg"
                     :placeholder="$t('lang.pleaseInput')"/>
        </van-cell>
      </div>

      <!-- 手机 -->
      <div class="bb_gray mt10">
        <van-cell class="list_radius van_bg_zdy1">
          <van-field v-model="login_mobile" :label="$t('lang.loginMobile')" :error-message="mobileErrorMsg"
                     :placeholder="$t('lang.pleaseInput')"/>
        </van-cell>
      </div>

      <van-button @click="nextStep" class="mt30" type="primary" block>{{ $t("lang.nextStep") }}</van-button>

      <div class="mt10 f12 c_gray">
        <p class="left">{{ $t("lang.loginAgree") }}</p>
        <van-button to="/serviceAgreement" class="left" size="mini" color="#EDEDED" type="primary">
          <span class="f12 c-blue1">{{ $t("lang.loginTerm") }}</span>
        </van-button>
        <p class="left">、</p>
        <van-button to="/privacyProtection" class="left" size="mini" color="#EDEDED" type="primary" >
          <span class="f12 c-blue1">{{ $t("lang.loginPrivacy") }}</span>
        </van-button>
      </div>
    </div>
  </section>
</template>

<script>
import utils from "@/utils";
import i18n from "@/lang";
import {getDoctorByMobile} from "@/services/doctor";
import constants from "@/constants";
import {Toast} from 'vant';

export default {
  name: "Login",
  data() {
    return {
      login_name: this.$store.state.doctorWholeObj.nameAlias ? this.$store.state.doctorWholeObj.nameAlias : "",
      login_mobile: "",
      nameErrorMsg: "",
      mobileErrorMsg: "",
    }
  },
  methods: {
    // 下一步
    nextStep: async function () {
      this.nameErrorMsg = ""
      this.mobileErrorMsg = ""

      // 姓名格式判断
      if (utils.isNull(this.login_name) || this.login_name.length > 15) {
        this.nameErrorMsg = i18n.t("lang.mameInputError");
        return;
      }

      // 手机格式判断
      if (!utils.isPhoneNum(this.login_mobile)) {
        this.mobileErrorMsg = i18n.t("lang.mobileInputError");
        return;
      }

      // 手机是否已被使用判断
      let resultData = await getDoctorByMobile(this.login_mobile);
      if (constants.AJAX_OK === resultData.code && resultData.data && resultData.data.length > 0) {
        Toast({
          message: i18n.t("lang.mobileInUse"),
          position: 'bottom',
        });
        return;
      }

      // 临时保存姓名、手机号
      let doctor = this.$store.state.doctorWholeObj;
      doctor.nameAlias = this.login_name
      doctor.mobile = this.login_mobile

      this.$store.commit("setDoctorWholeObj", doctor);

      await this.$router.push("/sendSms")
    }
  },
  mounted() {
    this.login_name = this.$store.state.doctorWholeObj.nameAlias
  }
}
</script>

<style scoped>
.van_bg_zdy1 .van-cell {
  background-color: #EDEDED;
  padding: 10px 0px;
}
</style>
