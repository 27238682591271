<template>
    <section class="van-doc-demo-section">
        <van-pull-refresh v-model="isRefreshLoading" @refresh="onRefresh">
            <div class="m15-00 dfr">

                <div v-for="(item, index) in errorList" :key="index" class="mb5 bc_fff of yuan6">
                    <van-image class="left w16 h16 ml10 mt8 mb8" :src="require('@/assets/img/call_the_police.png')"/>
                    <p class="left f12 mt8 ml5 c-orange">{{ getWarnInfo(item.errorDesc) }}</p>
                </div>

                <div v-if="x1List.length > 0 && !editor" class="mb5 bc_fff of yuan6">
                    <van-image class="left w16 h16 ml10 mt8 mb8" :src="require('@/assets/img/call_the_police.png')"/>
                    <!--请登记新患者信息-->
                    <p class="left f12 mt8 ml5 c-orange">{{ $t("lang.device_dh_regnewpati") }}</p>
                    <van-button to="/patientInfo" class="right" color="#f64900" size="small">
                        <!--现在登记-->
                        <span class="p0005 fb">{{ $t("lang.device_dh_nowreg") }}</span>
                    </van-button>
                </div>

                <!-- 基本信息 -->
                <div class="of">
                    <van-image class="left mt14 w44 h44 ml10"
                               :src="require('@/assets/img/electric_negative_pressure_aspirator.png')"/>
                    <div class="left">
                        <div class="of">
                            <p class="fb f18 left lh32">{{ dv_device.titleDesc }}</p> <!--二病区 3床 王立军-->
                            <van-button to="/patientInfo" class="left" color="#EDEDED"
                                        :icon="require('@/assets/img/list_edit.png')"
                                        type="info" size="small" round>
                            </van-button>
                        </div>
                        <p class="f14">{{ dv_device.instName }} {{ dv_device.patDeptName }}</p>
                        <p class="f12 c_gray1 lh24">{{ getDvTime() }}</p>
                    </div>
                </div>

                <!-- 编辑人 -->
                <div class="de_grey_line mt5"></div>
                <div class="mt5 of pt5">
                    <p class="f12 left lh24">{{ $t("lang.newEditor") }} {{ editor }}</p>
                    <van-button v-if="editor" to="/editRecord" class="right bc_999" color="#828999" plain round
                                size="mini">
                        <span class="p0005 fb">{{ $t("lang.analog1Record") }}</span>
                    </van-button>
                </div>

                <!-- 实时监控数据 -->
                <div class="yuan6 of mt10 bc_black lh24">
                    <div class="of p0510 op8 bc_black1">
                        <p class="left c_white f12">{{ $t("lang.analog1Data") }}</p>
                        <van-loading v-show="isShowLoading" class="left ml5" size="15"/>
                        <p class="right c_white f12">{{ $t("lang.analog1Update") }}{{ recordTime }}</p>
                    </div>
                    <div class="p10100510">
                        <van-row gutter="10">
                            <van-col span="12">
                                <div class="yuan6 de_blue_border1">
                                    <div class="of p10050505 de_bg_blue1" v-if="setPress != 60">
                                        <p class="left f12">{{ $t("lang.analog1SetPressure") }}</p>
                                        <p class="right f10">cmH2O</p>
                                        <p class="right f22 mt-4">{{ setPress }}</p>
                                    </div>
									<div class="of p10050505 de_bg_blue1" v-else style="font-size: 20px;text-align: center;">
										重力引流
									</div>
                                    <div class="of de_blue_word1 p5">
                                        <van-row gutter="0">
                                            <van-col span="10">
                                                <p class="left f12 lh70">{{ $t("lang.analog1WavePressure") }}</p>
                                            </van-col>
                                            <van-col span="14">
                                                <div class="of pt10">
                                                    <p class="right f10">cmH2O</p>
                                                    <p class="right f22 mt-4">{{ pressSurgeH }}</p>
                                                </div>
                                                <div class="de_blue_line"></div>
                                                <div class="of pt10">
                                                    <p class="right f10">cmH2O</p>
                                                    <p class="right f22 mt-4">{{ pressSurgeL }}</p>
                                                </div>
                                            </van-col>
                                        </van-row>
                                    </div>
                                </div>
                                <div class="of p10050505 c_white mt5">
                                    <p class="left f12">{{ $t("lang.analog1GasLeakage") }}</p>
                                    <p class="right f10">mL/min</p>
                                    <p class="right f22 mt-4">{{ gasLeak }}</p>
                                </div>
                            </van-col>
                            <van-col span="12">
                                <div class="yuan6 of de_blue_border2">
                                    <div class="of p10050505 de_blue_word2">
                                        <p class="left f12">{{ $t("lang.analog1StartTime") }}</p>
                                        <p class="right f22 mt-4">{{ startTime }}</p>
                                    </div>
                                    <div class="of p10050505 de_blue_word2">
                                        <p class="left f12">{{ $t("lang.analog1TotalTime") }}</p>
                                        <p class="right f10">min</p>
                                        <p class="right f22 mt-4">{{ stageDurationMin }}</p>
                                        <p class="right f10">h</p>
                                        <p class="right f22 mt-4">{{ stageDurationHour }}</p>
                                    </div>
                                    <div class="de_black_line"></div>
                                    <div class="of p10050505 de_green_word">
                                        <p class="left f12">{{ $t("lang.analog1TotalLiquid") }}</p>
                                        <p class="right f10">mL</p>
                                        <p class="right f22 mt-4">{{ stageLiquidLevel }}</p>
                                    </div>
                                </div>
                                <div class="of p10050505 de_green_word mt5">
                                    <p class="left f12">{{ $t("lang.analog1SumLiquid") }}</p>
                                    <p class="right f10">mL</p>
                                    <p class="right f22 mt-4">{{ drainageTotal }}</p>
                                </div>
                            </van-col>
                        </van-row>
                    </div>
                    <div class="van_bg_zdy">
                        <van-cell @click="gotoAlarmRecord" :title="$t('lang.analog1WarnRecord')" is-link
                                  :icon="require('@/assets/img/mention_his.png')"/>
                    </div>
                </div>

                <!-- 胸腔气体泄漏 -->
                <div class="yuan6 of mt5 bc_fff">
                    <div class="of p10">
                        <van-image class="left w14 h14 mt5" :src="require('@/assets/img/pressure_relief_his.png')"/>
                        <p class="left f14 ml5 lh24">{{ $t("lang.analog1GasDecompression") }}</p>

                        <div v-show="!chartType1">
                            <van-button @click="controlTo10_1" class="right" color="#EAEAEA" round size="mini">
                                <span class="p0005 c_gray1 fb">10D</span>
                            </van-button>
                            <van-button class="right mr5 bc_orange" color="#FC8936" plain round size="mini">
                                <span class="p0005 fb">24H</span>
                            </van-button>
                        </div>

                        <div v-show="chartType1">
                            <van-button class="right bc_orange" color="#FC8936" plain round size="mini">
                                <span class="p0005 fb">10D</span>
                            </van-button>
                            <van-button @click="controlTo24_1" class="right mr5" color="#EAEAEA" round size="mini">
                                <span class="p0005 c_gray1 fb">24H</span>
                            </van-button>
                        </div>
                    </div>
                    <pressure-relief-chart :trend-type="chartType1" :data-list="y1List"
                                           :x-list="x1List"></pressure-relief-chart>
                </div>

                <!-- 引流液体累计 -->
                <div class="yuan6 of mt5 bc_fff">
                    <div class="of p10">
                        <van-image class="left w14 h14 mt5" :src="require('@/assets/img/drainage_fluid_his.png')"/>
                        <p class="left f14 ml5 lh24">{{ $t("lang.analog1TotalLiquid2") }}</p>

                        <div v-show="!chartType2">
                            <van-button @click="controlTo10_2" class="right" color="#EAEAEA" round size="mini">
                                <span class="p0005 c_gray1 fb">10D</span>
                            </van-button>
                            <van-button class="right mr5 bc_orange" color="#FC8936" plain round size="mini">
                                <span class="p0005 fb">24H</span>
                            </van-button>
                        </div>

                        <div v-show="chartType2">
                            <van-button class="right bc_orange" color="#FC8936" plain round size="mini">
                                <span class="p0005 fb">10D</span>
                            </van-button>
                            <van-button @click="controlTo24_2" class="right mr5" color="#EAEAEA" round size="mini">
                                <span class="p0005 c_gray1 fb">24H</span>
                            </van-button>
                        </div>
                    </div>
                    <drainage-fluid-chart :trend-type="chartType2" :data-list="y2List"
                                          :x-list="x2List"></drainage-fluid-chart>
                </div>

                <!-- 胸腔压力波动 -->
                <div class="yuan6 of mt5 bc_fff">
                    <div class="of p10">
                        <van-image class="left w14 h14 mt5"
                                   :src="require('@/assets/img/pressure_fluctuation_his.png')"/>
                        <p class="left f14 ml5 lh24">{{ $t("lang.analog1PressureFluctuation") }}</p>

                        <div v-show="!chartType3">
                            <van-button @click="controlTo10_3" class="right" color="#EAEAEA" round size="mini">
                                <span class="p0005 c_gray1 fb">10D</span>
                            </van-button>
                            <van-button class="right mr5 bc_orange" color="#FC8936" plain round size="mini">
                                <span class="p0005 fb">24H</span>
                            </van-button>
                        </div>

                        <div v-show="chartType3">
                            <van-button class="right bc_orange" color="#FC8936" plain round size="mini">
                                <span class="p0005 fb">10D</span>
                            </van-button>
                            <van-button @click="controlTo24_3" class="right mr5" color="#EAEAEA" round size="mini">
                                <span class="p0005 c_gray1 fb">24H</span>
                            </van-button>
                        </div>
                    </div>
                    <pressure-fluctuation-chart :high-list="y3List_high" :low-list="y3List_low"
                                                :x-list="x3List"></pressure-fluctuation-chart>
                </div>

                <!-- 设备患者数据 -->
                <van-cell class="list_radius mt5 van_bg_zdy1">
                    <van-cell @click="onClick2GetPatiDvCount" :title="$t('lang.analog1HistoryPat')"
                              :icon="require('@/assets/img/historical_patients.png')"
                              is-link/>
                </van-cell>

                <!-- 设备设置 -->
                <van-cell class="list_radius mt5 van_bg_zdy1">
                    <van-cell to="/editDeviceInfo" :title="$t('lang.analog1DeviceSet')"
                              :icon="require('@/assets/img/device_setting.png')" is-link/>
                </van-cell>

                <!-- 取消关注 -->
                <van-button v-if="doctor.admin===2" @click="onClick2ShowCancelAttent" class="mt10" block
                            color="#E3E3E3">
                    <span class="c_red">{{ $t("lang.analog1CancelAtt") }}</span>
                </van-button>
            </div>
        </van-pull-refresh>
    </section>
</template>

<script>
    import {cancelAttentDvDoctor} from "@/services/dvDoctor";
    import {getTrend} from "@/services/dvTrend";
    import {getLiveData} from "@/services/dvLiveData";
    import {getDeviceHisPatiCount} from "@/services/dvPatient";
    import {Dialog, Toast} from "vant";
    import constants from "@/constants";
    import i18n from "@/lang";
    import PressureReliefChart from "components/PressureReliefChart";
    import DrainageFluidChart from "components/DrainageFluidChart";
    import PressureFluctuationChart from "components/PressureFluctuationChart";
    import utils from "@/utils";
    import {getDvError} from "@/services/dvError";

    export default {
        name: "DataHome",
        components: {
            PressureReliefChart, DrainageFluidChart, PressureFluctuationChart
        },
        data() {
            return {
                doctor: this.$store.state.doctorWholeObj,
                dv_device: this.$store.state.bindDeviceObj,
                // 第一个趋势图x轴
                x1List: [],
                // 第二个趋势图x轴
                x2List: [],
                // 第三个趋势图x轴
                x3List: [],
                // 第一个趋势图y轴
                y1List: [],
                // 第二个趋势图y轴
                y2List: [],
                // 第三个趋势图y轴
                y3List_high: [],
                y3List_low: [],
                // 第一个趋势图类型
                chartType1: constants.TrendType.HOUR,
                // 第二个趋势图类型
                chartType2: constants.TrendType.HOUR,
                // 第三个趋势图类型
                chartType3: constants.TrendType.HOUR,
                // 定时刷新
                timer: '',
                // 定时刷新loading
                isShowLoading: false,
                // 下拉刷新loading
                isRefreshLoading: false,
                // 设置压力
                setPress: '',
                // 实时压力
                realPress: '',
                // 气源压力
                bleedPress: '',
                // 压力波动-高值(cmH2O)
                pressSurgeH: '',
                // 压力波动-低值(cmH2O)
                pressSurgeL: '',
                // 气体泄露(mL/min)
                gasLeak: '',
                // 阶段液量
                stageLiquidLevel: '',
                // 引流总量(mL)
                drainageTotal: '',
                // 起始时间
                startTime: '',
                // 阶段时长-小时
                stageDurationHour: '',
                // 阶段时长-分钟
                stageDurationMin: '',
                // 记录时间（年月日时分秒）
                recordTime: '',
                // 编辑人
                editor: '',
                // 报警信息列表
                errorList: [],
                // 运行时间（精确到分钟）
                runTime: '',
            };
        },
        methods: {
            // 第一个趋势图切换到10D
            controlTo10_1: function () {
                this.chartType1 = constants.TrendType.DAY
                this.getTrendData(1, this.chartType1)
            },
            // 第一个趋势图切换到24H
            controlTo24_1: function () {
                this.chartType1 = constants.TrendType.HOUR
                this.getTrendData(1, this.chartType1)
            },
            // 第二个趋势图切换到10D
            controlTo10_2: function () {
                this.chartType2 = constants.TrendType.DAY
                this.getTrendData(2, this.chartType2)
            },
            // 第二个趋势图切换到24H
            controlTo24_2: function () {
                this.chartType2 = constants.TrendType.HOUR
                this.getTrendData(2, this.chartType2)
            },
            // 第三个趋势图切换到10D
            controlTo10_3: function () {
                this.chartType3 = constants.TrendType.DAY
                this.getTrendData(3, this.chartType3)
            },
            // 第三个趋势图切换到24H
            controlTo24_3: function () {
                this.chartType3 = constants.TrendType.HOUR
                this.getTrendData(3, this.chartType3)
            },
            // 获取趋势图数据
            getTrendData: async function (index, type) {
                let resultData = await getTrend(this.dv_device.patientId, this.dv_device.deviceId, type, type, type, i18n.t("lang.day"));
                if (constants.AJAX_OK === resultData.code && resultData.data) {
                    if (1 === index) {
                        this.x1List = resultData.data.prXList
                        this.y1List = resultData.data.prYList
                    } else if (2 === index) {
                        this.x2List = resultData.data.dfXList
                        this.y2List = resultData.data.dfYList
                    } else if (3 === index) {
                        this.x3List = resultData.data.pfXList
                        this.y3List_high = resultData.data.pfYList_high
                        this.y3List_low = resultData.data.pfYList_low
                    }
                }
            },
            // 定时刷新数据
            refreshData: async function () {
                this.isShowLoading = true
                let resultData = await getLiveData(this.dv_device.patientId, this.dv_device.deviceId, this.chartType1, this.chartType2, this.chartType3, i18n.t("lang.day"));
                if (constants.AJAX_OK === resultData.code && resultData.data) {
                    this.x1List = resultData.data.prXList
                    this.y1List = resultData.data.prYList

                    this.x2List = resultData.data.dfXList
                    this.y2List = resultData.data.dfYList

                    this.x3List = resultData.data.pfXList
                    this.y3List_high = resultData.data.pfYList_high
                    this.y3List_low = resultData.data.pfYList_low

                    // 实时数据
                    this.setPress = resultData.data.setPress
                    this.realPress = resultData.data.realPress
                    this.bleedPress = resultData.data.bleedPress
                    this.pressSurgeH = resultData.data.pressSurgeH
                    this.pressSurgeL = resultData.data.pressSurgeL
                    this.gasLeak = resultData.data.gasLeak
                    this.stageLiquidLevel = resultData.data.stageLiquidLevel
                    this.drainageTotal = resultData.data.drainageTotal
                    this.stageDurationHour = resultData.data.stageDurationHour
                    this.stageDurationMin = resultData.data.stageDurationMin
                    this.startTime = utils.formatDate(new Date(resultData.data.startTime), "hh:mm")
                    this.recordTime = utils.formatDate(new Date(resultData.data.recordTime), "yyyy/MM/dd hh:mm")
                    this.editor = resultData.data.editor
                    this.errorList = resultData.data.errorList
                    this.runTime = resultData.data.runTime
					console.log(this.recordTime)
                }

                setTimeout(() => {
                    this.isShowLoading = false
                }, 1000 * 2)
            },

            // 获取报警提示
            getWarnInfo(content) {
                return utils.stringFormat(i18n.t("lang.deviceWarn"), [content])
            },

            // 获取设备运行时间
            getDvTime: function () {
                let beginTime = this.dv_device.beginTime
                let content = utils.formatDate(new Date(beginTime), "yyyy/MM/dd hh:mm") + i18n.t("lang.analog1Start") + ","
                    + i18n.t("lang.run");
                let runTime_h = parseInt(this.runTime / 60)
                let runTime_m = this.runTime - runTime_h * 60
                if (0 !== runTime_h) {
                    content += runTime_h + i18n.t("lang.hour2")
                }
                if (0 !== runTime_m) {
                    content += runTime_m + i18n.t("lang.min2")
                }
                return content
            },

            // 进入报警记录页面
            gotoAlarmRecord: async function () {
                let resultData = await getDvError(this.dv_device.patientId, this.dv_device.deviceId);
                if (constants.AJAX_OK === resultData.code && resultData.data && utils.isNotNull(resultData.data)) {
                    this.$router.push("/alarmRecord")
                } else {
                    Toast({
                        message: i18n.t("lang.noAlarmRecord"),
                        position: 'top',
                    });
                }
            },

            // 取消关注
            onClick2ShowCancelAttent() {
                Dialog.confirm({
                    title: i18n.t("lang.device_dh_cancelattent"), //取消关注
                    message: i18n.t("lang.device_dh_comcanatt"), //确定取消关注该设备?
                }).then(() => {
                    // on confirm
                    this.toCancelAttent();
                }).catch(() => {
                    // on cancel
                });
            },

            // 医生取消关注设备
            toCancelAttent: async function () {

                //  请求服务端医生取消关注设备
                let resultData = await cancelAttentDvDoctor(this.doctor.doctorId, this.dv_device.deviceId);

                // eslint-disable-next-line no-empty
                if (constants.AJAX_OK === resultData.code && resultData.data) {
                    Toast({
                        message: i18n.t("lang.device_dh_cancelsuccess"), //取消关注成功
                        position: 'bottom',
                    });
                } else {
                    Toast({
                        message: i18n.t("lang.device_dh_cancelfail"), //取消关注失败
                        position: 'bottom',
                    });
                }
            },

            //获取设备历史患者数量
            onClick2GetPatiDvCount: async function () {
                let resultData = await getDeviceHisPatiCount(this.dv_device.deviceId);
                if (constants.AJAX_OK === resultData.code && resultData.data) {

                    //历史患者
                    this.$router.push("/devicePatientList");

                } else {

                    Toast({
                        message: i18n.t("lang.device_his_nopati"), //该设备无患者数据
                        position: 'bottom',
                    });
                }
            },
            // 下拉刷新
            onRefresh: function () {
                this.refreshData();
                setTimeout(() => {
                    this.isRefreshLoading = false;
                }, 1000);
            }
        },
        mounted() {
            document.title = this.dv_device.deviceAlias
            this.refreshData();
            this.timer = setInterval(() => {
                this.refreshData();
            }, 1000 * 60 * 0.5);
        },
        beforeDestroy() {
            clearInterval(this.timer);
        }
    }
</script>

<style scoped>
    .mt-4 {
        margin-top: -4px;
    }

    .w16 {
        width: 16px;
    }

    .h16 {
        height: 16px;
    }

    .bc_black {
        background-color: #292F33;
    }

    .bc_black1 {
        background-color: #535759;
    }

    .de_blue_word1 {
        color: #C9E1FF;
    }

    .de_blue_word2 {
        color: #0CC9FA;
    }

    .de_green_word {
        color: #6EFF9B;
    }

    .yuan0600 {
        border-radius: 0px 6px 0px 0px
    }

    .de_blue_line {
        width: 100%;
        height: 2px;
        background-color: rgba(201, 225, 255, 1);
    }

    .de_blue_line_1 {
        width: 100%;
        height: 1px;
        background-color: rgba(201, 225, 255, 0.2);
    }

    .b_left_blue {
        border-left: 1px solid rgba(201, 225, 255, 0.2);
    }

    .de_black_line {
        width: 100%;
        height: 2px;
        background-color: #292F33;
    }

    .de_grey_line {
        width: 100%;
        height: 1px;
        background-color: #E3E3E3;
    }

    .de_blue_border1 {
        border: 2px solid #C9E1FF;
    }

    .de_blue_border2 {
        border: 2px solid #0CC9FA;
    }

    .de_bg_blue1 {
        background-color: #C9E1FF;
    }

    .p10050505 {
        padding: 10px 5px 5px 5px;
    }

    .p10100510 {
        padding: 10px 10px 5px 10px;
    }

    .van_bg_zdy .van-cell {
        background-color: #383C3F;
        color: #fff;
        padding: 10px;
    }

    .van_bg_zdy1 .van-cell {
        padding: 10px;
    }

    .police_part .van-notice-bar {
        padding: 0px 10px;
    }
</style>
