<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr">
      <!-- 基本信息 -->
      <div class="of">
        <van-image class="left mt14 w44 h44 ml10"
                   :src="require('@/assets/img/electric_negative_pressure_aspirator.png')"/>
        <div class="left">
          <div class="of">
            <p class="fb f18 left lh32">{{ $t("lang.analogPat") }}</p>
          </div>
          <p class="f14">{{ $t("lang.analog1Inst") }} {{ $t("lang.analog1Dep") }}</p>
          <p class="f12 c_gray1 lh24">2021/04/29 16:10{{ $t("lang.analog1Start") }}，{{ $t("lang.analog1Time") }}</p>
        </div>
      </div>

      <!-- 编辑人 -->
      <div class="de_grey_line mt5"></div>
      <div class="mt5 of pt5">
        <p class="f12 left lh24">{{ $t("lang.analog1Editor") }}</p>
      </div>

      <!-- 实时监控数据 -->
      <div class="yuan6 of mt10 bc_black lh24">
        <div class="of p0510 op8 bc_black1">
          <p class="left c_white f12">{{ $t("lang.analog1Data") }}</p>
          <p class="right c_white f12">{{ $t("lang.analog1Update") }}2021/05/09 16:46</p>
        </div>
        <div class="p10100510">
          <van-row gutter="10">
            <van-col span="12">
              <div class="yuan6 de_blue_border1">
                <div class="of p10050505 de_bg_blue1">
                  <p class="left f12">{{ $t("lang.analog1SetPressure") }}</p>
                  <p class="right f10">cmH2O</p>
                  <p class="right f22 mt-4">-10</p>
                </div>
                <div class="of de_blue_word1 p5">
                  <van-row gutter="0">
                    <van-col span="10">
                      <p class="left f12 lh70">{{ $t("lang.analog1WavePressure") }}</p>
                    </van-col>
                    <van-col span="14">
                      <div class="of pt10">
                        <p class="right f10">cmH2O</p>
                        <p class="right f22 mt-4">-12</p>
                      </div>
                      <div class="de_blue_line"></div>
                      <div class="of pt10">
                        <p class="right f10">cmH2O</p>
                        <p class="right f22 mt-4">-4</p>
                      </div>
                    </van-col>
                  </van-row>
                </div>
              </div>
              <div class="of p10050505 c_white mt5">
                <p class="left f12">{{ $t("lang.analog1GasLeakage") }}</p>
                <p class="right f10">mL/min</p>
                <p class="right f22 mt-4">385</p>
              </div>
            </van-col>
            <van-col span="12">
              <div class="yuan6 of de_blue_border2">
                <div class="of p10050505 de_blue_word2">
                  <p class="left f12">{{ $t("lang.analog1StartTime") }}</p>
                  <p class="right f22 mt-4">10:31</p>
                </div>
                <div class="of p10050505 de_blue_word2">
                  <p class="left f12">累计时长</p>
                  <p class="right f10">min</p>
                  <p class="right f22 mt-4">15</p>
                  <p class="right f10">h</p>
                  <p class="right f22 mt-4">5</p>
                </div>
                <div class="de_black_line"></div>
                <div class="of p10050505 de_green_word">
                  <p class="left f12">累计液量</p>
                  <p class="right f10">mL</p>
                  <p class="right f22 mt-4">47</p>
                </div>
              </div>
              <div class="of p10050505 de_green_word mt5">
                <p class="left f12">{{ $t("lang.analog1SumLiquid") }}</p>
                <p class="right f10">mL</p>
                <p class="right f22 mt-4">9365</p>
              </div>
            </van-col>
          </van-row>
        </div>
        <div class="van_bg_zdy">
          <van-cell @click="gotoAlarmRecord" :title="$t('lang.analog1WarnRecord')" is-link
                    :icon="require('@/assets/img/mention_his.png')"/>
        </div>
      </div>


      <!-- 胸腔气体泄漏 -->
      <div class="yuan6 of mt5 bc_fff">
        <div class="of p10">
          <van-image class="left w14 h14 mt5" :src="require('@/assets/img/pressure_relief_his.png')"/>
          <p class="left f14 ml5 lh24">{{ $t("lang.analog1GasDecompression") }}</p>

          <div v-show="!chartType1">
            <van-button @click="controlTo10_1" class="right" color="#EAEAEA" round size="mini">
              <span class="p0005 c_gray1 fb">10D</span>
            </van-button>
            <van-button class="right mr5 bc_orange" color="#FC8936" plain round size="mini">
              <span class="p0005 fb">24H</span>
            </van-button>
          </div>

          <div v-show="chartType1">
            <van-button class="right bc_orange" color="#FC8936" plain round size="mini">
              <span class="p0005 fb">10D</span>
            </van-button>
            <van-button @click="controlTo24_1" class="right mr5" color="#EAEAEA" round size="mini">
              <span class="p0005 c_gray1 fb">24H</span>
            </van-button>
          </div>
        </div>
        <pressure-relief-chart :trend-type="chartType1" :data-list="y1List" :x-list="x1List"></pressure-relief-chart>
      </div>

      <!-- 引流液体累计 -->
      <div class="yuan6 of mt5 bc_fff">
        <div class="of p10">
          <van-image class="left w14 h14 mt5" :src="require('@/assets/img/drainage_fluid_his.png')"/>
          <p class="left f14 ml5 lh24">{{ $t("lang.analog1TotalLiquid2") }}</p>

          <div v-show="!chartType2">
            <van-button @click="controlTo10_2" class="right" color="#EAEAEA" round size="mini">
              <span class="p0005 c_gray1 fb">10D</span>
            </van-button>
            <van-button class="right mr5 bc_orange" color="#FC8936" plain round size="mini">
              <span class="p0005 fb">24H</span>
            </van-button>
          </div>

          <div v-show="chartType2">
            <van-button class="right bc_orange" color="#FC8936" plain round size="mini">
              <span class="p0005 fb">10D</span>
            </van-button>
            <van-button @click="controlTo24_2" class="right mr5" color="#EAEAEA" round size="mini">
              <span class="p0005 c_gray1 fb">24H</span>
            </van-button>
          </div>
        </div>
        <drainage-fluid-chart :trend-type="chartType2" :data-list="y2List" :x-list="x2List"></drainage-fluid-chart>
      </div>

      <!-- 胸腔压力波动 -->
      <div class="yuan6 of mt5 bc_fff">
        <div class="of p10">
          <van-image class="left w14 h14 mt5" :src="require('@/assets/img/pressure_fluctuation_his.png')"/>
          <p class="left f14 ml5 lh24">{{ $t("lang.analog1PressureFluctuation") }}</p>

          <div v-show="!chartType3">
            <van-button @click="controlTo10_3" class="right" color="#EAEAEA" round size="mini">
              <span class="p0005 c_gray1 fb">10D</span>
            </van-button>
            <van-button class="right mr5 bc_orange" color="#FC8936" plain round size="mini">
              <span class="p0005 fb">24H</span>
            </van-button>
          </div>

          <div v-show="chartType3">
            <van-button class="right bc_orange" color="#FC8936" plain round size="mini">
              <span class="p0005 fb">10D</span>
            </van-button>
            <van-button @click="controlTo24_3" class="right mr5" color="#EAEAEA" round size="mini">
              <span class="p0005 c_gray1 fb">24H</span>
            </van-button>
          </div>
        </div>
        <pressure-fluctuation-chart :high-list="y3List_high" :low-list="y3List_low"
                                    :x-list="x3List"></pressure-fluctuation-chart>
      </div>
    </div>
  </section>
</template>

<script>
import PressureReliefChart from "@/components/PressureReliefChart"
import DrainageFluidChart from "@/components/DrainageFluidChart"
import PressureFluctuationChart from "@/components/PressureFluctuationChart"
import constants from "@/constants";
import i18n from "@/lang";

export default {
  name: "Data_home",
  components: {
    PressureReliefChart, DrainageFluidChart, PressureFluctuationChart
  },
  data() {
    return {
      // 第一个趋势图x轴
      x1List: [],
      // 第二个趋势图x轴
      x2List: [],
      // 第三个趋势图x轴
      x3List: [],
      // 第一个趋势图y轴
      y1List: [],
      // 第二个趋势图y轴
      y2List: [],
      // 第三个趋势图y轴
      y3List_high: [],
      y3List_low: [],
      // 第一个趋势图x轴（24h）
      x1List_24: [],
      // 第一个趋势图x轴（10D）
      x1List_10: [],
      // 第二个趋势图x轴（24h）
      x2List_24: [],
      // 第二个趋势图x轴（10D）
      x2List_10: [],
      // 第三个趋势图x轴（24h）
      x3List_24: [],
      // 第三个趋势图x轴（10D）
      x3List_10: [],
      // 第一个趋势图y轴（24h）
      y1List_24: [],
      // 第一个趋势图y轴（10D）
      y1List_10: [],
      // 第二个趋势图y轴（24h）
      y2List_24: [],
      // 第二个趋势图y轴（10D）
      y2List_10: [],
      // 第三个趋势图y轴（24h）
      y3List_high_24: [],
      y3List_low_24: [],
      // 第三个趋势图y轴（10D）
      y3List_high_10: [],
      y3List_low_10: [],
      // 第一个趋势图类型
      chartType1: constants.TrendType.HOUR,
      // 第二个趋势图类型
      chartType2: constants.TrendType.HOUR,
      // 第三个趋势图类型
      chartType3: constants.TrendType.HOUR,
    };
  },
  methods: {
    // 第一个趋势图切换到10D
    controlTo10_1: function () {
      this.chartType1 = constants.TrendType.DAY
      this.x1List = this.x1List_10
      this.y1List = this.y1List_10
    },
    // 第一个趋势图切换到24H
    controlTo24_1: function () {
      this.chartType1 = constants.TrendType.HOUR
      this.x1List = this.x1List_24
      this.y1List = this.y1List_24
    },
    // 第二个趋势图切换到10D
    controlTo10_2: function () {
      this.chartType2 = constants.TrendType.DAY
      this.x2List = this.x2List_10
      this.y2List = this.y2List_10
    },
    // 第二个趋势图切换到24H
    controlTo24_2: function () {
      this.chartType2 = constants.TrendType.HOUR
      this.x2List = this.x2List_24
      this.y2List = this.y2List_24
    },
    // 第三个趋势图切换到10D
    controlTo10_3: function () {
      this.chartType3 = constants.TrendType.DAY
      this.x3List = this.x3List_10
      this.y3List_high = this.y3List_high_10
      this.y3List_low = this.y3List_low_10
    },
    // 第三个趋势图切换到24H
    controlTo24_3: function () {
      this.chartType3 = constants.TrendType.HOUR
      this.x3List = this.x3List_24
      this.y3List_high = this.y3List_high_24
      this.y3List_low = this.y3List_low_24
    },

    // 进入报警记录页面
    gotoAlarmRecord: async function () {
      this.$router.push("/analogRecord")
    },
  },
  mounted() {
    let arr1 = [550, 450, 450, 440, 438, 419, 416, 405, 396, 387, 378, 378, 378, 377, 375, 388, 396, 387, 378, 378, 378, 377, 386, 366, 386]
    let arr2 = [11, 11, 11, 11, 10, 10, 10, 9, 9, 9, 9, 9, 9, 8, 9, 8, 9, 9, 8, 9, 9, 8, 7, 7, 7]
    let arr3 = [-3, -3, -3, -3, -4, -3, -3, -4, -3, -4, -3, -4, -3, -3, -3, -3, -3, -4, -3, -3, -3, -4, -4, -3, -4]
    let arr4 = [-12, -11, -11, -13, -13, -11, -12, -12, -13, -13, -11, -10, -10, -11, -12, -12, -13, -13, -12, -11, -11, -12, -13, -13, -12]
    arr1.reverse()
    arr2.reverse()
    arr3.reverse()
    arr4.reverse()

    // 测试数据赋值
    for (let i = 25; i > 0; i--) {
      if (1 === i) {
        this.x1List_24.push("36min")
        this.x2List_24.push("36min")
        this.x3List_24.push("36min")
      } else {
        this.x1List_24.push((i - 1) + "h")
        this.x2List_24.push((i - 1) + "h")
        this.x3List_24.push((i - 1) + "h")
      }

      this.y1List_24.push(arr1[i - 1])
      this.y2List_24.push(arr2[i - 1])
      this.y3List_high_24.push(arr3[i - 1])
      this.y3List_low_24.push(arr4[i - 1])
    }

    let arr11 = [3360, 2840, 2320, 2140, 2000, 1300, 800, 600, 580, 560, 450]
    let arr12 = [1527, 1410, 1273, 1020, 896, 767, 647, 535, 410, 387, 219]
    let arr13 = [3, 3, 1, 1, 0, 0, -1, -2, -2, -3, -3]
    let arr14 = [-2, -3, -2, -5, -7, -10, -11, -11, -12, -10, -12]
    arr11.reverse()
    arr12.reverse()
    arr13.reverse()
    arr14.reverse()

    for (let i = 11; i > 0; i--) {
      if (1 === i) {
        this.x1List_10.push("9h")
        this.x2List_10.push("9h")
        this.x3List_10.push("9h")
      } else {
        this.x1List_10.push((i - 1) + i18n.t("lang.day"))
        this.x2List_10.push((i - 1) + i18n.t("lang.day"))
        this.x3List_10.push((i - 1) + i18n.t("lang.day"))
      }

      this.y1List_10.push(arr11[i - 1])
      this.y2List_10.push(arr12[i - 1])
      this.y3List_high_10.push(arr13[i - 1])
      this.y3List_low_10.push(arr14[i - 1])
    }

    this.controlTo24_1()
    this.controlTo24_2()
    this.controlTo24_3()
  }
}
</script>

<style scoped>
.mt-4 {
  margin-top: -4px;
}

.w16 {
  width: 16px;
}

.h16 {
  height: 16px;
}

.bc_black {
  background-color: #292F33;
}

.bc_black1 {
  background-color: #535759;
}

.de_blue_word1 {
  color: #C9E1FF;
}

.de_blue_word2 {
  color: #0CC9FA;
}

.de_green_word {
  color: #6EFF9B;
}

.yuan0600 {
  border-radius: 0px 6px 0px 0px
}

.de_blue_line {
  width: 100%;
  height: 2px;
  background-color: rgba(201, 225, 255, 1);
}

.de_blue_line_1 {
  width: 100%;
  height: 1px;
  background-color: rgba(201, 225, 255, 0.2);
}

.b_left_blue {
  border-left: 1px solid rgba(201, 225, 255, 0.2);
}

.de_black_line {
  width: 100%;
  height: 2px;
  background-color: #292F33;
}

.de_grey_line {
  width: 100%;
  height: 1px;
  background-color: #E3E3E3;
}

.de_blue_border1 {
  border: 2px solid #C9E1FF;
}

.de_blue_border2 {
  border: 2px solid #0CC9FA;
}

.de_bg_blue1 {
  background-color: #C9E1FF;
}

.p10050505 {
  padding: 10px 5px 5px 5px;
}

.p10100510 {
  padding: 10px 10px 5px 10px;
}

.van_bg_zdy .van-cell {
  background-color: #383C3F;
  color: #fff;
  padding: 10px;
}

.van_bg_zdy1 .van-cell {
  padding: 10px;
}

.police_part .van-notice-bar {
  padding: 0px 10px;
}
</style>
