<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr lh24">
      <div class="bb_gray mt50">
        <van-cell class="list_radius van_bg_zdy1">
          <!--label="设备编号" placeholder="请输入"-->
          <van-field v-model="device_num" :label="$t('lang.device_add_devicenum')" :placeholder="$t('lang.device_add_pleaseinput')" :error-message="valueErrorMsg"/>
        </van-cell>
      </div>
      <!--下一步"-->
      <van-button @click="onClick2GetDevice" class="mt30" type="primary" block>{{$t('lang.device_add_next')}}</van-button>
    </div>
  </section>
</template>

<script>
import {getAdminDocDevice} from "@/services/dvDoctor";
import constants from "@/constants";
import utils from "@/utils";
import i18n from "@/lang";
import {Toast} from 'vant';

export default {
  name: "DeviceAddManual",
  data() {
    return {
      device_num: '',
      valueErrorMsg: "",
      doctor: this.$store.state.doctorWholeObj,
    };
  },
  methods: {

    // 获取设备
    onClick2GetDevice: async function () {

      this.valueErrorMsg = ""
      // 设备序列号格式判断
      if (utils.isNull(this.device_num)) {
        this.valueErrorMsg = i18n.t("lang.editDeviceNumError");
        return;
      }

      // 根据设备号获取设备
      let resultData = await getAdminDocDevice(this.doctor.doctorId, this.device_num);
      console.log(resultData);
      if (constants.AJAX_OK === resultData.code || constants.SERVICE_NO_DEVICE === resultData.code) {

        Toast({
          message: i18n.t("lang.editNotFoundDevice"), //提示
          position: 'bottom',
        });

      } else if (constants.SERVICE_NO_BIND === resultData.code) {

        //有设备但是医生没绑定
        this.$store.commit("setBindDeviceObj", resultData.data);
        this.$router.push("/addDeviceBing");

      } else if (constants.SERVICE_BIND === resultData.code) {

        //有设备+医生绑定
        this.$store.commit("setBindDeviceObj", resultData.data);
        this.$router.push("/addedDeviceInfo");

      }

    },
  }

}
</script>

<style scoped>
.van_bg_zdy1 .van-cell {
  background-color: #EDEDED;
  padding: 10px 0px;
}

.m20h {
  margin: 20vh auto auto auto;
}
</style>
