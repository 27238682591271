<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr">
      <div class="yuan6 of bc_fff">
        <div class="p2010">
          <van-row gutter="10">
            <van-col span="18">
              <p class="f16 fb">{{ $t("lang.applyAdmin") }}</p>
              <p class="f13 c_gray1 mt5">{{ $t("lang.applyAdminTip") }}</p>
            </van-col>
            <van-col span="6">
              <van-image class="right w44 h44 mr10" :src="require('@/assets/img/apply_manager.png')"/>
            </van-col>
          </van-row>
        </div>
        <div class="van_bg_zdy">
          <van-cell to="/applyAdmin" is-link :value="$t('lang.nowApply')"/>
        </div>
      </div>
      <div class="yuan6 of bc_fff mt10">
        <div class="p2010">
          <van-row gutter="10">
            <van-col span="18">
              <p class="f16 fb">{{ $t("lang.inputCode") }}</p>
              <p class="f13 c_gray1 mt5">{{ $t("lang.inputCodeTip") }}</p>
            </van-col>
            <van-col span="6">
              <van-image class="right w44 h44 mr10" :src="require('@/assets/img/authorize.png')"/>
            </van-col>
          </van-row>
        </div>
        <div class="van_bg_zdy">
          <van-cell to="/completeAuthorization" is-link :value="$t('lang.nowInput')"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style scoped>
.mt-4 {
  margin-top: -4px;
}

.mt-6 {
  margin-top: -6px;
}

.w16 {
  width: 16px;
}

.h16 {
  height: 16px;
}

.bc_black {
  background-color: #292F33;
}

.bc_black1 {
  background-color: #535759;
}

.de_blue_word1 {
  color: #C9E1FF;
}

.de_blue_word2 {
  color: #0CC9FA;
}

.de_green_word {
  color: #6EFF9B;
}

.de_blue_line {
  width: 100%;
  height: 1px;
  background-color: #C9E1FF;
}

.de_black_line {
  width: 100%;
  height: 2px;
  background-color: #292F33;
}

.de_grey_line {
  width: 100%;
  height: 1px;
  background-color: #E3E3E3;
}

.de_blue_border1 {
  border: 2px solid #C9E1FF;
}

.de_blue_border2 {
  border: 2px solid #0CC9FA;
}

.de_bg_blue1 {
  background-color: #C9E1FF;
}

.p2010 {
  padding: 20px 10px;
}

.p10100510 {
  padding: 10px 10px 5px 10px;
}

.van_bg_zdy .van-cell {
  background: -webkit-linear-gradient(to right, #FFF, #EDF3FE); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(to right, #FFF, #EDF3FE); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(to right, #FFF, #EDF3FE); /* Firefox 3.6 - 15 */
  background: linear-gradient(to right, #FFF, #EDF3FE); /* 标准的语法（必须放在最后） */
  color: #fff;
  padding: 10px;
}

.van_bg_zdy .van-cell:active {
  background: -webkit-linear-gradient(to right, #F5F5F5, #DFE9FF); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(to right, #F5F5F5, #DFE9FF); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(to right, #F5F5F5, #DFE9FF); /* Firefox 3.6 - 15 */
  background: linear-gradient(to right, #F5F5F5, #DFE9FF); /* 标准的语法（必须放在最后） */
}

.van_bg_zdy1 .van-cell {
  padding: 10px;
}

.min-height200 {
  min-height: 200px;
}

.police_part .van-notice-bar {
  padding: 0px 10px;
}
</style>
