import utils from "@/utils";
import url from "./index"

// 根据微信openId查询医生数据
export function getSettingByDoctor(doctorId) {
    return utils.getHttp(url.GET_SETTING_BY_DOCTOR, {
        doctorId: doctorId
    });
}

// 根据微信openId查询医生数据
export function modSetting(setting) {
    return utils.putHttp(url.MOD_SETTING, setting);
}
