<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr">
      <div class="tc mt50">
        <img :src="require('@/assets/img/electric_negative_pressure_aspirator_mid.png')" class="w100">
        <!--电动负压吸引器-->
        <p class="f20 fb mt10">{{ $t("lang.device_binding_alldevicename") }}</p>
        <div class="bb_gray f14 of p15 mt30">
          <!--序列号-->
          <p class="left">{{ $t("lang.device_binding_seqnum") }}</p>
          <p class="right c_gray1">{{ dv_device.deviceNum }}</p>
        </div>
        <div class="bb_gray mt10">
          <van-cell class="list_radius van_bg_zdy1">
            <!-- label="别名" placeholder="请输入（限6字符内）"-->
            <van-field v-model="device_alias" :label="$t('lang.device_binding_alias')"
                       :placeholder="$t('lang.device_binding_aliasdesc')" :error-message="aliasErrorMsg"/>
          </van-cell>
        </div>
        <div class="bb_gray mt10">
          <van-cell class="list_radius van_bg_zdy1">
            <!-- label="病区" placeholder="请输入（如：一病区）"-->
            <van-field v-model="ward_name" :label="$t('lang.device_binding_ward')"
                       :placeholder="$t('lang.device_binding_warddesc')" :error-message="wardErrorMsg"/>
          </van-cell>
        </div>
        <van-button @click="onClick2Bind" class="mt30" block type="primary">
          <!--绑定-->
          <p class="fb p1020">{{ $t("lang.device_binding_bind") }}</p>
        </van-button>
      </div>
    </div>
  </section>
</template>

<script>
import {bindDvDoctor, getAdminDocDevice} from "@/services/dvDoctor";
import constants from "@/constants";
import utils from "@/utils";
import i18n from "@/lang";
import {Toast} from "vant";

export default {
  name: "ScanResultBinding",
  data() {
    return {
      device_alias: "",
      ward_name: "",
      aliasErrorMsg: "", //格式错误，别名长度限6字符内
      wardErrorMsg: "",
      doctor: this.$store.state.doctorWholeObj,
      dv_device: this.$store.state.bindDeviceObj,
    };
  },
  methods: {

    // 绑定设备
    onClick2Bind: async function () {

      //保存前判断
      this.aliasErrorMsg = ""
      this.wardErrorMsg = ""

      // 别名格式判断
      if (utils.isNull(this.device_alias) || this.device_alias.length > 6) {
        this.aliasErrorMsg = i18n.t("lang.editAliasNameError");
        return;
      }

      // 病区格式判断
      if (utils.isNull(this.ward_name) || this.ward_name.length > 10) {
        this.wardErrorMsg = i18n.t("lang.editWardNameError");
        return;
      }

      // 根据设备号获取设备
      let resultData = await getAdminDocDevice(this.doctor.doctorId, this.dv_device.deviceNum);
      if (constants.AJAX_OK === resultData.code || constants.SERVICE_NO_DEVICE === resultData.code) {

        Toast({
          message: i18n.t("lang.editNotFoundDevice"), //提示
          position: 'bottom',
        });

      } else if (constants.SERVICE_NO_BIND === resultData.code) {

        //  管理员绑定新设备
        resultData = await bindDvDoctor(this.doctor.doctorId, this.dv_device.deviceId, this.ward_name, this.device_alias);
        // eslint-disable-next-line no-empty
        if (constants.AJAX_OK === resultData.code && resultData.data) {
          Toast({
            message: i18n.t("lang.editBindSuccess"), //提示 绑定成功
            position: 'bottom',
          });

          await this.$router.replace("/adminDeviceList") //
        } else {
          Toast({
            message: i18n.t("lang.editBindFail"), //提示 绑定失败
            position: 'bottom',
          });
        }

      } else if (constants.SERVICE_BIND === resultData.code) {

        //有设备+医生绑定
        this.$store.commit("setBindDeviceObj", resultData.data);
        this.$router.push("/addedDeviceInfo");
      }
    },
  }
}
</script>

<style scoped>
.m20h {
  margin: 20vh auto auto auto;
}

.van_bg_zdy1 .van-cell {
  background-color: #EDEDED;
  padding: 10px 15px;
}
</style>
