import Vue from "vue"
import VueRouter from "vue-router";

import ErrorPage from "@/views/not-login/SeriveError";

import NotLogin from "@/views/not-login/NotLogin";
import AnalogData from "@/views/not-login/Data_home";
import AnalogRecord from "@/views/not-login/AnalogRecord";

import AppLogin from "@/views/login/LoginCode";
import Login from "@/views/login/Login";
import SendSms from "@/views/login/AuthCode";

import MainHome from "@/views/main-home/MainHome";
import InitHome from "@/views/device/Home";
import DeviceCenter from "@/views/device/EmptyAdministrator"; //page10 16设备管理员主界面  非设备管理员主界面
import Msg from "@/views/message/Message";
import UserCenter from "@/views/userCenter/Usercenter_home"
import AdminDeviceList from "@/views/device/DeviceList"; //page15 设备管理员主界面
import FollowDeviceList from "@/views/device/DeviceListFollow"; //page16 非设备管理员主界面

import ApplyAdmin from "@/views/device/ApplyAdministrator"
import SelectMap from "@/views/device/SettingOrganizationName"
import ApplyAdminSure from "@/views/device/InfoConfirmation"

import AddDeviceManual from "@/views/device/DeviceAddManual"; //page12 通过录入序列号添加
import AddDeviceBing from "@/views/device/ScanResultBinding"; //page13 设别结果
import AddedDeviceInfo from "@/views/device/ScanResult"; //page14 已绑定设备的识别结果
import AddClassSel from "@/views/device/ClassSel"; //page17-1 选择科室组
import AddClassDeviceSel from "@/views/device/ClassDeviceSel"; //page17-2 机构设备
import EditDeviceInfo from "@/views/device/DeviceInformation"; //page20 设备设置
import DevicePatientList from "@/views/device/PatientList"; //page21 设备患者数据
import DeviceDataHome from "@/views/device/DataHome"; //page19 主界面
import DeviceDataHomeEmpty from "@/views/device/DataHomeEmpty"; //page19 设备空界面
import DevicePatientListExport from "@/views/device/PatientSel"; //page22 23 历史患者导出数据

import UserCenterMore from "@/views/userCenter/UsercenterMore";
import DoctorTeam from "@/views/userCenter/DoctorTeam";
import DoctorTeamOther from "@/views/userCenter/DoctorTeamOther";

import DoctorDetails from "@/views/userCenter/DoctorDetails";
import DoctorTeamOtherDetails from "@/views/userCenter/DoctorTeamOtherDetails";
import CompleteAuthorization from "@/views/device/CompleteAuthorization";
import AlarmRecord from "@/views/device/AlarmRecord";
import EditRecord from "@/views/device/PatientInfoEditHis";
import PatientInfo from "@/views/device/PatientInfoRegistration";

import OldPhoneEnsure from "@/views/userCenter/OldPhoneEnsure";
import NewPhoneInput from "@/views/userCenter/NewPhoneInput";
import NewPhoneAuthCode from "@/views/userCenter/NewPhoneAuthCode";

import ServiceAgreement from "@/views/userCenter/ServiceAgreement";
import PrivacyProtection from "@/views/userCenter/PrivacyProtection";

import MessageSetting from "@/views/userCenter/MessageSetting";
import i18n from '@/lang/index'

Vue.use(VueRouter)
const routes = [
	{
		path: '',
		component: NotLogin,
		meta: {
			excludeLogin: true,
			title: i18n.t("lang.analogTitle")
		}
	},
	{
		path: '/',
		component: NotLogin,
		meta: {
			excludeLogin: true,
			title: i18n.t("lang.analogTitle")
		}
	},
	{
		path: '/home',
		component: NotLogin,
		meta: {
			excludeLogin: true,
			title: i18n.t("lang.analogTitle")
		}
	},
	{
		path: '/analogData',
		component: AnalogData,
		meta: {
			excludeLogin: true,
			title: i18n.t("lang.analog1Title")
		}
	},
	{
		path: '/analogRecord',
		component: AnalogRecord,
		meta: {
			excludeLogin: true,
			title: i18n.t("lang.analog1WarnRecord")
		}
	},
	{
		path: '/appLogin',
		component: AppLogin,
		meta: {
			excludeLogin: true,
			title: " "
		}
	},
	{
		path: '/login',
		component: Login,
		meta: {
			excludeLogin: true,
			title: " "
		}
	},
	{
		path: '/sendSms',
		component: SendSms,
		meta: {
			excludeLogin: true,
			title: " "
		}
	},
	{
		path: '/mainHome',
		component: MainHome,
		children: [{
				path: '/deviceCenter',
				name: 'deviceCenter',
				component: DeviceCenter,
				meta: {
					title: " "
				}
			},
			{
				path: '/msg',
				component: Msg,
				meta: {
					title: i18n.t("lang.message")
				}
			},
			{
				path: '/userCenter',
				name: 'userCenter',
				component: UserCenter,
				meta: {
					title: i18n.t("lang.my")
				}
			},
			{
				path: '/initHome',
				component: InitHome,
				meta: {
					title: i18n.t("lang.device")
				}
			},
			{
				path: '/adminDeviceList',
				component: AdminDeviceList,
				meta: {
					title: " "
				}
			},
			{
				path: '/followDeviceList',
				component: FollowDeviceList,
				meta: {
					title: i18n.t("lang.device_att")
				}
			}
		]
	},
	{
		path: '/applyAdmin',
		component: ApplyAdmin,
		meta: {
			title: " "
		}
	},
	{
		path: '/applyAdminSure',
		component: ApplyAdminSure,
		meta: {
			title: " "
		}
	},
	{
		path: '/selectMap',
		component: SelectMap,
		meta: {
			title: i18n.t("lang.instName")
		}
	},
	{
		path: '/oldPhoneEnsure',
		component: OldPhoneEnsure,
		meta: {
			title: " "
		}
	},
	{
		path: '/newPhoneInput',
		component: NewPhoneInput,
		meta: {
			title: " "
		}
	},
	{
		path: '/newPhoneAuthCode',
		name: 'newPhoneAuthCode',
		component: NewPhoneAuthCode,
		meta: {
			title: " "
		}
	},
	{
		path: '/userCenterMore',
		component: UserCenterMore,
		meta: {
			title: i18n.t("lang.uc_more")
		}
	},
	{
		path: '/doctorTeam',
		name: 'doctorTeam',
		component: DoctorTeam,
		meta: {
			title: i18n.t("lang.setManageGroup")
		}
	},
	{
		path: '/doctorTeamOther',
		name: 'doctorTeamOther',
		component: DoctorTeamOther,
		meta: {
			title: i18n.t("lang.setManageGroup")
		}
	},
	{
		path: '/doctorDetails',
		name: 'doctorDetails',
		component: DoctorDetails,
		meta: {
			title: i18n.t("lang.docDetail")
		}
	},
	{
		path: '/doctorTeamOtherDetails',
		name: 'doctorTeamOtherDetails',
		component: DoctorTeamOtherDetails,
		meta: {
			title: " "
		}
	},
	{
		path: '/completeAuthorization',
		name: 'completeAuthorization',
		component: CompleteAuthorization,
		meta: {
			title: " "
		}
	},
	{
		path: '/serviceAgreement',
		name: 'serviceAgreement',
		component: ServiceAgreement,
		meta: {
			excludeLogin: true,
			title: i18n.t("lang.uc_more_fwxy")
		}
	},
	{
		path: '/privacyProtection',
		name: 'privacyProtection',
		component: PrivacyProtection,
		meta: {
			excludeLogin: true,
			title: i18n.t("lang.uc_more_ysq")
		}
	},
	{
		path: '/messageSetting',
		name: 'messageSetting',
		component: MessageSetting,
		meta: {
			excludeLogin: true,
			title: i18n.t("lang.uc_more_msgset")
		}
	},
	{
		path: '/addDeviceManual',
		component: AddDeviceManual,
		meta: {
			title: i18n.t("lang.editManualBind")
		}
	},
	{
		path: '/addDeviceBing',
		component: AddDeviceBing,
		meta: {
			title: i18n.t("lang.analogTitle")
		}
	},
	{
		path: '/addedDeviceInfo',
		component: AddedDeviceInfo,
		meta: {
			title: i18n.t("lang.analogTitle")
		}
	},
	{
		path: '/addClassSel',
		component: AddClassSel,
		meta: {
			title: i18n.t("lang.editSelect")
		}
	},
	{
		path: '/addClassDeviceSel',
		component: AddClassDeviceSel,
		meta: {
			title: i18n.t("lang.editSelect")
		}
	},
	{
		path: '/editDeviceInfo',
		component: EditDeviceInfo,
		meta: {
			title: i18n.t("lang.editDeviceSet")
		}
	},
	{
		path: '/devicePatientList',
		component: DevicePatientList,
		meta: {
			title: i18n.t("lang.editHisPatient")
		}
	},
	{
		path: '/devicePatientListExport',
		component: DevicePatientListExport, //历史患者数据导出
		meta: {
			title: i18n.t("lang.device_his_pspati") //请选择患者
		}
	},
	{
		path: '/deviceDataHome',
		component: DeviceDataHome,
		meta: {
			title: " "
		}
	},
	{
		path: '/deviceDataHomeEmpty',
		component: DeviceDataHomeEmpty,
		meta: {
			title: " "
		}
	},
	{
		path: '/alarmRecord',
		component: AlarmRecord,
		meta: {
			title: i18n.t("lang.alarmRecord")
		}
	},
	{
		path: '/editRecord',
		component: EditRecord,
		meta: {
			title: i18n.t("lang.editRecord")
		}
	},
	{
		path: '/patientInfo',
		component: PatientInfo,
		meta: {
			title: i18n.t("lang.patientInfo")
		}
	},
	{
		path: '/errorPage',
		component: ErrorPage,
		meta: {
			excludeLogin: true,
			title: " "
		}
	},
	{
		path: '*',
		component: ErrorPage,
		meta: {
			excludeLogin: true,
			title: " "
		}
	}
]

const router = new VueRouter({
	base: process.env.NODE_ENV === 'development' ? process.env.BASE_URL : '/axsed-app/',
	// base: '/axsed-app/',
	mode: 'history',
	routes
})


export default router
