import utils from "@/utils";
import url from "./index"

// 查询设备报警信息
export function getDvError(patientId, deviceId) {
    return utils.getHttp(url.GET_DV_ERROR, {
        patientId: patientId,
        deviceId: deviceId,
    });
}
