<template>
  <section class="van-doc-demo-section">
    <div v-if="0 !== msgList.length" class="m15-00 dfr">
      <van-list
          v-model="loading"
          :finished="finished"
          @load="onLoad">


        <div v-for="(item, index) in msgList" :key="index" class="yuan6 of mb10 message_head_yellow">
          <div class="of p0510">
            <van-image class="left w14 h14 mt5" :src="item.typeImg"/>
            <p class="left f14 ml5 lh24 message_yellow">{{ item.typeName }}</p>
            <p class="right f12 c_gray1 ml5 lh24">{{ getDateFormat(item.msgTime) }}</p>
          </div>
          <div class="p1510 bc_fff yuan6 min-height200">
            <p class="f16">{{ item.msgContent }}</p>
          </div>
        </div>
      </van-list>
    </div>

    <div v-if="0 === msgList.length" class="m15-00 dfr">
      <div class="tc mt50 mb30">
        <img :src="require('@/assets/img/message_empty.png')" class="w60">
        <!--暂无消息-->
        <p class="f18 mt15">{{ $t("lang.noMessage") }}</p>
        <p class="f14 c_gray1 mt5">{{ $t("lang.noMessageTip") }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import {getMsg} from "@/services/stMessage";
import constants from "@/constants";
import utils from "@/utils";

export default {
  mounted() {
    this.onLoad()
  },
  data() {
    return {
      msgList: [],
      loading: false,
      finished: false,
      page: 1,
    };
  },
  methods: {
    async onLoad() {
      let resultData = await getMsg(this.$store.state.doctorWholeObj.doctorId, this.page);
      if (constants.AJAX_OK === resultData.code && resultData.data) {
        for (let i = 0; i < resultData.data.length; i++) {
          this.msgList.push(resultData.data[i])
        }

        if (utils.isNull(resultData.data) || resultData.data.length < 20) {
          this.finished = true;
        }

        // 加载状态结束
        this.loading = false;
        this.page++
      }
    },
    // 获取日期格式
    getDateFormat: function (time) {
      return utils.formatDate(new Date(time), "yyyy/MM/dd hh:mm")
    },
  },
};
</script>

<style scoped>
.message_head_yellow {
  background: -webkit-linear-gradient(to right, #FEF6ED, #FFF); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(to right, #FEF6ED, #FFF); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(to right, #FEF6ED, #FFF); /* Firefox 3.6 - 15 */
  background: linear-gradient(to right, #FEF6ED, #FFF); /* 标准的语法（必须放在最后） */
}

.p1510 {
  padding: 15px 10px;
}

.message_yellow {
  color: #6D5237;
}
</style>
