<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr">
      <van-cell class="list_radius">
        <div class="van_bg_zdy1">
          <van-cell v-for="(item, index) in alarmList" :key="index">
            <van-image class="left w40" :src="require('@/assets/img/call_the_police_big.png')"/>
            <div class="left">
              <p class="fb f16">{{ item.errorDesc }}</p>
              <div class="of">
                <van-image class="left w14 h14 mt5" :src="require('@/assets/img/alarm_start.png')"/>
                <p class="left ml5 fs90 c_gray1">{{ getDateFormat(item.alarmTime) }}</p>
                <van-image class="left w14 h14 mt5 ml10" :src="require('@/assets/img/alarm_end.png')"/>
                <p class="left ml5 fs90 c_gray1">{{ getDateFormat(item.cancelTime) }}</p>
              </div>
            </div>
          </van-cell>
        </div>
      </van-cell>
    </div>
  </section>
</template>

<script>
import {getDvError} from "@/services/dvError";
import constants from "@/constants";
import utils from "@/utils";

export default {
  name: "AlarmRecord",
  data() {
    return {
      alarmList: [],
    }
  },
  async mounted() {
    let dv_device = this.$store.state.bindDeviceObj

    let resultData = await getDvError(dv_device.patientId, dv_device.deviceId);
    if (constants.AJAX_OK === resultData.code && resultData.data) {
      this.alarmList = resultData.data
    }
  },
  methods: {
    // 获取日期格式
    getDateFormat: function (time) {
      return utils.formatDate(new Date(time), "yyyy/MM/dd hh:mm")
    }
  }
}
</script>

<style scoped>
.van_bg_zdy1 .van-cell {
  padding: 10px;
}
</style>
