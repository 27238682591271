<template>
  <section class="van-doc-demo-section">
    <div class="m1010 dfr cell_setting">
      <van-row>
        <van-col>
          <van-cell @click="showPopup" :title="myTimeDesc()" is-link arrow-direction="down" />

          <van-popup v-model="pick_show" position="bottom" :overlay="true">
            <!--选择时间-->
            <van-picker show-toolbar :title="$t('lang.device_his_seltime')" :columns="columns" @cancel="onCancel" @confirm="onConfirm" @change="onChange" />
          </van-popup>

        </van-col>
      </van-row>
      <div class="of ml5">
        <p class="left f14 lh24 c_gray1">{{myDeviceDesc()}}</p>
        <van-button v-if=dv_patients @click="onClick2Export" class="right" type="primary" size="mini" round>
          <!--导出-->
          <span class="p0008 fb">{{$t('lang.device_his_export')}}</span>
        </van-button>
      </div>
    </div>

    <!--    <van-cell center is-link title="二病区 3床 患者B" label="心胸内科一 ｜ 运行18小时22分钟" />-->
    <van-cell @click="onClick2GetPatiDeData(value)" v-for="(value,key,index) in dv_patients" :key="index"  center is-link :title="myDepDesc(value)" :label="getDvTime(value)" />

  </section>
</template>

<script>
  import {getDvHisPatient, getDeviceHisMonth} from "@/services/dvPatient";
  import {getPatiDeviceData} from "@/services/dvDevice";
  import utils from "@/utils";
  import i18n from "@/lang";
  import {Toast} from "vant";
  import constants from "@/constants";

  export default {
name: "PatientList",

  async mounted() {
    console.log(this.dv_device.deviceId);

    //获取设备监控历史月
    let resultData = await getDeviceHisMonth(this.dv_device.deviceId);
    console.log(resultData);

    this.columns = resultData.data;
    console.log(this.columns);

    if (utils.isNotNull(this.columns))
    {
      this.cur_time = this.columns[0];

      //设备监控患者
       this.toGetHisPatient();
    }
  },

  data() {
    return {
      doctor: this.$store.state.doctorWholeObj,
      dv_device: this.$store.state.bindDeviceObj,
      dv_patients:"",
      pick_show:false,
      columns: [],
      cur_time:"",
    };
  },

  methods:{

    myDeviceDesc(){
      if (utils.isNotNull(this.dv_patients))
      {
        console.log(this.dv_patients);
        let runNum = 0;
        for (let i = 0; i < this.dv_patients.length; i++)
        {
           let pati = this.dv_patients[i];
           runNum = runNum + pati.runTime;
        }
        let hour = parseInt(runNum/60);
        let min = runNum%60;
        return this.dv_patients.length + i18n.t("lang.device_his_onepati")+'，'+i18n.t("lang.device_his_run")+hour+i18n.t("lang.device_his_hour")+min+i18n.t("lang.device_his_min");
      }
      else
      {
        return "--"+i18n.t("lang.device_his_onepati")+"，"+i18n.t("lang.device_his_run")+"--"+i18n.t("lang.device_his_hour");
      }
    },

    myDepDesc(docPatObj){
      return docPatObj.wardName + ' ' + docPatObj.bedNum + i18n.t("lang.device_his_bed")+ ' ' + docPatObj.patName;
    },

    // 获取设备运行时间
    getDvTime: function (docPatObj) {
      let beginTime = docPatObj.beginTime
      let content = utils.formatDate(new Date(beginTime), "yyyy/MM/dd hh:mm") + i18n.t("lang.analog1Start") + ","
              + i18n.t("lang.run");
      let runTime_h = parseInt(docPatObj.runTime / 60)
      let runTime_m = docPatObj.runTime - runTime_h * 60
      if (0 !== runTime_h) {
        content += runTime_h + i18n.t("lang.hour2")
      }
      if (0 !== runTime_m) {
        content += runTime_m + i18n.t("lang.min2")
      }
      return content
    },

    //选择时间
    myTimeDesc(){
      if (!utils.isNull(this.cur_time))
      {
        return this.cur_time;
      }
      else
      {
        return i18n.t("lang.device_his_seltime"); //选择时间
      }
    },

    showPopup() {
      this.pick_show = true;
    },

    hidePopup() {
      this.pick_show = false;
    },

    //获取设备历史患者
    toGetHisPatient: async function () {
      console.log(this.dv_device.deviceId, this.cur_time);
      let dr = await getDvHisPatient(this.dv_device.deviceId, this.cur_time);
      console.log(dr);

      this.dv_patients =dr.data;
      console.log(this.dv_patients);
    },

    onConfirm(value, index) {
      // Toast(`当前值：${value}, 当前索引：${index}`);
      console.log(`当前值：${value}, 当前索引：${index}`);
      this.cur_time = value;
      this.pick_show = false;
      this.toGetHisPatient();
    },
    onChange(picker, value, index) {
      // Toast(`当前值：${value}, 当前索引：${index}`);
      console.log(`当前值：${value}, 当前索引：${index}`);
      this.cur_time = value;
      this.pick_show = false;
      this.toGetHisPatient();
    },
    onCancel() {
      // Toast('取消');
      this.pick_show = false;
    },

    //导出
    onClick2Export(){
      this.$router.push("/devicePatientListExport");
    },

    //获取患者历史监控数据
    onClick2GetPatiDeData: async function (patiObj) {
      console.log(this.dv_device.deviceId, patiObj);
      let resultData = await getPatiDeviceData(this.dv_device.deviceId, patiObj.dvPatientId);
      console.log(resultData);
      if (constants.AJAX_OK === resultData.code && resultData.data && utils.isNotNull(resultData.data)) {

        this.$store.commit("setBindDeviceObj", resultData.data);
        this.$router.push("/deviceDataHome");

      } else {

        Toast({
          message: i18n.t("lang.device_his_getdatafail"), //获取历史数据失败
          position: 'bottom',
        });
      }

    },

  },

}
</script>

<style scoped>
.cell_setting .van-cell{background-color: #EDEDED; padding:10px 5px;font-size: 16px;}
.cell_setting .van-cell:active{background-color: #E3E1E1;}
.p0008{padding: 0px 8px;}
</style>