<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr">
      <div class="tc mt50 mb30">
        <img :src="require('@/assets/img/device_off.png')" class="w60">
        <!--该设备暂无监控患者-->
        <p class="f18">{{ $t('lang.device_dh_nopatient') }}</p>
      </div>
      <van-cell class="list_radius mt5 van_bg_zdy1">
        <van-cell @click="onClick2GetPatiDvCount" :title="$t('lang.analog1HistoryPat')"
                  :icon="require('@/assets/img/historical_patients.png')"
                  is-link/>
      </van-cell>
      <van-cell class="list_radius mt5 van_bg_zdy1">
        <van-cell @click="toEditDeviceInfo" :title="$t('lang.analog1DeviceSet')"
                  :icon="require('@/assets/img/device_setting.png')" is-link/>
      </van-cell>
    </div>
  </section>
</template>

<script>
import {getDeviceHisPatiCount} from "@/services/dvPatient";
import {Toast} from "vant";
import constants from "@/constants";
import i18n from "@/lang";

export default {
  name: "DataHome",

  data() {
    return {
      doctor: this.$store.state.doctorWholeObj,
      docdevice: this.$store.state.bindDeviceObj, //当前绑定设备
    };
  },
  mounted() {
    document.title = this.docdevice.deviceAlias
  },
  methods: {

    //设备设置
    toEditDeviceInfo() {
      this.$router.push("/editDeviceInfo");
    },

    //获取设备历史患者数量
    onClick2GetPatiDvCount: async function () {
      console.log(this.docdevice.deviceId);
      let resultData = await getDeviceHisPatiCount(this.docdevice.deviceId);
      console.log(resultData);
      if (constants.AJAX_OK === resultData.code && resultData.data) {

        //历史患者
        this.$router.push("/devicePatientList");

      } else {

        Toast({
          message: i18n.t("lang.device_his_nopati"), //该设备无患者数据
          position: 'bottom',
        });
      }
    },

  }
}
</script>

<style scoped>
.van_bg_zdy1 .van-cell {
  padding: 10px;
}
</style>
