<template>
  <div class="pr">
    <div class="pa c_999 f12 mt10 ml10">cmH2O</div>
    <!-- 胸腔压力波动趋势图 -->
    <v-chart v-if="realOptions" class="bc_black yuan6 min-height200" @datazoom="onDataZoom"
             :option="realOptions" autoresize/>
  </div>
</template>

<script>
import i18n from '@/lang/index'
import utils from "../utils";

export default {
  name: "PressureFluctuationChart",
  props: ['xList', 'highList', 'lowList'],
  data() {
    return {
      // 选中的数据
      selData: '',
      // 选项
      realOptions: '',
      // 缩放
      zoomStart: 0,
      zoomEnd: 100,
    }
  },
  watch: {
    options(newValue) {
      if (newValue) {
        for (let i = 0; i < newValue.dataZoom.length; i++) {
          newValue.dataZoom[i].start = this.zoomStart;
          newValue.dataZoom[i].end = this.zoomEnd;
        }
        this.realOptions = newValue;
      }
    }
  },
  computed: {
    options() {
      return {
        title: {
          text: this.selData,
          right: '3%',
          top: 10,
          textStyle: {
            color: '#FFE594',
            fontWeight: 'normal',
            fontSize: 12
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          top: 40,
          bottom: 55,
          containLabel: true
        },
        dataZoom: [{
          type: 'inside',
          start: 0,
          end: 100,
          borderRadius: 20,
        }, {
          start: 0,
          end: 100,
          bottom: 30,
          brushSelect: false,
          showDataShadow: false,
          height: 20,
          backgroundColor: 'rgba(255,255,255,0.2)',
          borderColor: "#292F33",
          handleIcon: 'image://' + require("@/assets/img/drag_small.png"),
          fillerColor: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
              offset: 0, color: '#0CC9FA' // 0% 处的颜色
            }, {
              offset: 1, color: '#FFE594' // 100% 处的颜色
            }],
            globalCoord: false // 缺省为 false
          },
          handleStyle: {
            color: '#4E82D7',
          },
        }],
        xAxis: {
          type: 'category',
          data: this.xList,
          axisLine: {
            onZero: false
          },
          axisTick: {
            alignWithLabel: true
          },
          axisPointer: {
            show: true,
            triggerTooltip: false,
            type: 'shadow',
            label: {
              show: false,
              formatter: (params) => {
                let arr = params.value.replace("h", i18n.t("lang.hour")).replace("mins", i18n.t("lang.min"))

                let index = this.xList.indexOf(params.value);
                // 非最后一条数据
                if (this.xList.length - 1 !== index) {
                  arr += i18n.t("lang.before")
                }

                if (index < this.lowList.length && index < this.highList.length) {
                  arr += " | " + i18n.t("lang.highValue") + ": " + (0 === this.lowList[index] || this.lowList[index] ? this.lowList[index] : '--')
                  arr += "、" + i18n.t("lang.lowValue") + ": " + (0 === this.highList[index] || this.highList[index] ? this.highList[index] : '--')
                } else {
                  arr += " | " + i18n.t("lang.highValue") + ": " + (0 === this.lowList[this.lowList.length - 1] || this.lowList[this.lowList.length - 1] ? this.lowList[this.lowList.length - 1] : '--')
                  arr += "、" + i18n.t("lang.lowValue") + ": " + (0 === this.highList[this.highList.length - 1] || this.highList[this.highList.length - 1] ? this.highList[this.highList.length - 1] : '--')
                }
                this.selData = arr
              }
            },
            handle: {
              show: true,
              color: '#7581BD',
              size: 22,
              margin: 55,
              shadowColor: 'rgba(0, 0, 0, 0)',
              icon: 'image://' + require("@/assets/img/drag_big.png")
            },
            lineStyle: {
              type: 'solid'
            }
          },
        },
        yAxis: {
          type: 'value',
          nameTextStyle: {
            color: "#999999",
          },
          inverse: utils.isNotNull(this.highList),
          axisLabel: {
            color: "#FFE594"
          },
          splitLine: {
            lineStyle: {
              color: "#FFF",
              opacity: 0.2
            }
          },
          min: -20,
          max: 5,
          interval: 5,
          splitNumber: 5,
        },
        series: [
          {
            type: 'bar',
            stack: 'pressure_fluctuation',
            data: this.getLowList(),
            itemStyle: {
              borderColor: 'rgba(0,0,0,0)',
              color: 'rgba(0,0,0,0)'
            },
          },
          {
            type: 'bar',
            stack: 'pressure_fluctuation',
            data: this.getHighLowList(),
            barWidth: 5,
            itemStyle: {
              borderRadius: 50,
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: '#FFE594' // 0% 处的颜色
                }, {
                  offset: 1, color: '#0CC9FA' // 100% 处的颜色
                }],
                globalCoord: false // 缺省为 false
              }
            }
          },
          {
            type: 'bar',
            stack: 'pressure_fluctuation',
            data: this.getPositiveList(),
            barWidth: 5,
            itemStyle: {
              borderRadius: [0, 0, 50, 50],
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: '#87d7c6' // 0% 处的颜色
                }, {
                  offset: 1, color: '#11c9f8' // 100% 处的颜色
                }],
                globalCoord: false // 缺省为 false
              }
            }
          },
          {
            type: 'bar',
            stack: 'pressure_fluctuation',
            data: this.getNegativeList(),
            barWidth: 5,
            itemStyle: {
              borderRadius: [50, 50, 0, 0],
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: '#f1e39a' // 0% 处的颜色
                }, {
                  offset: 1, color: '#b6dcb3' // 100% 处的颜色
                }],
                globalCoord: false // 缺省为 false
              }
            }
          },
        ]
      }
    }
  },
  methods: {
    // 获取低值列表（高值和低值存在正负区别时，显示为“-”）
    getLowList: function () {
      if (utils.isNull(this.highList) || utils.isNull(this.lowList)) {
        return;
      }

      let arr = []
      for (let i = 0; i < this.lowList.length; i++) {
        if (this.lowList[i] * this.highList[i] < 0) {
          arr.push("-")
        } else if (Math.abs(this.lowList[i]) < Math.abs(this.highList[i])) {
          arr.push(this.lowList[i])
        } else {
          arr.push(this.highList[i])
        }
      }
      return arr
    },
    // 获取高值-低值列表（高值和低值存在正负区别时，显示为“-”）
    getHighLowList: function () {
      if (utils.isNull(this.highList) || utils.isNull(this.lowList)) {
        return;
      }

      let arr = []
      for (let i = 0; i < this.highList.length; i++) {
        if (this.lowList[i] * this.highList[i] < 0) {
          arr.push("-")
        } else if (Math.abs(this.lowList[i]) > Math.abs(this.highList[i])) {
          arr.push(this.lowList[i] - this.highList[i])
        } else {
          arr.push(this.highList[i] - this.lowList[i])
        }
      }
      return arr
    },
    // 全是正数列表（高值和低值存在正负区别时，所有的正数列表，其余值显示为“-”）
    getPositiveList: function () {
      if (utils.isNull(this.highList) || utils.isNull(this.lowList)) {
        return;
      }

      let arr = []
      for (let i = 0; i < this.lowList.length; i++) {
        if (this.lowList[i] * this.highList[i] < 0) {
          if (this.lowList[i] > 0) {
            arr.push(this.lowList[i])
          } else {
            arr.push(this.highList[i])
          }
        } else {
          arr.push("-")
        }
      }
      return arr
    },
    // 全是负数列表（高值和低值存在正负区别时，所有的负数列表，其余值显示为“-”）
    getNegativeList: function () {
      if (utils.isNull(this.highList) || utils.isNull(this.lowList)) {
        return;
      }

      let arr = []
      for (let i = 0; i < this.lowList.length; i++) {
        if (this.lowList[i] * this.highList[i] < 0) {
          if (this.lowList[i] < 0) {
            arr.push(this.lowList[i])
          } else {
            arr.push(this.highList[i])
          }
        } else {
          arr.push("-")
        }
      }
      return arr
    },
    onDataZoom: function (action) {
      if ("batch" in action && Array.isArray(action.batch) && action.batch.length > 0) {
        this.zoomStart = action.batch[0].start;
        this.zoomEnd = action.batch[0].end;
      } else if ("start" in action && "end" in action) {
        this.zoomStart = action.start;
        this.zoomEnd = action.end;
      }
    },
  }
}
</script>


<style scoped>
.bc_black {
  background-color: #292F33;
}

.min-height200 {
  min-height: 200px;
}
</style>
