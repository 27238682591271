// const RootReqUrl = "https://xy.chest.drainage.xinhaiip.cn/";
// const RootReqUrl = "http://127.0.0.1:8888";
// const RootReqUrl = process.env.NODE_ENV === 'development' ? "axsed-api" : "axsed-app/axsed-api";
// const RootReqUrl = "http://183.134.242.190:14006/"
const RootReqUrl = "https://xy.chest.drainage.xinhaiip.cn/axsed-api/"
// const RootReqUrl = "http://192.168.2.118:8889/"

module.exports = {
    // 微信公众号API
    WX_ACCOUNT: RootReqUrl + "/api/wechat/wt-account",
    // 微信公众号API(带斜线)
    WX_ACCOUNT_PATH: RootReqUrl + "/api/wechat/wt-account/",
    // 获取微信用户信息
    GET_WX_USER_INFO: RootReqUrl + "/api/wechat/wt-account/get-user-info",
    // 获取微信用户信息
    GET_WXPARAM: RootReqUrl + "/api/wechat/wt-account/get-wxparam",

    // 获取医生openId
    GET_OPEN_ID: RootReqUrl + "/api/wechat/wt-wechat/get-openId",

    // 获取机构信息
    GET_INSTBYID: RootReqUrl + "/api/inst/it-inst/",

    // 根据微信openId查询医生数据
    GET_DOCTOR: RootReqUrl + "/api/inst/it-doctor/get-from-wx",
    //  根据doctorId查询医生信息
    GET_DOCTOR_PATH: RootReqUrl + "/api/inst/it-doctor/",
    // 根据手机号查询医生数据
    GET_DOCTOR_BY_MOBILE: RootReqUrl + "/api/inst/it-doctor/get-from-mobile",
    // 获取短信验证码
    GET_SMS: RootReqUrl + "/api/inst/it-doctor/get-sms",
    // 申请管理员
    APPLY_ADMIN: RootReqUrl + "/api/inst/it-doctor/apply-admin",
    // 修改医生信息
    MOD_DOCTOR: RootReqUrl + "/api/inst/it-doctor",
    // 更换管理员(mgrId当前管理员id，doctorId将要修改的管理员id)
    REPLACE_ADMIN: RootReqUrl + "/api/inst/it-doctor/replace-admin",
    // 医生邀请码检查
    CHECK_CODE: RootReqUrl + "/api/inst/it-doctor/check-code",
    // 查看管理员已邀请的医生
    GET_INVITE_DOCTOR: RootReqUrl + "/api/inst/it-doctor/get-invite-doctor",
    // 管理员获取6位邀请码
    GET_INVITE_CODE: RootReqUrl + "/api/inst/it-doctor/get-invite-code",

    // 解除邀请
    REMOVE_GROUP_PATH: RootReqUrl + "/api/inst/it-dept-group/remove-group/",
    // 查看医生已加入的群组
    GET_DOCTOR_GROUPS: RootReqUrl + "/api/inst/it-dept-group/get-doctor-groups",
    // 查看在同一个群组里的医生
    GET_GROUP_DOCTOR: RootReqUrl + "/api/inst/it-dept-group/get-group-doctor",

    // 根据设备号获取设备
    GET_BY_DEVICE_NUM: RootReqUrl + "/api/device/dv-device/getByDeviceNum",
    // 查询医生添加、关注的设备
    GET_DOC_DEVICE_LIST: RootReqUrl + "/api/device/dv-device/getDocDeviceList",
    // 询管理员管理设备 或 查询医生关注设备
    GET_DOC_DEVICE: RootReqUrl + "/api/device/dv-device/getDocDevice",
    // 根据主键获取设备
    GET_DEVICE_BY_ID: RootReqUrl + "/api/device/dv-device/",
    // 更新设备信息【病区 和别 名】
    UPDATE_DEVICE_INFO: RootReqUrl + "/api/device/dv-device/updateDeviceInfo",
    // 查询历史患者对应监控数据
    GET_PATI_DEVICED_ATA: RootReqUrl + "/api/device/dv-device/getPatiDeviceData",
    // 查询设备对应监控数据
    GET_DEVICE_DATA: RootReqUrl + "/api/device/dv-device/getDeviceData",

    // 管理医生绑定新设备
    BIND_DV_DOCTOR: RootReqUrl + "/api/device/dv-doctor/bindDvDoctor",
    // 管理医生解除绑定设备
    DELETE_BIND_DV_DOCTOR: RootReqUrl + "/api/device/dv-doctor/deleteBindDvDoctor",
    // 管理员根据设备号查询设备
    GET_ADMIN_DOC_DEVICE: RootReqUrl + "/api/device/dv-doctor/getAdminDocDevice",
    // 医生取消关注设备
    CANCEL_ATTENT_DV_DOCTOR: RootReqUrl + "/api/device/dv-doctor/cancelAttentDvDoctor",
    // 新增医生关注设备
    ATTENT_DV_DOCTOR: RootReqUrl + "/api/device/dv-doctor/attentDvDoctor",
    // 获取设备管理员
    GET_DEVICE_ADMIN_DOC: RootReqUrl + "/api/device/dv-doctor/getDeviceAdminDoc",

    // 查询设备历史患者数目
    GET_DEVICE_HIS_PATI_COUNT: RootReqUrl + "/api/device/dv-patient/getDeviceHisPatiCount",
    // 查询设备历史患者
    GET_DV_HIS_PATIENT: RootReqUrl + "/api/device/dv-patient/getDvHisPatient",
    // 获取设备监控数据历史月
    GET_DEVICE_HIS_MONTH: RootReqUrl + "/api/device/dv-patient/getDeviceHisMonth",
    // 发邮件导出设备历史患者数据
    EXPORT_DEVICE_HIS_PATI: RootReqUrl + "/api/device/dv-patient/exportDeviceHisPati",

    // 获取设备报警信息
    GET_DV_ERROR: RootReqUrl + "/api/device/dv-error/getDvError",

    // 查询患者编辑记录
    GET_PAT_RECORD: RootReqUrl + "/api/patient/pt-edit/getRecordByPatId",

    // 查询患者信息
    GET_PATIENT: RootReqUrl + "/api/patient/pt-patient",
    // 查询患者信息(带斜线)
    GET_PATIENT_PATH: RootReqUrl + "/api/patient/pt-patient/",

    // 获取趋势图
    GET_TREND: RootReqUrl + "/api/device/dv-trend/getTread",

    // 获取实时数据
    GET_LIVE_DATA: RootReqUrl + "/api/device/dv-live-data/getLiveData",

    // 获取消息（分页）
    GET_MSG: RootReqUrl + "/api/system/st-message/page",

    //根据doctorId获取该医生的消息设置信息
    GET_SETTING_BY_DOCTOR: RootReqUrl + "/api/system/st-setting/get-Setting-ByDoctor",
    //修改医生的消息设置信息
    MOD_SETTING: RootReqUrl + "/api/system/st-setting",
}
