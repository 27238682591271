<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr">
      <div class="tc m20">
        <img :src="doctor.headPortrait" class="w40 h40 yuan20">
        <p class="f18 mt10">{{ this.doctor.nameAlias }}</p>
      </div>
      <div class="bb_gray mt30 f14 of p15 lh32">
        <!--手机-->
        <p class="left">{{ $t("lang.uc_docDet_mobile") }}</p>
        <p class="right c_gray1">{{ this.doctor.mobile }}</p>
      </div>
      <div class="bb_gray f14 of p15 lh32">
        <!--备用名-->
        <p class="left">{{ $t("lang.uc_docDet_backupName") }}</p>
        <van-button @click="showPopup_aliasSet" class="right" color="#EDEDED"
                    :icon="require('@/assets/img/list_edit.png')"
                    type="info"
                    size="small" round>
        </van-button>
        <p class="right c_gray1">{{ doctor.aliasSet }}</p>
      </div>
      <div class="van-bottom">
        <div class="m00-15">
          <!--设为设备管理员-->
          <van-button class="fb mb10" type="primary" block @click="setting_manager">
            {{ $t("lang.uc_docDet_setManger") }}
          </van-button>
          <van-button class="mb10" block color="#E3E3E3" @click="release_authorization">
            <!--解除授权-->
            <span class="c_red fb">{{ $t("lang.uc_docDet_noAuth") }}</span>
          </van-button>
        </div>
      </div>
    </div>
    <!--修改备用名-->
    <van-popup class="h250" v-model="show" @close="closePopup_aliasSet" round position="bottom" close-on-click-overlay>
      <div class="bc_gray">
        <!--修改备用名-->
        <van-divider class="lh50 c_black f18">{{ $t("lang.uc_docDet_modName") }}</van-divider>
      </div>
      <van-cell class="list_radius mt20 mb50">
        <!--备用名-->
        <van-field v-model="aliasSet" label="" placeholder=""/>
      </van-cell>
      <van-row gutter="20" class="m1500">
        <van-col span="8">
          <van-button @click="closePopup_aliasSet" block color="#EDEDED">
            <!--取消-->
            <span class="c_green fb">{{ $t("lang.uc_docDet_cancel") }}</span>
          </van-button>
        </van-col>
        <van-col span="16">
          <van-button @click="saveAliasSet" block color="#42BD56">
            <!--保存-->
            <span class="fb">{{ $t("lang.uc_docDet_save") }}</span>
          </van-button>
        </van-col>
      </van-row>
    </van-popup>
  </section>
</template>

<script>
import {Dialog, Toast} from 'vant';
import {modDoctor, removeGroup, replaceAdmin} from "@/services/doctor";
import constants from "@/constants";
import i18n from "@/lang";
import utils from "@/utils";

export default {
  async mounted() {
    this.mgr = this.$route.params.mgr;
    this.doctor = this.$route.params.doctor;
    if (this.doctor.aliasSet.length > 0) {
      this.aliasSet = this.doctor.aliasSet
    } else {
      this.aliasSet = this.doctor.nameAlias
    }
  },
  data() {
    return {
      show: false,
      value: '',
      mgr: '',
      doctor: '',
      aliasSet: ''
    };
  },
  methods: {
    //弹出修改姓名框
    showPopup_aliasSet() {
      this.show = true;
    },
    //关闭修改姓名框
    closePopup_aliasSet() {
      this.show = false;
    },
    //保存别名
    async saveAliasSet() {
      this.doctor.aliasSet = this.aliasSet;
      let resultData = await modDoctor(this.doctor);
      if (constants.AJAX_OK === resultData.code && resultData.data) {
        this.doctor = resultData.data
        //修改成功
        Toast(i18n.t("lang.uc_docDet_saveSucc"));
      }
      this.show = false;
    },
    //设为管理员
    setting_manager() {
      Dialog.confirm({
        title: i18n.t("lang.uc_docDet_setMgr"),//设置该医生为设备管理员
        message: i18n.t("lang.uc_docDet_tip_noMger"),//设置成功后您将不再是设备管理员，同时该医生现关注的所有设备将被清除。
      })
          .then(async () => {
            // on confirm
            //设置当前医生为管理员
            let resultData = await replaceAdmin(this.mgr.doctorId, this.doctor.doctorId);
            if (constants.AJAX_OK === resultData.code && resultData.data) {
              this.$store.commit("setDoctorWholeObj", resultData.data);
              console.log(JSON.stringify(resultData.data))

              //跳转到首页
              await utils.toForward(this, "/userCenter");
            }
          })
          .catch(() => {
            // on cancel
          });
    },
    release_authorization() {
      Dialog.confirm({
        title: i18n.t("lang.uc_docDet_noAuth"),//解除授权
        message: i18n.t("lang.uc_docDet_confirm"),//确认后该医生将不可查看您管理的设备监控数据
      })
          .then(async () => {
            // on confirm
            let resultData = await removeGroup(this.doctor.doctorId, this.mgr.doctorId);
            if (constants.AJAX_OK === resultData.code) {
              await this.$router.push("/doctorTeam");
            }
          })
          .catch(() => {
            // on cancel
          });
    },
  },
}
</script>

<style scoped>
</style>
