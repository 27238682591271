<template>
  <section class="van-doc-demo-section">
    <van-cell-group class="mt15">
      <!-- 姓名 -->
      <van-field v-model="info_name" :left-icon="require('@/assets/img/patient_name.png')" clearable
                 :label="$t('lang.patientName')" :placeholder="$t('lang.pleaseInput')" :error-message="nameErrorMsg"/>
      <!-- 床号 -->
      <van-field v-model="info_bed" :left-icon="require('@/assets/img/patient_bed_number.png')" clearable type="digit"
                 :label="$t('lang.patientBed')" :placeholder="$t('lang.pleaseInputNum')" :error-message="bedErrorMsg"/>
      <!-- 科室 -->
      <van-field v-model="info_dep" :left-icon="require('@/assets/img/patient_department.png')" clearable
                 :label="$t('lang.patientDep')" :placeholder="$t('lang.pleaseInput')" :error-message="depErrorMsg"/>
      <!-- 病区 -->
      <van-field v-model="info_ward" :left-icon="require('@/assets/img/patient_inpatient_area.png')" clearable
                 :label="$t('lang.patientWard')" :placeholder="$t('lang.pleaseInput')" :error-message="wardErrorMsg"/>
    </van-cell-group>

    <div class="m15-00 dfr lh24">
      <van-button class="fb" type="primary" block @click="submitInfo">{{ $t("lang.submit") }}</van-button>
    </div>
  </section>
</template>

<script>
import {getPatById, updatePat} from "@/services/ptPatient";
import constants from "@/constants";
import utils from "@/utils";
import i18n from "@/lang";

export default {
  data() {
    return {
      info_name: '',
      info_bed: '',
      info_dep: '',
      info_ward: '',
      nameErrorMsg: "",
      bedErrorMsg: "",
      depErrorMsg: "",
      wardErrorMsg: "",
      patObj: "",
    };
  },
  async mounted() {
    let resultData = await getPatById(this.$store.state.bindDeviceObj.patientId);
    if (constants.AJAX_OK === resultData.code && resultData.data) {
      this.patObj = resultData.data
      this.patObj.editorId = this.$store.state.doctorWholeObj.doctorId
      this.patObj.nameField = i18n.t("lang.patientName")
      this.patObj.bedField = i18n.t("lang.patientBed")
      this.patObj.depField = i18n.t("lang.patientDep")
      this.patObj.wardField = i18n.t("lang.patientWard")

      this.info_name = this.patObj.patName
      this.info_bed = this.patObj.bedNum
      this.info_dep = this.patObj.deptName
      this.info_ward = this.patObj.wardName
    }
  },
  methods: {
    // 提交
    submitInfo: async function () {
      this.nameErrorMsg = ""
      this.bedErrorMsg = ""
      this.depErrorMsg = ""
      this.wardErrorMsg = ""

      // 姓名格式判断
      if (utils.isNull(this.info_name) || this.info_name.length > 15) {
        this.nameErrorMsg = i18n.t("lang.mameInputError");
        return;
      }

      // 床号格式判断
      if (utils.isNull(this.info_bed) || this.info_bed.length > 8) {
        this.bedErrorMsg = i18n.t("lang.bedInputError");
        return;
      }

      // 科室格式判断
      if (utils.isNull(this.info_dep) || this.info_dep.length > 6) {
        this.depErrorMsg = i18n.t("lang.depInputError");
        return;
      }

      // 病区格式判断
      if (utils.isNull(this.info_ward) || this.info_ward.length > 4) {
        this.wardErrorMsg = i18n.t("lang.wardInputError");
        return;
      }

      // 容错
      if (null == this.patObj) {
        return;
      }

      this.patObj.patName = this.info_name
      this.patObj.bedNum = this.info_bed
      this.patObj.deptName = this.info_dep
      this.patObj.wardName = this.info_ward
      // 更新患者信息
      let resultData = await updatePat(this.patObj);
      if (constants.AJAX_OK === resultData.code && resultData.data) {
        let dv_device = this.$store.state.bindDeviceObj
        dv_device.titleDesc = this.info_ward + " " + this.info_bed + i18n.t("lang.device_his_bed") + " " + this.info_name
        dv_device.patDeptName = this.info_dep
        this.$store.commit("setBindDeviceObj", dv_device)

        this.$router.go(-1)
      }
    }
  }
};
</script>

<style scoped>

</style>
