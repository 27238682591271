import utils from "@/utils";
import url from "./index"

// 获取消息
export function getMsg(doctorId, page) {
    return utils.getHttp(url.GET_MSG, {
        doctorId: doctorId,
        page: page
    });
}
