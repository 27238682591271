<template>
  <section class="van-doc-demo-section">
    <div class="bb_gray bc_999 tip_sel">
      <div class="p10 of">
        <van-image class="left w14 mt4" :src="require('@/assets/img/sort.png')"/>
        <p class="left f14 lh24 ml5">{{ this.sort_title }}</p><!--病区号+床位号-->
        <van-button class="right" @click="showPopup" color="#CECECE" size="mini" round>
          <span class="p0008 fb c_gray1">{{ $t("lang.device_csel_sort") }}</span><!--排序-->
        </van-button>
      </div>
    </div>
    <div class="h40"></div>
    <div class="m15-00 dfr">
      <van-cell class="list_radius">
        <van-cell v-for="(value,key,index) in doc_devices" :key="index" is-link center>
          <van-image class="left w44 h44" :src="require('@/assets/img/electric_negative_pressure_aspirator.png')"/>
          <div class="left">
            <p class="fb">{{value.titleDesc}}</p>
            <div class="fs80">
              <p class="left fs90 blue_tag">{{value.deviceAlias}}</p>
              <p class="left ml5">{{getDvTime(value)}}</p>
            </div>
          </div>
          <template v-if="value.docAttent == 1" #right-icon>
            <!--已关注-->
            <p class="f12 c_gray1">{{ $t("lang.device_csel_attented") }}</p>
          </template>
          <template v-else #right-icon>
<!--            <van-checkbox :name="item" ref="checkboxes" />-->
            <van-checkbox v-model="value.danxuan" ref="checkboxes"  @change="singleChecked(value.deviceId)"></van-checkbox>
          </template>
        </van-cell>
      </van-cell>
      <div class="van-bottom" v-show="isShow">
        <div class="m00-15">
          <!--添加关注-->
          <van-button @click="onClick2Attent" class="fb mb10" type="primary" block>{{ $t("lang.device_csel_addattent") }}</van-button>
        </div>
      </div>
    </div>
    <!--选择排序方式-->
    <van-popup v-model="sort_show" round position="bottom" close-on-click-overlay>
      <van-cell-group>
       <!-- 病区号+床位号    优先按病区号排序，床位号其次-->
        <van-cell center clickable @click="toGetByBed" :title="$t('lang.device_csel_wandbedno')" :icon="require('@/assets/img/patient_bed_number.png')" :label="$t('lang.device_csel_sortwtobed')">
          <template v-if="sort_type == '1'" #right-icon>
            <van-image class="right w14" :src="require('@/assets/img/cell_selected.png')" />
          </template>
        </van-cell>
        <!--病区号+设备运行时间   优先按病区号排序，设备运行时间其次-->
        <van-cell center clickable @click="toGetByTime" :title="$t('lang.device_csel_wandruntime')" :icon="require('@/assets/img/time.png')" :label="$t('lang.device_csel_sortwtotime')" >
            <template v-if="sort_type == '2'" #right-icon>
                <van-image class="right w14" :src="require('@/assets/img/cell_selected.png')" />
            </template>
        </van-cell>

      </van-cell-group>
    </van-popup>
  </section>
</template>

<script>
  import {getDocDeviceList} from "@/services/dvDevice";
  import {attentDvDoctor} from "@/services/dvDoctor";
  import {Toast} from "vant";
  import constants from "@/constants";
  import i18n from "@/lang";
  import utils from "@/utils";

  export default {

  async mounted() {

      //获取科室组设备
      this.sort_type = "1";  //1按床号 默认按床号排序
      this.sort_title = i18n.t("lang.device_csel_wandbedno");
      this.onClick2GetDocDeviceList();
  },

  data() {
    return {
      doctor: this.$store.state.doctorWholeObj,
      adminDocId:this.$store.state.adminDocId, //设备管理员ID
      doc_devices:"", //科室组设备
      selectedData:[], //选中设备
      isShow:false,
      sort_show: false,
      sort_title:"", //排序标题
      sort_type:"1", //排序类型 1按床号  2按时间
    };
  },

  methods: {
    showPopup() {
        this.sort_show = true;
    },

    singleChecked:function(deviceId){

     let id =  deviceId;
     if (this.selectedData.indexOf(id) > -1)
     {
        this.remove(this.selectedData, id);
     }
     else
     {
        this.selectedData.push(id);
     }

      // 判断checked的值是否还等于商品种类数目，
      if (this.checked == this.doc_devices.length)
      {
        this.checkAll = true;
      }else{
        this.checkAll = false;
      }
      console.log(this.selectedData);
    },

    //数组删除
    remove(arr, val) {
      var index = arr.indexOf(val);
      if (index > -1) {
        arr.splice(index, 1);
      }
    },

      // 获取设备运行时间
      getDvTime: function (docPatObj) {
          //新设备无患者 beginTime为空
          if (!docPatObj.beginTime)
          {
              return docPatObj.deptName;
          }


          let content =  i18n.t("lang.run");
          let runTime_h = parseInt(docPatObj.runTime / 60)
          let runTime_m = docPatObj.runTime - runTime_h * 60
          if (0 !== runTime_h) {
              content += runTime_h + i18n.t("lang.hour2")
          }
          if (0 !== runTime_m) {
              content += runTime_m + i18n.t("lang.min2")
          }
          return content
      },

    // 获取设备，按床号排序
      toGetByBed() {
          this.sort_show = false;
          this.sort_type = "1";
          this.sort_title = i18n.t("lang.device_csel_wandbedno");

          this.onClick2GetDocDeviceList();
      },

      // 取设备，按监控时间排序
      toGetByTime() {
          this.sort_show = false;
          this.sort_type = "2";
          this.sort_title = i18n.t("lang.device_csel_wandruntime");
          this.onClick2GetDocDeviceList();
      },

      //获取设备数组
    onClick2GetDocDeviceList: async function () {

        let dr = await getDocDeviceList(this.adminDocId, this.doctor.doctorId, "3", this.sort_type);
        console.log(dr);
        this.doc_devices =dr.data;
        console.log(this.doc_devices);
    },

    // 关注新设备
    onClick2Attent: async function () {

      console.log(this.doctor.doctorId, this.selectedData);

      //  医生关注新设备
      let resultData = await attentDvDoctor(this.doctor.doctorId, this.selectedData);

      if (constants.AJAX_OK === resultData.code && resultData.data) {
        Toast({
          message: i18n.t("lang.device_csel_attentsuccess"), //关注成功
          position: 'bottom',
        });

        await this.$router.replace("/followDeviceList")
      }
      else
      {
        Toast({
          message: i18n.t("lang.device_csel_attentfail"), // 关注失败
          position: 'bottom',
        });
      }
    },

  },

    watch: {
      selectedData(value) {

        console.log(value);
        if (utils.isNotNull(value))
        {
          this.isShow = true;
        }
        else
        {
          this.isShow = false;
        }
      }
    }

}
</script>

<style scoped>
.p0008{padding: 0px 8px;}
</style>