<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr lh24">
      <p class="f22 mt50 fb">{{ doctor.nameAlias }}</p>
      <p class="f14 c_gray1 mt10">{{ $t('lang.inputInviteCode') }}</p>
      <div class="pas_area mt36">

        <!-- 密码输入框 -->
        <van-password-input
            :value="code"
            :error-info="errorInfo"
            :gutter="10"
            :focused="showKeyboard"
            @focus="showKeyboard = true"
        />

        <!-- 数字键盘 -->
        <van-number-keyboard
            v-model="code"
            :show="showKeyboard"
            @blur="showKeyboard = false"
            @input="onInput"
            @delete="onDelete"
        />
      </div>
    </div>
  </section>
</template>

<script>
import {checkCode} from "@/services/doctor";
import constants from "@/constants";
import utils from "@/utils";
import i18n from "@/lang";

export default {
  async mounted() {
    this.doctor = this.$store.state.doctorWholeObj;
  },
  data() {
    return {
      showKeyboard: true,
      doctor: this.$store.state.doctorWholeObj,
      code: '',
      errorInfo: ''
    };
  },
  methods: {
    async onInput(key) {
      this.code = (this.code + key).slice(0, 6);
      if (this.code.length == 6) {
        let resultData = await checkCode(this.doctor.doctorId, this.code);
        console.log(resultData);
        console.log(resultData.data);
        if (constants.AJAX_OK === resultData.code && resultData.data) {
          if (resultData.data == constants.CODE_STATE_TRUE) {
            // 设置全局变量
            this.doctor.admin = constants.DocDeviceAdmin.NO;
            utils.setDoctorCache(this, this.doctor);
            await this.$router.push("/deviceCenter");
          } else if (resultData.data == constants.CODE_STATE_EXISTS) {
            this.errorInfo = i18n.t("lang.docInGroup");
          } else {
            this.errorInfo = i18n.t("lang.inviteCodeError");
          }
        }
      }
    },
    onDelete() {
      this.code = this.code.slice(0, this.code.length - 1);
      this.errorInfo = '';
    }
  }
}
</script>

<style scoped>
.pas_area .van-password-input {
  margin: 0px;
}
</style>
