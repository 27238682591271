import utils from "@/utils";
import url from "./index"

// 查询设备历史患者
export function getDvHisPatient(deviceId, month) {
    return utils.getHttp(url.GET_DV_HIS_PATIENT, {
        deviceId: deviceId,
        month: month,
    });
}

// 获取设备监控数据历史月
export function getDeviceHisMonth(deviceId) {
    return utils.getHttp(url.GET_DEVICE_HIS_MONTH, {
        deviceId: deviceId,
    });
}

// 发邮件导出设备历史患者数据
export function exportDeviceHisPati(mail, dvPatientIds) {
    let doctorObj = {mail: mail, dvPatientIds: dvPatientIds};
    return utils.postHttp(url.EXPORT_DEVICE_HIS_PATI, doctorObj)
}

// 查询设备历史患者数目
export function getDeviceHisPatiCount(deviceId) {
    return utils.getHttp(url.GET_DEVICE_HIS_PATI_COUNT, {
        deviceId: deviceId,
    });
}