126192<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr">
      <van-cell class="list_radius">
        <van-cell v-for="(value,key,index) in dept_groups" :key="index" center is-link :title="value.deptName" :value="toDeviceCountDesc(value)" :label="value.instName" @click="onCLick2SelectDevice(value)" />
        </van-cell>
    </div>
  </section>
</template>

<script>
import { Dialog } from 'vant';
import {getDoctorGroups} from "@/services/doctor";
import i18n from "@/lang";

export default {

  async mounted() {

    let dr = await getDoctorGroups(this.doctor.doctorId);
    console.log(dr);
    this.dept_groups =dr.data;
    console.log(this.dept_groups);
  },

  data() {
    return {
      doctor: this.$store.state.doctorWholeObj,
      dept_groups:"", //科室组
    };
  },

  methods: {
    setting_smit() {
      Dialog.alert({
        title: i18n.t("lang.device_group_nodevice"), //暂无设备
        message: i18n.t("lang.device_group_pcdadminbind"),//请联系设备管理员绑定设备
      })
          .then(() => {
            // on close
          })
    },

    // 非管理员绑定 选择设备
    onCLick2SelectDevice(deptGroup) {
      console.log(deptGroup);
      if(0 == deptGroup.deviceCount)
      {
        this.setting_smit(); //暂无设备
      }
      else
      {
        this.$store.commit("setAdminDocId", deptGroup.adminId);
        this.$router.push("/addClassDeviceSel");
      }
    },

    toDeviceCountDesc(deviceObj){
      //5+台设备
      return deviceObj.deviceCount +  i18n.t("lang.device_group_onedevice");

    }

  },
}
</script>

<style scoped>

</style>