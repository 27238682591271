/**
 *
 * @desc 从缓存中获取医生对象
 * @return {{expiryDate: string, instId: string, deptName: string, nameAlias: string, doctorId: string, inviteCode: string, mobile: string, admin: string, headPortrait: string, aliasSet: string}}
 */
function getDoctorCache(t) {
    return t.$store.state.doctorWholeObj;
}


module.exports = getDoctorCache
