<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr">
      <!--消息接收设置-->
      <div v-if="showMsg">
        <van-cell @click="toMessageSetting" class="list_radius">
          <van-cell :title="$t('lang.uc_more_set')" is-link size="large"
                    :icon="require('@/assets/img/message_setting.png')"/>
        </van-cell>
      </div>

      <!--语言设置-->
      <div class="mt5">
        <van-cell @click="showPopup_language" class="list_radius">
          <van-cell :title="$t('lang.uc_more_language')" is-link size="large" :value="curLanguage"
                    :icon="require('@/assets/img/language.png')"/>
        </van-cell>

        <van-popup v-model="showLanguage" position="bottom" :overlay="true">
          <!--选择语言-->
          <van-picker show-toolbar :title="$t('lang.uc_more_chooseLanguage')" :columns="languageList"
                      @cancel="onCancel_language" @confirm="onConfirm_language"/>
        </van-popup>
      </div>

      <div class="mt5">
        <van-cell class="list_radius">
          <!--服务协议-->
          <van-cell @click="toServiceAgreement" :title="$t('lang.uc_more_fwxy')" is-link size="large"
                    :icon="require('@/assets/img/service_agreement.png')"/>
          <!--隐私保护-->
          <van-cell @click="toPrivacyProtection" :title="$t('lang.uc_more_ysq')" is-link size="large"
                    :icon="require('@/assets/img/privacy_protection.png')"/>
        </van-cell>
      </div>
      <div class="mt5">
        <van-cell class="list_radius">
          <!--给我们评分-->
          <van-cell v-if="showScore" @click="toScore" :title="$t('lang.uc_more_score')" is-link size="large"
                    :icon="require('@/assets/img/score.png')"/>

          <!--客服电话-->
          <van-cell :title="$t('lang.uc_more_serviceMobile')" size="large" value="(0574)63996801"
                    :icon="require('@/assets/img/service_telephone.png')"/>
        </van-cell>
      </div>
      <!--热线服务时间-->
      <p class="mt5 f12 ml15 c_666">{{ $t('lang.uc_more_serviceTime') }}</p>
      <div class="mt10">
        <!--当前版本-->
        <van-cell class="list_radius">
          <van-cell :title="$t('lang.uc_version')" size="large" value="1.0.1"
                    :icon="require('@/assets/img/edition.png')"/>
        </van-cell>
      </div>

      <!--退出-->
      <van-button @click="show = true" class="mt10" block color="#E3E3E3">
        <span class="c_red fb">{{ $t('lang.uc_more_exit') }}</span>
      </van-button>

      <div class="van-bottom1">
        <!--公司 单位 版权-->
        <van-divider class="tc">{{ $t('lang.uc_more_company') }}<br>{{ $t('lang.uc_more_bq') }}
        </van-divider>
        <van-divider class="tc f12">Copyright 2021 All Rights Reserved</van-divider>
      </div>
    </div>
    <van-action-sheet
        v-model="show"
        :actions="actions"
        :cancel-text="$t('lang.uc_more_cancel')"
        close-on-click-action
        @select="onSelect"
    />
  </section>
</template>

<script>

import utils from "@/utils";
import constants from "@/constants";
import i18n from "@/lang";

export default {
  async mounted() {
    let accessType = this.$store.state.accessType;
    // APP
    if (constants.AccessType.APP == accessType) {
      this.showMsg = true;
      this.showScore = true;
    }
    // WECHAT
    else if (constants.AccessType.WECHAT == accessType) {
      this.showMsg = false;
      this.showScore = false;
    }

    // 默认语言
    if ('en' === this.$i18n.locale) {
      this.curLanguage = this.languageList[0]
    } else if ('zh' === this.$i18n.locale) {
      this.curLanguage = this.languageList[1]
    }
  },
  data() {
    return {
      show: false,//"是否显示退出按钮"
      showMsg: true,//是否显示"消息接收设置"
      showScore: true,//是否显示"给我们评分"
      actions: [
        {name: i18n.t("lang.uc_more_comfirmExit"), color: '#ee0a24'},
      ],
      showLanguage: false,//是否显示"选择语言按钮"
      languageList: [i18n.t("lang.uc_more_en"), i18n.t("lang.uc_more_zh")],
      curLanguage: '',
    };
  },
  methods: {
    onSelect() {
      // 默认情况下点击选项时不会自动收起
      let accessType = this.$store.state.accessType;
      let accountId = this.$store.state.cacheAccountId;

      // 清除缓存
      this.$store.commit("setDoctorWholeObj", "")
      this.$store.commit("setCacheAccountId", "")
      this.$store.commit("setCacheOpenId", "")
      utils.removeStorage(constants.WX_OPENID);
      utils.removeStorage(constants.WX_ACCOUNT_ID);

      this.$router.push({path: '/', query: {accessType: accessType, accountId: accountId}});
    },
    //跳转到服务协议
    toServiceAgreement() {
      utils.toForward(this, "/serviceAgreement");
    },
    //跳转到隐私权政策
    toPrivacyProtection() {
      utils.toForward(this, "/privacyProtection");
    },
    toMessageSetting() {
      //消息设置
      utils.toForward(this, "/messageSetting");
    },
    toScore() {
      //给我们评分
      utils.jsUseApp("appStore")
    },
    //弹出修改语言框
    showPopup_language() {
      this.showLanguage = true;
    },
    //确定修改语言框
    onConfirm_language(value, index) {
      this.curLanguage = value
      if (0 === index) {
        this.$i18n.locale = 'en'
      } else if (1 === index) {
        this.$i18n.locale = 'zh'
      }
      utils.setStorage(constants.LANGUAGE, this.$i18n.locale)

      let accessType = this.$store.state.accessType
      // APP
      if (constants.AccessType.APP == accessType) {
        utils.jsUseApp("appLanguage", {
          lang: this.$i18n.locale
        })
      }
      this.showLanguage = false;
    },
    //取消修改语言框
    onCancel_language() {
      this.showLanguage = false;
    },
  },
}
</script>

<style scoped>

</style>
