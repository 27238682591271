<template>
	<div>
		<router-view />
		<!-- 开启底部安全区适配 -->
		<van-number-keyboard safe-area-inset-bottom />
	</div>
</template>

<script>
	export default {
		name: 'App',
		components: {},
	}
</script>

<style>
	img {
		image-rendering: -moz-crisp-edges;
		image-rendering: -o-crisp-edges;
		image-rendering: -webkit-optimize-contrast;
		image-rendering: crisp-edges;
		-ms-interpolation-mode: nearest-neighbor;
	}
</style>
