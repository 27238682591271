<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr lh24">

      <!-- 手机号 -->
      <div class="bb_gray mt90">
        <van-cell class="list_radius van_bg_zdy1">
          <!--新手机号 请输入-->
          <van-field :label="$t('lang.uc_newPhone_tip')" :error-message="showTips" v-model="mobile"
                     :placeholder="$t('lang.uc_newPhone_input')"/>
        </van-cell>
      </div>

      <!--获取验证码-->
      <van-button @click="toNewPhoneAuthCode" class="mt30" type="primary" block>{{ $t("lang.uc_newPhone_getCode") }}
      </van-button>
    </div>
  </section>
</template>

<script>

import {getDoctorByMobile} from "@/services/doctor";
import constants from "@/constants";
import i18n from "@/lang";
import utils from "@/utils";

export default {
  data() {
    return {
      mobile: '',
      showTips: ''
    }
  },
  methods: {
    // 跳转到修改手机号
    async toNewPhoneAuthCode() {
      // 手机验证
      if (utils.isPhoneNum(this.mobile)) {
        let resultData = await getDoctorByMobile(this.mobile);
        if (constants.AJAX_OK === resultData.code && resultData.data && resultData.data.length > 0) {
          // 该手机号已被使用
          this.showTips = i18n.t("lang.uc_newPhone_isExists")
        } else {
          // 如果验证成功，跳转到输入验证码页面
          await this.$router.push({name: 'newPhoneAuthCode', params: {mobile: this.mobile}});
        }
      } else {
        //请输入正确的手机号
        this.showTips = i18n.t("lang.uc_newPhone_reInput")
      }
    }
  }
}
</script>

<style scoped>
.van_bg_zdy1 .van-cell {
  background-color: #EDEDED;
  padding: 10px 0px;
}
</style>
