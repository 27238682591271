/**
 *
 * @desc   格式化字符串
 * @param  {String} formatted
 * @param  {[String]} args
 * @return {String}
 */
function stringFormat(formatted, args) {
    for (let i = 0; i < args.length; i++) {
        let regexp = new RegExp('\\{' + i + '\\}', 'gi')
        formatted = formatted.replace(regexp, args[i])
    }
    return formatted
}

module.exports = stringFormat