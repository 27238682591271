/**
 *
 * @desc  非null判断
 * @param  {Object} msg
 * @return {Boolean}
 */
export function isNotNull(msg) {
    if (null != msg && typeof (msg) !== 'undefined' && Object.keys(msg).length > 0) {
        return true
    } else {
        return false
    }
}

/**
 *
 * @desc  null判断
 * @param  {Object} msg
 * @return {Boolean}
 */
export function isNull(msg) {
    return !isNotNull(msg)
}

