import utils from "@/utils";
import url from "./index"

// 根据微信openId查询医生数据
export function getDoctorByWx(openId, accountId, unionId) {
    return utils.getHttp(url.GET_DOCTOR, {
        openId: openId,
        accountId: accountId,
        unionId: unionId
    });
}

// 根据手机号查询医生数据
export function getDoctorByMobile(mobile) {
    return utils.getHttp(url.GET_DOCTOR_BY_MOBILE, {
        mobile: mobile
    });
}

// 根据doctorId查询医生信息
export function getDoctorById(doctorId) {
    return utils.getHttp(url.GET_DOCTOR_PATH + doctorId);
}

// 修改医生信息
export function modDoctor(doctor) {
    return utils.putHttp(url.MOD_DOCTOR, doctor);
}

// 获取短信验证码
export function getSms(mobile) {
    return utils.getHttp(url.GET_SMS, {
        mobile: mobile
    });
}

// 注册医生帐号
export function addDoctor(doctor) {
    return utils.postHttp(url.MOD_DOCTOR, doctor);
}

// 管理员查看已经邀请的医生
export function getInviteDoctor(adminId) {
    return utils.getHttp(url.GET_INVITE_DOCTOR, {adminId: adminId})
}

// 管理员获取6位邀请码
export function getInviteCode(doctorId) {
    return utils.getHttp(url.GET_INVITE_CODE, {doctorId: doctorId})
}

// 申请管理员
export function applyAdmin(doctor) {
    return utils.putHttp(url.APPLY_ADMIN, doctor)
}

// 更换管理员(mgrId当前管理员id，doctorId将要修改的管理员id)
export function replaceAdmin(mgrId, doctorId) {
    let doctor = {adminId: mgrId, doctorId: doctorId};
    return utils.putHttp(url.REPLACE_ADMIN, doctor)
}

// 解除授权
export function removeGroup(doctorId, adminId) {
    console.log(doctorId);
    return utils.deleteHttp(url.REMOVE_GROUP_PATH + doctorId + '/' + adminId);
}

// 查看医生已加入的群组
export function getDoctorGroups(doctorId) {
    return utils.getHttp(url.GET_DOCTOR_GROUPS, {doctorId: doctorId})
}

// 查看同一群组里的医生
export function getGroupDoctor(instId, deptName) {
    return utils.getHttp(url.GET_GROUP_DOCTOR, {instId: instId, deptName: deptName})
}

// 医生邀请码检查
export function checkCode(doctorId, code) {
    return utils.getHttp(url.CHECK_CODE, {doctorId: doctorId, code: code})
}
