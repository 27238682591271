let getOS = require("@/utils/device/getOS")
let replaceAll = require("@/utils/string/replaceAll")

/**
 *
 * @desc js调用app
 * @param name 方法名
 * @param params 参数（json）
 */
function jsUseApp(name, params) {
    if (getOS() === 'android') {
        if (params) {
            eval('window.app.' + name + '("' + replaceAll(JSON.stringify(params), '"', "'") + '")')
        } else {
            eval('window.app.' + name + '()')
        }
    } else if (getOS() === 'ios') {
		// console.log(name)
		// console.log(params)
        if (params) {
            eval('window.webkit.messageHandlers.' + name + '.postMessage(' + JSON.stringify(params) + ')')
        } else {
            eval('window.webkit.messageHandlers.' + name + '.postMessage({})')
        }
    }
}

module.exports = jsUseApp;
