<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr">
      <div class="tc mt50">
        <img :src="require('@/assets/img/electric_negative_pressure_aspirator_mid.png')" class="w100">
        <!--电动负压吸引器-->
        <p class="f20 fb mt10">{{$t("lang.device_binded_alldevicename")}}</p>
        <!--该设备已被添加-->
        <p class="f12 mt5 c-orange">{{$t("lang.device_binded_added")}}</p>
        <div class="bb_gray f14 of p15 mt30">
          <!--序列号-->
          <p class="left">{{$t("lang.device_binded_seqnum")}}</p>
          <p class="right c_gray1">{{dv_device.deviceNum}}</p>
        </div>
        <div class="bb_gray f14 of p15 mt10">
          <!--别名-->
          <p class="left">{{$t("lang.device_binded_alias")}}</p>
          <p class="right c_gray1">{{dv_device.deviceAlias}}</p>
        </div>
        <div class="bb_gray f14 of p15 mt10">
          <!--管理员-->
          <p class="left">{{$t("lang.device_binded_admin")}}</p>
          <p class="right c_gray1">{{dv_device.nameAlias}}</p>
        </div>
        <div class="bb_gray f14 of p15 mt10">
          <!--机构-->
          <p class="left">{{$t("lang.device_binded_inst")}}</p>
          <p class="right c_gray1">{{dv_device.instName}}</p>
        </div>
        <div class="bb_gray f14 of p15 mt10">
          <!--科室-->
          <p class="left">{{$t("lang.device_binded_dep")}}</p>
          <p class="right c_gray1">{{dv_device.deptName}}</p>
        </div>
        <div class="bb_gray f14 of p15 mt10">
          <!--病区-->
          <p class="left">{{$t("lang.device_binded_ward")}}</p>
          <p class="right c_gray1">{{dv_device.wardName}}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
name: "ScanResult",
  data() {
    return {
      show: false,
      doctor: this.$store.state.doctorWholeObj,
      dv_device: this.$store.state.bindDeviceObj,
    };
  },
}
</script>

<style scoped>
.m20h{margin: 20vh auto auto auto;}
</style>