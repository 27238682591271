<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr lh24">
      <p class="f22 mt50 fb">{{ $store.state.doctorWholeObj.nameAlias }}</p>
      <p class="f14 c_gray1 mt10">{{ $t("lang.applyTip") }}</p>
      <div class="bb_gray mt30">
        <van-cell @click="selectMap" class="list_radius van_bg_zdy1">
          <van-field readonly v-model="instName" :label="$t('lang.instName')" :placeholder="$t('lang.pleaseSet')"/>
        </van-cell>
      </div>
      <div class="bb_gray mt10">
        <van-cell class="list_radius van_bg_zdy1">
          <van-field v-model="depName" :label="$t('lang.dep')" :error-message="depErrorMsg"
                     :placeholder="$t('lang.pleaseInput')"/>
        </van-cell>
      </div>
      <van-button @click="submitInfo" class="mt30" type="primary" block>{{ $t("lang.submit") }}</van-button>
    </div>
  </section>
</template>

<script>
import utils from "@/utils";
import {Toast} from "vant";
import i18n from "@/lang";

export default {
  name: "ApplyAdministrator",
  data() {
    return {
      instName: this.$store.state.applyInfo.inst.instName,
      depName: this.$store.state.applyInfo.depName,
      depErrorMsg: "",
    }
  },
  methods: {
    // 选择地图
    selectMap: function () {
      let applyInfo = this.$store.state.applyInfo
      applyInfo.depName = this.depName
      this.$store.commit("setApplyInfo", applyInfo)

      this.$router.push("/selectMap")
    },
    // 提交信息
    submitInfo: function () {
      if (utils.isNull(this.instName)) {
        this.errorTip(i18n.t("lang.instNameNull"));
        return;
      }
      if (utils.isNull(this.depName) || this.depName.length > 8) {
        this.errorTip(i18n.t("lang.depNameError"));
        return;
      }

      // 保存科室信息
      let applyInfo = this.$store.state.applyInfo
      applyInfo.depName = this.depName
      this.$store.commit("setApplyInfo", applyInfo)

      this.$router.push("/applyAdminSure")
    },
    // 错误提示
    errorTip: (tip) => {
      Toast({
        message: tip,
        position: 'bottom',
      });
    }
  },
}
</script>

<style scoped>
.van_bg_zdy1 .van-cell {
  background-color: #EDEDED;
  padding: 10px 0px;
}
</style>
