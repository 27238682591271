<template>
  <section class="van-doc-demo-section">
    <!-- 绑定微信 -->
    <div class="bc_999">
      <p class="f14 c_green tc pt20">{{ $t("lang.analogTip") }}</p>
      <van-row class="mt10 pb20">
        <van-col span="7"></van-col>
        <van-col span="10">
          <van-button @click="getWXCode" type="primary" size="small" round block>{{ $t("lang.login") }}</van-button>
        </van-col>
        <van-col span="7"></van-col>
      </van-row>
    </div>

    <!-- 演示数据 -->
    <div class="m15-00 dfr">
      <van-cell class="list_radius" to="/analogData">
        <van-cell is-link center>
          <van-image class="left mt14 w44 h44" :src="require('@/assets/img/electric_negative_pressure_aspirator.png')"/>
          <div class="left">
            <p class="fb">{{ $t("lang.analogPat") }}</p>
            <div class="fs80">
              <p class="left fs90 blue_tag">{{ $t("lang.analog1Dev") }}</p>
              <p class="left ml5">{{ $t("lang.analog1Dep") }}｜{{ $t("lang.analog1Time") }}</p>
            </div>
            <p class="fs80 c_gray1">{{ $t("lang.analog1Inst") }}</p>
          </div>
        </van-cell>
      </van-cell>
    </div>
  </section>
</template>

<script>
import utils from "@/utils";
import {getWXAccount, getWXUserInfo} from "@/services/wxAccount";
import {getDoctorByWx} from "@/services/doctor";
import constants from "@/constants";

export default {
  name: "NotLogin",
  mounted() {
    // 为扫码赋URL
    this.$store.commit("setInitUrl", window.location.href)
	console.log(window.location.href)
    // 版本标识
    let versionFlag = this.$route.query.versionFlag;
    let accessType = this.$route.query.accessType;
    let accountId = this.$route.query.accountId;
    let code = this.$route.query.code;
    // 版本判断是否有更新
    if (this.judgeVersion(versionFlag)) {
      this.$router.push({path: '/appLogin', query: this.$route.query})
      return;
    }

    if (accessType && accountId) {
      this.$store.commit("setAccessType", accessType);
      this.reqAccountId = accountId

      // 已经绑定
      if (this.$store.state.cacheOpenId && accountId === this.$store.state.cacheAccountId) {

        // 容错，这边不需要unionId
        this.$store.commit("setUnionId", '')

        this.getWXBingUser(true);
      }

      if (code) {
        this.getWXOpenId(code, accountId);
      }
    }
    // 异常跳转
    else {
      this.$router.push("/errorPage")
    }
  },
  data() {
    return {
      reqAccountId: ""
    }
  },
  methods: {
    // 版本判断是否有更新
    judgeVersion: function (version) {
      if (version) {
        if (parseInt(version) > constants.VERSION_FLAG) {
          return true;
        }
      }

      return false;
    },

    // 获取微信code
    getWXCode: async function () {
      let accessType = this.$store.state.accessType
      // APP
      if (constants.AccessType.APP == accessType) {
        utils.jsUseApp("appLogin")
      }
      // WECHAT
      else if (constants.AccessType.WECHAT == accessType) {
        // 获取微信公众号对象
        let resultData = await getWXAccount(this.reqAccountId);
        if (constants.AJAX_OK === resultData.code && resultData.data) {
          // 获取微信code
          window.location.replace(utils.stringFormat(constants.WechatAuthorize.GET_CODE,
              [resultData.data.appId, encodeURIComponent(window.location.href), constants.WechatAuthorize.SCOPE_USERINFO]))
        }
      }
    },

    // 获取微信openId
    getWXOpenId: async function (code, accountId) {

      // 获取微信公众号对象
      let resultData = await getWXUserInfo(accountId, code);
      if (constants.AJAX_OK === resultData.code && resultData.data) {
        // 临时保存openId和accountId
        this.$store.commit("setCacheAccountId", accountId);
        this.$store.commit("setCacheOpenId", resultData.data.openid);

        let doctor = this.$store.state.doctorWholeObj;
        doctor.nameAlias = resultData.data.nickname
        doctor.headPortrait = resultData.data.headimgurl
        this.$store.commit("setDoctorWholeObj", doctor);

        this.$store.commit("setUnionId", resultData.data.unionid);

        await this.getWXBingUser(false);
      }
    },
    // 获取微信绑定用户
    getWXBingUser: async function (isClearCache) {
      let openId = this.$store.state.cacheOpenId
      let accountId = this.$store.state.cacheAccountId
      let unionId = this.$store.state.unionId

      // 获取微信绑定用户
      let resultData = await getDoctorByWx(openId, accountId, unionId);
      if (constants.AJAX_OK === resultData.code && resultData.data) {
        this.$store.commit("setDoctorWholeObj", resultData.data)

        // 保存openId，accountId进入缓存
        utils.setStorage(constants.WX_OPENID, openId);
        utils.setStorage(constants.WX_ACCOUNT_ID, accountId);

        await this.$router.push("/deviceCenter");
      } else {
        if (isClearCache) {
          // 清除缓存
          this.$store.commit("setDoctorWholeObj", "")
          this.$store.commit("setCacheAccountId", "")
          this.$store.commit("setCacheOpenId", "")
          utils.removeStorage(constants.WX_OPENID);
          utils.removeStorage(constants.WX_ACCOUNT_ID);
        } else {
          await this.$router.push("/login")
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
