<template>
  <section class="van-doc-demo-section">
    <div class="m15 dfr ta">
      <div class="page_faq f14 mt15">
        <p>您的信任对我们非常重要，我们深知个人信息安全的重要性，我们将会按照法律法规要求，采取安全保护措施保护您的个人信息安全 。鉴此，胸腔负压吸引器服务提供者（或简称“我们”）制定本《隐私权政策》并提醒您：</p>
        <p class="em_text">请您在使用胸腔负压吸引器的各项服务或产品前，务必仔细阅读并透彻理解本政策，特别是以<b>粗体</b>标识的条款，您应重点阅读，在确认充分理解并同意后再开始使用。在任何情况下，您的使用行为将被视为对本政策全部内容的认可。</p>
        <p>如您对本政策内容有任何疑问、意见或建议，您可通过本政策提供的各种联系方式与我们联系。</p>
        <br>
        <p class="sectionTitle fs120 em_text" style="font-size: 1.5em;">定义</p>
        <p><b>胸腔负压吸引器</b>：指胸腔负压吸引器客户端。</p>
        <p><b>胸腔负压吸引器服务提供者</b>：指宁波新跃医疗科技股份有限公司。</p>
        <p><b>用户</b>：指胸腔负压吸引器产品或服务的使用人，在本政策中更多地称为“您”或“用户”。</p>
        <p><b>个人信息</b>：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</p>
        <p><b>个人信息删除</b>：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。</p>
        <p><b>未成年人</b>：指不满十八周岁的公民。</p>
        <br>
        <p class="sectionTitle fs120 em_text" style="font-size: 1.5em;">隐私权政策</p>
        <p>本隐私权政策部分将帮助您了解以下内容：</p>
        <p style="color:#828999;">一、我们如何收集和使用您的个人信息</p>
        <p style="color:#828999;">二、我们如何使用 Cookie 和同类技术</p>
        <p style="color:#828999;">三、我们如何共享您的个人信息</p>
        <p style="color:#828999;">四、我们如何保护和保存您的个人信息</p>
        <p style="color:#828999;">五、您如何管理您的个人信息</p>
        <p style="color:#828999;">六、如何联系我们</p>
        <p style="color:#828999;">七、本隐私政策如何更新</p>
        <br>
        <br>
        <p class="em_text f20">一、我们如何收集和使用您的个人信息</p>
        <p>我们会为实现本政策所述的以下功能，收集和使用您的个人信息：</p>
        <br>
        <p class="f18 fb">为您提供注册与认证功能</p>
        <p>您需要注册一个账户，以便于我们为您提供服务。当您注册时，请您至少提供您的<b>用户名、密码、所在国家/地区、手机号码。</b>您也可以选择是否向您的账户提供以下有关您的信息，例如<b>您的姓名、性别、年龄、工作单位、地址等。</b>我们将通过发送短信验证码的方式验证您的手机号是否有效。</p>
        <p>您的登录名<b>包括但不限于您的手机号，您可以完善昵称、密码、头像等相关信息</b>。</p>
        <p>如成为胸腔负压吸引器平台的注册医生，您需要提供包括但不限于<b>机构名称、姓名、手机号码、所在科室。</b>如果您未提供这些信息，或者所提供的信息与医院官方提供的这些信息存在不匹配情况，将会影响到您使用胸腔负压吸引器的服务。</p>
        <p>您提供的上述信息，将在您使用服务期间持续授权我们使用。在您注销账号时，我们将停止使用上述信息。</p>
        <br>
        <p class="f18 fb">为您提供产品/服务信息展示与定向推送功能</p>
        <p>为向您提供更契合您需求的页面展示和搜索结果、了解产品适配性、识别账号异常状态，预防信息泄漏险，最终保障向您提供安全稳定的服务，我们会收集关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：</p>
        <p><b>设备或软件信息</b> ：我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如设备型号、操作系统版本等软硬件特征信息）、设备所在位置相关信息（例如IP 地址、GPS位置以及能够提供相关信息的Wi-Fi 接入点、蓝牙和基站等传感器信息）、您用于接入我们的服务的其他程序所提供的的配置信息以及您在使用我们服务时搜索和浏览的信息，例如您使用的搜索关键字、访问页面等。<b>请注意，单独的设备信息是无法识别特定自然人身份的信息。</b></p>
        <p><b>客户服务信息</b> ：当您通过我们的平台与我们进行客户服务互动时，我们将使用有关您的以下信息，包括您的胸腔负压吸引器平台的<b>账户数据、姓名、电话、工作单位、部门以及您提出的问题内容或您提出的请求。</b></p>
        <p><b>用户反馈信息</b> ：您可以选择通过平台提供的各种渠道提交关于我们的产品和/或服务的意见、问题、要求或投诉。当您这样做时，我们可能会向您收集以下个人信息: 您的胸腔负压吸引器平台的<b>账户数据、工作单位、部门、您的意见/问题/要求/投诉的详细信息。</b>我们会使用这些个人信息来回复您的问题，满足您的要求，解决您的投诉，以及改善我们的平台界面、产品和服务。</p>
        <br>
        <p class="f18 fb">设备权限调用</p>
        <p>为向您提供便捷、优质的服务，我们可能会调用您设备的一些权限，以下是我们可能调用的设备权限列表及对应的使用目的说明，您有权随时选择关闭下列权限的授权，但可能会影响您正常使用我们服务或产品的部分或全部功能。</p>
        <table border="1" class="t1 tc b_gray2 fs70">
          <thead>
          <td width="10%" class="p5">设备权限</td>
          <td width="15%" class="p5">对应业务功能</td>
          <td width="25%" class="p5">调用权限的目的</td>
          <td width="20%" class="p5">是否询问</td>
          <td width="5%" class="p5">可否关闭权限</td>
          <td width="25%" class="p5">如果关闭权限会影响什么功能</td>
          </thead>
          <tr class="a1 c_999">
            <td class="p5">位置</td>
            <td class="p5">用于基于位置医院信息展示</td>
            <td class="p5">通过用户当前位置，优化用户搜索等信息展示推荐</td>
            <td class="p5">用户首次打开移动客户端时会弹窗询问</td>
            <td class="p5">是</td>
            <td class="p5">用户将无法准确使用基于位置的医院信息</td>
          </tr>
          <tr class="a1 c_999">
            <td class="p5">相机</td>
            <td class="p5">扫描设备二维码</td>
            <td class="p5">扫描设备二维码完成设备添加</td>
            <td class="p5">用户使用相应服务前会弹窗询问</td>
            <td class="p5">是</td>
            <td class="p5">用户将无法开启摄像头</td>
          </tr>
          <tr class="a1 c_999">
            <td class="p5">无线数据</td>
            <td class="p5">全部功能</td>
            <td class="p5">连接网络</td>
            <td class="p5">用户首次打开手机客户端时会弹窗询问</td>
            <td class="p5">是</td>
            <td class="p5">移动客户端无法正常使用</td>
          </tr>
          <tr class="a1 c_999">
            <td style="padding:5px;">存储</td>
            <td style="padding:5px;">全部功能</td>
            <td style="padding:5px;">写入存储用户信息，存储日志等信息</td>
            <td style="padding:5px;">用户首次打开手机客户端时会弹窗询问</td>
            <td style="padding:5px;">是</td>
            <td style="padding:5px;">移动客户端无法正常使用</td>
          </tr>
        </table>
        <br>
        <p class="f18 fb">其他</p>
        <p class="em_text">若您提供的信息中含有其他用户的个人信息，在向我们提供这些个人信息之前，您需确保您已经取得合法的授权。</p>
        <p>我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</p>
        <p>征得授权同意的例外</p>
        <p>根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：</p>
        <ul class="c_gray1 pl20">
          <li><p>☉ 与国家安全、国防安全有关的；</p></li>
          <li><p>☉ 与公共安全、公共卫生、重大公共利益有关的；</p></li>
          <li><p>☉ 与犯罪侦查、起诉、审判和判决执行等有关的；</p></li>
          <li><p>☉ 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</p></li>
          <li><p>☉ 所收集的个人信息是您自行向社会公众公开的；</p></li>
          <li><p>☉ 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p></li>
          <li><p>☉ 根据您的要求签订合同所必需的；</p></li>
          <li><p>☉ 用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</p></li>
          <li><p>☉ 为合法的新闻报道所必需的；</p></li>
          <li><p>☉ 学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p></li>
          <li><p>☉ 法律法规规定的其他情形。</p></li>
        </ul>
        <br>
        <p>请知悉，根据适用的法律，若我们<b>对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原</b>，或我们可能会对收集的信息进行去标识化地研究、统计分析和预测，用于改善胸腔负压吸引器平台的内容和布局，为商业决策提供产品或服务支撑，以及改进我们的产品和服务（包括使用匿名数据进行机器学习或模型算法训练），<b>则此类处理后数据的使用无需另行向您通知并征得您的同意。</b></p>
        <p>如我们停止运营胸腔负压吸引器平台，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。</p>
        <br>
        <br>
        <p class="em_text f20">二、我们如何使用Cookie和同类技术</p>
        <br>
        <p class="f18 fb">Cookie</p>
        <p>为确保胸腔负压吸引器平台的正常运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于 Cookie，胸腔负压吸引器平台能够记住您的选择，以便您在不同页面间跳转时仍能记住您的选项，避免重复输入并提供个性化增强服务等。</p>
        <p>您可根据自己的偏好管理或删除Cookie。有关详情请参见 AboutCookies.org。您可以清除计算机或移动设备上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie 的功能。但如果您这么做，则需要在每一次访问胸腔负压吸引器平台时更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。</p>
        <p>我们不会将Cookie用于本政策所述目的之外的用途。但在特定情况下用于本政策未载明的其他用途时，我们将事先征求您的同意。</p>
        <br>
        <p class="f18 fb">网站信标和像素标签</p>
        <p>除Cookie外，我们还会在胸腔负压吸引器平台上使用平台信标和像素标签等其他同类技术。</p>
        <br>
        <br>
        <p class="em_text f20">三、我们如何共享您的个人信息</p>
        <br>
        <p class="f18 fb">共享</p>
        <p>我们不会与胸腔负压吸引器服务提供者以外的任何公司、组织和个人分享您的个人信息，但以下情况除外：</p>
        <ul class="c_gray1 pl20">
          <li><p>☉ 在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。</p></li>
          <li><p>☉ 我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。</p></li>
          <li><p>☉ 与我们的关联公司和子公司共享：您的个人信息可能会与我们的关联公司和子公司共享。我们只会共享必要的个人信息，且受隐私政策中所声明目的的约束。关联公司和子公司如要改变个人信息的处理目的，将再次征求您的授权同意。</p></li>
          <li><p>☉ 与授权合作伙伴共享：<b>仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。</b>我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。</p></li>
          <li><p>☉ 目前，我们的授权合作伙伴包括以下类型：</p></li>
          <li><p>☉ 为了使您能够使用本政策项下的各项服务及功能，我们的应用中会嵌入授权合作伙伴的SDK或其他类似的应用程序，其中可能会涉及传输相关个人信息，具体如下：</p></li>
        </ul>
        <table border="1" class="t1 tc b_gray2 fs70">
          <thead>
          <td width="10%" style="padding:5px;">功能类型</td>
          <td width="15%" style="padding:5px;">SDK名称</td>
          <td width="15%" style="padding:5px;">业务场景</td>
          <td width="30%" style="padding:5px;">个人信息类型</td>
          <td width="20%" style="padding:5px;">个人信息字段</td>
          <td width="10%" style="padding:5px;">数据是否去标识化传输</td>
          </thead>
          <tr class="a1" style="color: #828999;">
            <td style="padding:5px;">定位及地图</td>
            <td style="padding:5px;">百度地图 SDK</td>
            <td style="padding:5px;">定位及导航</td>
            <td style="padding:5px;">个人常用设备信息、个人位置信息</td>
            <td style="padding:5px;">设备信息、定位信息</td>
            <td style="padding:5px;">是</td>
          </tr>
          <tr class="a1" style="color: #828999;">
            <td style="padding:5px;">短信</td>
            <td style="padding:5px;">短信 SDK</td>
            <td style="padding:5px;">推送通知消息</td>
            <td style="padding:5px;">个人常用设备信息</td>
            <td style="padding:5px;">设备信息</td>
            <td style="padding:5px;">是</td>
          </tr>
        </table>
        <br>
        <p>我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照我们的委托目的、服务说明、本隐私权政策以及其他任何相关的保密和安全措施来处理个人信息。</p>
        <br>
        <p class="f18 fb">第三方共享</p>
        <p>在您浏览和使用平台的过程中，您可能会遇到第三方服务商的链接或直接使用第三方服务商提供的服务，这些第三方服务商可能包括社交媒体平台提供商、其他移动应用程序开发商（如微信等）。我们在网站上添加这些内容、链接或插件是为了方便您登录我们的网站，或便于您向在这些第三方服务商的账户共享信息。</p>
        <p>这些第三方服务商通常独立于胸腔负压吸引器服务平台，并且可能有自己的隐私声明或政策。我们强烈建议您在使用第三方服务前查看这些政策或声明，以了解这些第三方如何处理您的个人信息，因为我们不对非由我们自身拥有或管理的网站或应用程序的内容及其使用和保护隐私的措施负责。例如，您可能通过我们平台提供的链接直接跳转到第三方社交媒体的网站，您在后续使用社交媒体网站的行为产生的个人信息将由该第三方而非我们收集，并将受该第三方的隐私政策而非本隐私政策的约束。</p>
        <br>
        <p class="f18 fb">共享时事先征得授权同意的例外</p>
        <p>以下情形中，共享无需事先征得您的授权同意：</p>
        <ul class="c_gray1 pl20">
          <li><p>☉ 与国家安全、国防安全有关的；</p></li>
          <li><p>☉ 与公共安全、公共卫生、重大公共利益有关的；</p></li>
          <li><p>☉ 与犯罪侦查、起诉、审判和判决执行等有关的；</p></li>
          <li><p>☉ 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</p></li>
          <li><p>☉ 您自行向社会公众公开的个人信息；</p></li>
          <li><p>☉ 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p></li>
        </ul>
        <p>根据法律规定，<b>共享经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</b></p>
        <br>
        <br>
        <p class="em_text f20">四、我们如何保护及保存您的个人信息</p>
        <br>
        <p class="f18 fb">我们如何保护您的个人信息：</p>
        <p>我们已采取符合业界标准、合理可行的安全防护措施（包括身份鉴别、数据加密、访问控制等）保护您提供的个人信息安全，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息。</p>
        <p>我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。</p>
        <p>我们会采取合理可行的措施，尽力避免收集无关的个人信息。<b>我们依照法律法规规定，将在运营过程中收集和产生的您的个人信息匿名化处理存储在安全可信的服务器上。</b></p>
        <p>请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</p>
        <p>请知悉，没有任何安全措施是完美或百分之百安全的，因此，我们无法也不保证您的信息不会被违反我们的任何物理、技术或组织保障措施而访问、查看、披露、更改或销毁。在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。</p>
        <br>
        <p class="f18 fb">我们如何保存您的个人信息：</p>
        <p>个人信息的保存期限：除非在收集您的个人信息时另行说明(例如在您填写的表格中)，我们将按照以下期限中较长者保存您的个人信息：（i）为实现本隐私政策中规定的收集或以其他方式处理个人信息的目的所需的期限，或（ii）为履行法律义务（例如税务或其他商事法律项下规定的数据留存义务)所必需的期限。</p>
        <p>我们根据服务需要时长去存储用户信息，超出保存期限的用户信息，我们会做匿名处理。当我们的产品或服务发生停止运营的情形时，我们将以推送通知、公告形式通知您，并在合理的期限内删除您的个人信息或进行匿名化处理。但法律法规有规定不能删除或不能匿名化处理的情况除外。</p>
        <br>
        <p class="f18 fb">我们如何处理未成年人的个人信息：</p>
        <p>我们非常重视保护未成年人个人信息。若您是未成年人，在使用我们的服务前，应提前取得您的父母或法定监护人的书面同意。我们将根据国家相关法律法规保护未成年人的个人信息。</p>
        <p>如果我们发现在未事先获得未成年人的父母或法定监护人同意的情况下收集了未成年人的个人信息，会尽快删除相关数据。</p>
        <br>
        <p class="em_text f20">五、您如何管理您的个人信息</p>
        <p>您可以通过以下方式访问及管理您的个人信息：</p>
        <br>
        <p class="f18 fb">访问您的个人信息</p>
        <p>您有权访问您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的个人信息：</p>
        <p>账户信息：如果您希望访问或编辑您的账户中的个人基本资料信息、更改您的密码等，您可以登录账号通过“个人中心-设置”执行此类操作。</p>
        <p>如果您无法通过上述路径访问该等个人信息，您可以随时通过客服修改。</p>
        <br>
        <p class="f18 fb">删除您的个人信息</p>
        <p>您可以通过本条“访问您的个人信息”中列明的方式删除您的部分个人信息。</p>
        <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
        <ul class="c_gray1 pl20">
          <li><p>☉ 如果我们处理个人信息的行为违反法律法规；</p></li>
          <li><p>☉ 如果我们收集、使用您的个人信息，却未征得您的明确同意；</p></li>
          <li><p>☉ 如果我们处理个人信息的行为严重违反了与您的约定；</p></li>
          <li><p>☉ 如果您不再使用我们的产品或服务，或您主动注销了账号；</p></li>
          <li><p>☉ 如果我们永久不再为您提供产品或服务。</p></li>
        </ul>
        <p>若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，要求其及时删除，除非法律法规另有规定，或这些主体获得您的独立授权。</p>
        <br>
        <p class="f18 fb">注销您的账户</p>
        <p>您可通过客服完成账户的注销。请您谨慎操作，否则，因账户注销产生的数据丢失问题均由您自行承担。</p>
        <p>在符合胸腔负压吸引器单项服务的服务协议约定条件及国家相关法律法规规定的情况下，您的该项胸腔负压吸引器服务账号可能被注销或删除。当账号注销或被删除后，与该账号相关的、该单项服务项下的全部服务资料和数据将依照单项服务的服务协议约定删除或匿名化处理。</p>
        <br>
        <br>
        <p class="em_text f20">六、如何联系我们</p>
        <p>如您对本政策内容有任何疑问、意见或建议，或发现个人信息可能被泄露的，您可以通过以下方式与我们联系，一般情况下我们将在3个工作日内回复您的请求。</p>
        <p>您可以通过“个人中心-设置-意见反馈”,或客服电话（0574-63996801）与我们联系。</p>
        <br>
        <br>
        <p class="em_text f20">七、本隐私政策如何更新</p>
        <p>我们的隐私政策可能变更。未经您的同意，我们不会削减您应享有的个人隐私权利。我们会以通告的形式发布对本政策所做的任何变更。在该种情况下，若您继续使用我们的服务，即表示您同意接受修订后的隐私政策约束。</p>
        <br>
      </div>
    </div>
  </section>
</template>

<script>
export default {
name: "PrivacyProtection"
}
</script>

<style scoped>
.page_faq p{margin: 0 0 10px;line-height: 24px;}
.em_text {
  font-style: normal;
  font-weight:1000;
}
</style>