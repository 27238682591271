/**
 * 全部替换
 * @param str 待替换的字符串
 * @param from 需要替换的关键字
 * @param to 替换后的关键字
 * @returns {*}
 */
function replaceAll(str, from, to) {
    while (str.indexOf(from) >= 0) {
        str = str.replace(from, to);
    }
    return str;
}

module.exports = replaceAll
