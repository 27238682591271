<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr">
      <van-steps class="yuan6 pt5 pb5" direction="vertical" :active="0">
        <van-step v-for="(item, index) in recordList" :key="index">
          <p v-if="2 === item.editState" class="f16 fb" :class="0 === index ? 'c_green' : 'c_black'">
            {{ item.editField }}：“{{ item.oldValue }}”{{ $t("lang.editTo") }}“{{ item.newValue }}”
          </p>
          <p v-if="1 === item.editState" class="f16 fb" :class="0 === index ? 'c_green' : 'c_black'">
            {{ $t("lang.createRecord") }}
          </p>
          <p class="f12 mt5 c_gray1">{{ item.editor }}｜{{ getDateFormat(item.editTime) }}</p>
        </van-step>
      </van-steps>
    </div>
  </section>
</template>

<script>
import {getRecordByPatId} from "@/services/ptEdit";
import constants from "@/constants";
import utils from "@/utils";

export default {
  name: "PatientInfoEditHis",
  data() {
    return {
      recordList: [],
    }
  },
  async mounted() {
    let resultData = await getRecordByPatId(this.$store.state.bindDeviceObj.patientId);
    if (constants.AJAX_OK === resultData.code && resultData.data) {
      this.recordList = resultData.data
    }
  },
  methods: {
    // 获取日期格式
    getDateFormat: function (time) {
      return utils.formatDate(new Date(time), "yyyy/MM/dd hh:mm")
    }
  }
}
</script>

<style scoped>
</style>
