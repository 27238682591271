<template>
  <section class="van-doc-demo-section">
    <!--地图展示区域-->
    <div class="h5005 pr bc_999">
      <baidu-map class="bm-view" :center="center" :zoom="zoom" :dragging="true"
                 @ready="handler" @dragend="dragendMap"
                 :scroll-wheel-zoom="true"
                 @click="clickMap"
                 ak="xZHk6O73z7Ngo2G9PIXPd9OCMaemGZYw">

        <!-- 定位当前位置 -->
        <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"
                        @locationSuccess="getLocationSuccess"></bm-geolocation>
        <!-- 地图容器 -->
        <bm-view class="bm-view"></bm-view>
      </baidu-map>
    </div>
    <div class="bc_fff h6006 overflow-y-auto">
      <van-cell-group>
        <van-cell @click="selectInst(item)" v-for="(item, index) in poiList" :key="index" center clickable
                  :title="item.title"
                  :label="item.address"/>
      </van-cell-group>
    </div>
  </section>
</template>

<script>

import {BaiduMap, BmView, BmGeolocation} from 'vue-baidu-map'
import constants from "@/constants";
import utils from '@/utils';

export default {
  name: "SettingOrganizationName",
  components: {
    BaiduMap,
    BmView,
    BmGeolocation,
  },
  data() {
    return {
      map: null,
      BMap: null,
      marker: null,
      center: {lng: 116.404, lat: 39.915},
      zoom: 15,
      poiList: []
    }
  },
  mounted() {
    let accessType = this.$store.state.accessType
    // APP
    if (constants.AccessType.APP == accessType) {
      utils.jsUseApp("appLocation")
    }
  },
  methods: {
    // 百度地图初始化
    handler({BMap, map}) {
      this.map = map;
      this.BMap = BMap;

      // 设置标注
      this.marker = new BMap.Marker(map.getCenter());
      map.addOverlay(this.marker);
      this.marker.enableDragging();
      this.marker.addEventListener("dragend", () => {
        this.search()
      })

      this.search()

      // 设置一个临时变量指向vue实例，因为在百度地图回调里使用this，指向的不是vue实例；
      let _this = this;
      let geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(function (r) {
        // 设置center属性值
        _this.center = {lng: r.longitude, lat: r.latitude};

        // 移动标注
        _this.marker.setPosition(r.point)

        _this.search()
      });
    },
    // 拖拽地图
    dragendMap() {
      // 移动标注
      this.marker.setPosition(this.map.getCenter())

      this.search()
    },
    // 点击地图
    clickMap(e) {
      // 移动标注
      this.marker.setPosition(e.point)

      this.search()
    },
    // 定位成功回调
    getLocationSuccess(e) {
      // 移动标注
      this.marker.setPosition(e.point)

      this.search()
    },
    // 搜索
    search() {
      if (null == this.marker) {
        return;
      }
      let geocoder = new this.BMap.Geocoder();  //创建地址解析器的实例
      geocoder.getLocation(this.marker.getPosition(), rs => {

        // 保存定位的省市区
        let applyInfo = this.$store.state.applyInfo
        applyInfo.inst.province = rs.addressComponents.province
        applyInfo.inst.city = rs.addressComponents.city
        applyInfo.inst.county = rs.addressComponents.district
        this.$store.commit("setApplyInfo", applyInfo)

        if (utils.isNotNull(rs.surroundingPois)) {
          this.poiList = rs.surroundingPois
        }
      }, {
        // 附近POI所处于的最大半径，默认为100米
        poiRadius: 1000,
        // 返回的POI点个数，默认为10个
        numPois: 20
      });
    },
    // 选择医院
    selectInst(item) {
      let applyInfo = this.$store.state.applyInfo
      applyInfo.inst.instName = item.title
      applyInfo.inst.address = item.address
      applyInfo.inst.latitude = item.point.lat
      applyInfo.inst.longitude = item.point.lng

      this.$store.commit("setApplyInfo", applyInfo)

      this.$router.go(-1)
    }
  },
}
</script>

<style scoped>
.h5005 {
  height: 40vh;
}

.h6006 {
  height: 60vh;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.right0 {
  right: 0px;
}

.bottom10 {
  bottom: 10px;
}

.overflow-y-auto {
  overflow-y: auto;
}

.bm-view {
  width: 100%;
  height: 100%;
}

/deep/ .anchorBL {
  display: none !important;
}
</style>
