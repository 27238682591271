export const lang = {
    // 1 page
    analogTitle: "设备",
    analogTip: "当前展示为模拟数据，请登录后查看真实信息。",
    analogWXLogin: "微信授权一键登录",
    analogPat: "二病区 三床 张**",
    analog1Dev: "1号监护设备",
    analog1Dep: "心胸外科",
    analog1Time: "运行240时36分",
    analog1Inst: "上海**医院",


    // 2 page
    analog1Title: "1#监护设备",
    analog1Start: "开始",
    analog1Editor: "最新编辑者：李*诚",
    analog1Record: "编辑记录",
    analog1Data: "监控数据",
    analog1Update: "实时数据更新于：",
    analog1SetPressure: "设置压力",
    analog1WavePressure: "波动压力",
    analog1GasLeakage: "气体泄漏",
    analog1StartTime: "起始时间",
    analog1TotalTime: "累计时长",
    analog1TotalLiquid: "累计液量",
    analog1SumLiquid: "引流总量",
    analog1WarnRecord: "报警记录",
    analog1GasDecompression: "胸腔气体泄漏",
    analog1TotalLiquid2: "引流液体累计",
    analog1PressureFluctuation: "胸腔压力波动",
    analog1HistoryPat: "设备患者数据",
    analog1DeviceSet: "设备设置",
    analog1CancelAtt: "取消关注",
    analog1TimePressure: "实时压力",
    analog1Pressure: "气源压力",
    analog1Unit: "单位",


    // 3 page
    analog1Info1: "压力无波动报警",
    analog1Info2: "收集容器已满报警",
    analog1Info3: "引流量超限报警",

    // 5 page
    loginTip: "已关联微信账号，请完善以下信息",
    loginName: "姓名/昵称",
    loginMobile: "手机号",
    pleaseInput: "请输入",
    nextStep: "下一步",
    loginAgree: "登录即视为同意",
    loginTerm: "服务条款",
    loginPrivacy: "隐私权政策",
    mobileInputError: "手机号格式错误",
    mameInputError: "姓名长度为1-15个字",
    mobileInUse: "该手机号已使用",
    mobileSmsLogin: "手机验证码登录",
    mobileSmsLoginTip: '登录前需在微信公众号"新跃医疗"-"智能服务"-"胸腔引流"完成手机号激活',
    mobileErrorTip: "该手机号未在公众号中完成激活",


    // 6 page
    inputSms: "输入验证码",
    smsSend: "验证码已发送至",
    reSendSms: "重新获取验证码",
    smsLast: "秒后重新获取验证码",
    smsError: "验证码错误",
    loginFail: "验证码错误",
    sms: "验证码",
    sendSms: "发送验证码",
    smsLast2: "秒后重新获取",
    login: "登录",


    // 7 page
    device: "设备",
    msg: "message",
    userCenter: "个人中心",
    applyAdmin: "申请设备管理员",
    applyAdminTip: "管理员有绑定设备权限，添加成功后可授权其他医生查看监控数据。",
    nowApply: "立即申请",
    inputCode: "录入邀请码获得授权",
    inputCodeTip: "其他医生查看监控数据，需设备管理员授权，授权码由设备管理员发出。",
    nowInput: "立即录入",

    // 8 page
    applyTip: "您正在申请机构设备管理员",
    instName: "机构名称",
    dep: "科室",
    pleaseSet: "请设置",
    submit: "提交",
    depNameError: "科室长度为1-8个字",
    instNameNull: "机构名称不能为空",
    applyFail: "申请失败",
    haveAdmin: "该科室已有设备管理员，请线下联系管理员授权",
    infoSure: "信息确认",
    infoSureTip: "信息提交后不可再更改",

    //page10 16 data empty
    device_empty_nodv: '暂无设备',
    device_empty_noattentdv: '暂无已关注设备',
    device_empty_admintobind: '您是设备管理员，请绑定设备',
    device_empty_nowbind: '现在绑定',
    device_empty_nowadd: '现在添加',

    //page12 add manual
    editManualBind: "手动绑定",
    editDeviceNumError: "设备序列号不能为空",
    editNotFoundDevice: "未找到相关设备",
    editBindSuccess: "绑定成功",
    editBindFail: "绑定失败",
    device_add_devicenum: '设备编号',
    device_add_pleaseinput: '请输入',
    device_add_next: '下一步',
    device_add_scanqrbind: '扫码添加',
    device_add_scanqrnote: '扫描设备二维码，自动识别编码后完成添加',
    device_add_manualbind: '手动录入添加',
    device_add_manualbindnote: '手动输入设备编码完成添加',

    //page13 binding device
    device_binding_alldevicename: '数字胸腔引流系统',
    device_binding_bind: '绑定',
    device_binding_bindnewdv: '绑定新设备',
    device_binding_ward: '病区',
    device_binding_warddesc: '请输入（如：一病区）',
    device_binding_alias: '别名',
    device_binding_aliasdesc: '请输入（限6字符内）',
    device_binding_seqnum: '序列号',
    editAliasNameError: "格式错误，别名长度限6字符内",
    editWardNameError: "病区长度为1-10字",

    // page14 device binded
    device_binded_alldevicename: '数字胸腔引流系统',
    device_binded_added: '该设备已被添加',
    device_binded_seqnum: '序列号',
    device_binded_alias: '别名',
    device_binded_admin: '管理员',
    device_binded_inst: '机构',
    device_binded_dep: '科室',
    device_binded_ward: '病区',

    // page16
    device_att: '已关注设备',

    //page17 select dep group
    device_group_nodevice: '暂无设备',
    device_group_pcdadminbind: '请联系设备管理员绑定设备',
    device_group_onedevice: '台设备',
    device_csel_wandbedno: '病区号+床位号',
    device_csel_sort: '排序',
    device_csel_addattent: '添加关注',
    device_csel_attentnewdv: '关注新设备',
    device_csel_attented: '已关注',
    device_csel_sortwtobed: '优先按病区号排序，床位号其次',
    device_csel_wandruntime: '病区号+设备运行时间',
    device_csel_sortwtotime: '优先按病区号排序，设备运行时间其次',
    device_csel_attentsuccess: '关注成功',
    device_csel_attentfail: '关注失败',
    editSelect: "选择",

    // page19 data home
    device_dh_nopatient: '该设备暂无监控患者',
    device_dh_regnewpati: '请登记新患者信息',
    device_dh_nowreg: '现在登记',
    device_dh_cancelattent: '取消关注',
    device_dh_comcanatt: '确定取消关注该设备?',
    device_dh_cancelsuccess: '取消关注成功',
    device_dh_cancelfail: '取消关注失败',
    day: '天',
    hour: '小时',
    min: '分钟',
    before: '前',
    highValue: '高值',
    lowValue: '低值',
    numValue: '数值',
    oneHourValue: '一小时量',
    oneDayValue: '一天量',
    totalValue: '累计总量',
    newEditor: "最新编辑者：",
    deviceWarn: "报警：{0}，请及时处理。",
    run: "运行",
    hour2: '时',
    min2: '分',

    // page20 device data set
    editDeviceSet: "设备设置",
    device_edit_limitadmin: "限管理员",
    device_edit_modify: "修改",
    device_edit_dname: '设备名',
    device_edit_alldevicename: '数字胸腔引流系统',
    device_edit_seqnum: '序列号',
    device_edit_dbaseinfo: '设备基本信息',
    device_edit_alias: '别名',
    device_edit_ward: '病区',
    device_edit_dep: '科室',
    device_edit_inst: '机构',
    device_edit_cancel: '取消',
    device_edit_save: '保存',
    device_edit_modifyalias: '修改别名',
    device_edit_modifyward: '修改病区',
    device_edit_deletebind: ' 解除绑定',
    device_edit_comftodbind: ' 确认解除绑定该设备',
    device_edit_comftodbinddesc: ' 解除该设备可能影响其他医生监控数据',
    device_edit_updatesuccess: ' 更新成功',
    device_edit_updatefail: ' 更新失败',
    device_edit_dbindsuccess: ' 解除绑定成功',
    device_edit_dbindfail: ' 解除绑定失败',

    //page21 22 23 his patient
    device_his_seltime: ' 选择时间',
    device_his_export: ' 导出',
    editHisPatient: "设备患者数据",
    device_his_pspati: ' 请选择患者',
    device_his_onepati: '位患者',
    device_his_run: '运行',
    device_his_hour: '小时',
    device_his_min: '分钟',
    device_his_bed: '床',
    device_his_mailerrormsg: '邮箱格式错误，请重新输入。',
    device_his_smailsuccess: '监控数据发送邮件成功',
    device_his_smailfail: '监控数据发送邮件失败',
    device_his_getdatafail: '获取患者数据失败',
    device_his_confirmsubmit: '确认提交',
    device_his_cancel: '取消',
    device_his_save: '保存',
    device_his_exportdata: '导出监控数据',
    device_his_sendtoemail: '发送至邮箱',
    device_his_sendemailnote: '输入并确认接收患者监控数据的邮箱地址',
    device_his_input: '请输入',
    device_his_nopati: '该设备无患者数据',

    // 24 page
    patientInfo: "患者信息",
    patientName: "姓名",
    patientBed: "床号",
    patientDep: "科室",
    patientWard: "病区",
    pleaseInputNum: "请输入数字（整数）",
    bedInputError: "床号长度为1-8个字",
    depInputError: "科室长度为1-6个字",
    wardInputError: "病区长度为1-4个字",

    // 25 page
    alarmRecord: "报警记录",
    noAlarmRecord: "暂无报警记录",

    // 26 page
    editRecord: "编辑记录",
    createRecord: "创建记录",
    editTo: "修改为",

    // 27 page
    message: "消息",
    noMessage: "暂无消息",
    noMessageTip: "消息包括设备报警、设备绑定/解绑等",

    // 28 page
    my: "我的",

    // 29 page
    setManageGroup: "设置管理群组",

    // 29 page
    docDetail: "医生详情",

    // 33 page
    inputInviteCode: "请录入邀请码获得授权",
    docInGroup: "该医生已在群组中，请勿重复加入",
    inviteCodeError: "邀请码无效或已过期",

    // MessageSetting page
    msg_wechatSent: "微信推送",
    msg_msgSent: "短信推送",
    msg_tips: "以上推送内容包括设备报警、设备绑定、设备解绑等提醒。",

    // Usercenter_home page
    uc_deptGroup: "设备管理群组",
    uc_modMobile: "修改手机",
    uc_more: 'more',
    uc_more_score: '给我们评分',
    uc_more_set: '消息接收设置',
    uc_more_setOver: '设置成功',
    uc_save: '保存',
    uc_cancel: '取消',
    uc_version: '当前版本',

    // doctorTeam page
    uc_dTeam_alreadyDoc: '已授权医生',
    uc_dTeam_inviteDoc: '邀请医生',
    uc_dTeam_inviteText: '邀请内容',
    uc_dTeam_tips: '管理员，邀请您查看病房“数字胸腔引流系统”设备监控数据。关注公众号"新跃医疗"登录后录入邀请码获得授权。邀请码：',
    uc_dTeam_time: '有效期至',
    uc_dTeam_copy: '复制文本',
    uc_dTeam_copySucc: "邀请码复制成功",

    // doctorDetails page
    uc_docDet_mobile: '手机',
    uc_docDet_backupName: '备用名',
    uc_docDet_setManger: '设为设备管理员',
    uc_docDet_modName: '修改备用名',
    uc_docDet_modSucc: '修改成功',
    uc_docDet_setMgr: '设置该医生为设备管理员',
    uc_docDet_tip_noMger: '设置成功后您将不再是设备管理员，同时该医生现关注的所有设备将被清除。',
    uc_docDet_noAuth: '解除授权',
    uc_docDet_confirm: '确认后该医生将不可查看您管理的设备监控数据',
    uc_docDet_cancel: '取消',
    uc_docDet_save: '保存',
    uc_docDet_saveSucc: '修改成功',

    // doctorTeamOther page
    uc_docOther_getAuth: '录入邀请码获得授权',

    // doctorTeamOtherDetails page
    uc_docOtherDet_doctor: '已授权医生',

    // OldPhoneEnsure page
    uc_oldPhone_check: '原手机号校验',
    uc_oldPhone_tips: '为保障账号安全，修改手机号前需进行身份校验',
    uc_oldPhone_mobile: '手机号',
    uc_oldPhone_code: '验证码',
    uc_oldPhone_input: '请输入',
    uc_oldPhone_sendCode: '发送验证码',
    uc_oldPhone_reGetCode: '秒后重新获取',
    uc_oldPhone_step: '下一步',
    uc_oldPhone_tipService: '如原手机号收不到验证码请拨打客服电话',
    uc_oldPhone_checkFail: '验证码不正确',

    // newPhoneInput page
    uc_newPhone_tip: '新手机号',
    uc_newPhone_input: '请输入',
    uc_newPhone_getCode: '获取验证码',
    uc_newPhone_isExists: '该手机号已被使用',
    uc_newPhone_reInput: '请输入正确的手机号',

    // newPhoneAuthCode page
    uc_phoneAuth_inputSms: "输入验证码",
    uc_phoneAuth_smsSend: "验证码已发送至",
    uc_phoneAuth_reSendSms: "重新获取验证码",
    uc_phoneAuth_smsLast: "秒后重新获取验证码",
    uc_phoneAuth_smsError: "验证码错误",
    uc_phoneAuth_success: "手机修改成功",

    // UsercenterMore page
    uc_more_msgset: '消息接收设置',
    uc_more_fwxy: '服务协议',
    uc_more_ysq: '隐私权政策',
    uc_more_serviceMobile: '客服电话',
    uc_more_serviceTime: '热线服务时间：9:00-17:30（工作日）',
    uc_more_exit: 'quit',
    uc_more_company: '宁波新跃医疗科技股份有限公司',
    uc_more_bq: '版权所有',
    uc_more_comfirmExit: '确认退出',
    uc_more_cancel: 'cancel',
    uc_more_language: 'language',
    uc_more_chooseLanguage: '选择语言',
    uc_more_zh: '简体中文',
    uc_more_en: '英语',

    serviceError: "服务访问异常",
    serviceErrorTip: "请尝试关闭当前页面后重新进入",
}
