<template>
  <section class="van-doc-demo-section">
    <div class="bc_999">
      <van-image :src="require('@/assets/img/usercenter_home_bg.jpg')"/>
    </div>
    <div class="mr10 ml10 mb50 mt-40 dfr">
      <!--头像-->
      <van-image class="w60 h60 bc_999 b2_gray" round :src="doctor.headPortrait"/>

      <!--姓名-->
      <div class="of" @click="showPopup_name">
        <p class="fb f18 left lh32 mb10 ml10 c_green">{{ doctor.nameAlias }}</p>
        <van-button class="left" color="#EDEDED" :icon="require('@/assets/img/list_edit.png')"
                    type="info"
                    size="small" round>
        </van-button>
      </div>

      <!--设备管理群组-->
      <div v-if="0 !== doctor.admin" class="mb5">
        <van-cell @click="toTeam" class="list_radius date_list">
          <van-cell :title="$t('lang.uc_deptGroup')" is-link :icon="require('@/assets/img/device.png')"/>
        </van-cell>
      </div>

      <!--修改手机-->
      <div class="mb5">
        <van-cell class="list_radius date_list" @click="toOldPhoneEnsure">
          <van-cell :title="$t('lang.uc_modMobile')" is-link :icon="require('@/assets/img/user_telephone.png')"/>
        </van-cell>
      </div>

      <!--更多-->
      <div class="mb5">
        <van-cell to="/userCenterMore" class="list_radius date_list">
          <van-cell :title="$t('lang.uc_more')" is-link :icon="require('@/assets/img/user_center_more.png')"/>
        </van-cell>
      </div>

      <!--测试-->
      <!--      <div class="mb5">-->
      <!--        <van-cell @click="scanQR" class="list_radius date_list">-->
      <!--          <van-cell title="测试摄像头" is-link :icon="require('@/assets/img/user_center_more.png')"/>-->
      <!--        </van-cell>-->
      <!--      </div>-->
    </div>

    <!--修改昵称-->
    <van-popup class="h250" v-model="show_name" round position="bottom" close-on-click-overlay>
      <div class="bc_gray">
        <van-divider class="lh50 c_black f18">修改昵称/姓名</van-divider>
      </div>
      <van-cell class="list_radius mt20 mb50">
        <van-field v-model="nameAlias" :placeholder="$t('lang.pleaseInput')" :error-message="nameErrorMsg"/>
      </van-cell>
      <van-row gutter="20" class="m1500">
        <van-col span="8">
          <van-button @click="closePopup_name" block color="#EDEDED">
            <!--取消-->
            <span class="c_green fb"> {{ $t("lang.uc_cancel") }}</span>
          </van-button>
        </van-col>
        <van-col span="16">
          <van-button @click="saveNickName" block color="#42BD56">
            <!--保存-->
            <span class="fb">{{ $t("lang.uc_save") }}</span>
          </van-button>
        </van-col>
      </van-row>
    </van-popup>

  </section>
</template>

<script>
import {getDoctorById, modDoctor} from "@/services/doctor";
import constants from "@/constants";
import utils from "@/utils";
import i18n from "@/lang";

export default {
  async mounted() {
    //管理员
    // let dr = await getDoctorByWx("ot3Di5qKC6qLON33mOsetbRbJgiU", "1386935558427373570");
    //非管理员
    // let dr = await getDoctorByWx("doctor", "1386935558427373571");
    //将医生对象放入到缓存
    // utils.setDoctorCache(this, dr.data);
    //从缓存中获取医生
    // this.doctor = utils.getDoctorCache(this);

    let resultData = await getDoctorById(this.$store.state.doctorWholeObj.doctorId);
    if (constants.AJAX_OK === resultData.code && resultData.data) {
      this.$store.commit("setDoctorWholeObj", resultData.data);
      this.doctor = resultData.data;
    }

  },
  data() {
    return {
      show_phone: false,
      show_name: false,
      nameAlias: '',
      nameErrorMsg: "",
      mobile: '',
      doctor: '',
      accountId: this.$store.state.cacheAccountId,
    };
  },
  methods: {
    //弹出修改姓名框
    showPopup_name() {
      this.nameAlias = this.doctor.nameAlias
      this.show_name = true;
    },
    //关闭修改姓名框
    closePopup_name() {
      this.show_name = false;
    },
    // 保存昵称
    async saveNickName() {
      this.nameErrorMsg = ""
      // 姓名格式判断
      if (utils.isNull(this.nameAlias) || this.nameAlias.length > 15) {
        this.nameErrorMsg = i18n.t("lang.mameInputError");
        return;
      }

      let dr = utils.deepClone(this.doctor);
      dr.nameAlias = this.nameAlias;
      let resultData = await modDoctor(dr);
      if (constants.AJAX_OK === resultData.code && resultData.data) {
        this.$store.commit("setDoctorWholeObj", resultData.data);
        this.doctor = resultData.data
      }

      this.show_name = false;
    },
    //跳转到修改手机号
    toOldPhoneEnsure() {
      this.$router.push("/oldPhoneEnsure");
    },
    toTeam() {
      //1为管理员，其他为医生
      if (this.doctor.admin == constants.DocDeviceAdmin.YES) {
        // this.$router.push("/doctorTeam");
        utils.toForward(this, "/doctorTeam");
      } else {
        // this.$router.push("/doctorTeamOther");
        utils.toForward(this, "/doctorTeamOther");
      }
    },

    //扫一扫测试用
    async scanQR() {
      await utils.scanQR(this.accountId, this.demo);
    },
    demo(re) {
      alert("re:" + re);
    }
  }
}
;
</script>

<style scoped>
.mt-40 {
  margin-top: -40px;
}

.b2_gray {
  border: 2px solid #EDEDED;
}

.date_list .van-cell {
  padding: 10px;
}
</style>
