<template>
  <section class="van-doc-demo-section">
    <div class="m1010 dfr cell_setting">
      <van-row>
        <van-col>
          <van-cell @click="showPopup" :title="myTimeDesc()" is-link arrow-direction="down" />

          <van-popup v-model="pick_show" position="bottom" :overlay="true">
            <!--选择时间-->
            <van-picker show-toolbar :title="$t('lang.device_his_seltime')" :columns="columns" @cancel="onCancel" @confirm="onConfirm" @change="onChange" />
          </van-popup>

        </van-col>
      </van-row>
      <div class="of ml5">
        <p class="left f14 lh24 c_gray1">{{myDeviceDesc()}}</p>
      </div>
    </div>

<!--    <van-cell center is-link title="*病区 *床 新患者" label="心胸内科 ｜ 运行72小时">-->
    <van-cell v-for="(value,key,index) in dv_patients" :key="index"  center is-link :title="myDepDesc(value)" :label="myRunDesc(value)" >
      <template #right-icon>
<!--        <van-checkbox ref="checkboxes" />-->
        <van-checkbox v-model="value.danxuan" ref="checkboxes"  @change="singleChecked(value.dvPatientId)"></van-checkbox>
      </template>
    </van-cell>
    <div class="van-bottom" @click="showExportPopup" v-show="isShow">
      <div class="m00-15">
        <!--导出监控数据-->
        <van-button class="fb mb10" type="primary" block>{{$t('lang.device_his_exportdata')}}</van-button>
      </div>
    </div>

    <!--编辑接收邮箱-->
    <van-popup class="h250" v-model="export_show" round position="bottom" close-on-click-overlay>
      <div class="bc_gray">
        <!--发送至邮箱-->
        <van-divider class="c_black f18 lh40">{{$t('lang.device_his_sendtoemail')}}</van-divider>
        <!--请输入并确认接收患者监控数据的邮箱地址-->
        <p class="f14 tc c_gray1 lh32 mt012">{{$t('lang.device_his_sendemailnote')}}</p>
      </div>
      <van-cell class="list_radius mt20 mb50">
        <!--邮箱格式错误，请重新输入。  请输入-->
        <van-field label="" v-model="mail_num" :error-message="mailErrorMsg" :placeholder="$t('lang.device_his_input')"/>
      </van-cell>
      <van-row gutter="20" class="m1500">
        <van-col span="8">
          <van-button @click="hideExportPopup" block color="#EDEDED">
            <!--取消-->
            <span class="c_green fb">{{$t('lang.device_his_cancel')}}</span>
          </van-button>
        </van-col>
        <van-col span="16">
          <van-button @click="onClick2ExportData" block color="#42BD56">
            <!--保存-->
            <span class="fb">{{$t('lang.device_his_save')}}</span>
          </van-button>
        </van-col>
      </van-row>
    </van-popup>
  </section>
</template>

<script>
  import {getDvHisPatient, getDeviceHisMonth, exportDeviceHisPati} from "@/services/dvPatient";
  import utils from "@/utils";
  import i18n from "@/lang";
  import {Toast} from "vant";
  import constants from "@/constants";

  export default {
    name: "PatientList",

    async mounted() {
      console.log(this.dv_device.deviceId);

      //获取设备监控历史月
      let resultData = await getDeviceHisMonth(this.dv_device.deviceId);
      console.log(resultData);

      this.columns = resultData.data;
      console.log(this.columns);

      if (utils.isNotNull(this.columns))
      {
        this.cur_time = this.columns[0];

        //设备监控患者
        this.toGetHisPatient();
      }
    },

    data() {
      return {
        doctor: this.$store.state.doctorWholeObj,
        dv_device: this.$store.state.bindDeviceObj,
        dv_patients:"",
        pick_show:false, //选择时间弹出 -月份
        export_show:false, //导出数据弹出
        isShow:false, //导出数据按钮
        columns: [], //月份数组
        cur_time:"", //当前月
        selectedData:[], //导出数据选中数据
        mail_num: '', //输入邮箱值
        mailErrorMsg: "", //邮箱错误提示
      };
    },

    methods:{

      //设备监控描述
      myDeviceDesc(){
        if (utils.isNotNull(this.dv_patients))
        {
          console.log(this.dv_patients);
          let runNum = 0;
          for (let i = 0; i < this.dv_patients.length; i++)
          {
            let pati = this.dv_patients[i];
            runNum = runNum + pati.runTime;
          }
          let hour = parseInt(runNum/60);
          let min = runNum%60;

          return this.dv_patients.length + i18n.t("lang.device_his_onepati")+'，'+i18n.t("lang.device_his_run")+hour+i18n.t("lang.device_his_hour")+min+i18n.t("lang.device_his_min");
        }
        else
        {
          return "--"+i18n.t("lang.device_his_onepati")+"，"+i18n.t("lang.device_his_run")+"--"+i18n.t("lang.device_his_hour");
        }
      },

      //患者描述
      myDepDesc(docPatObj){
        return docPatObj.wardName + ' ' + docPatObj.bedNum + i18n.t("lang.device_his_bed")+ ' ' + docPatObj.patName;
      },

      myRunDesc(docPatObj){

          return docPatObj.deptName + ' | ' + this.getDvTime(docPatObj);
      },

      // 获取设备运行时间
      getDvTime: function (docPatObj) {
        let beginTime = docPatObj.beginTime
        let content = utils.formatDate(new Date(beginTime), "yyyy/MM/dd hh:mm") + i18n.t("lang.analog1Start") + ","
                + i18n.t("lang.run");
        let runTime_h = parseInt(docPatObj.runTime / 60)
        let runTime_m = docPatObj.runTime - runTime_h * 60
        if (0 !== runTime_h) {
          content += runTime_h + i18n.t("lang.hour2")
        }
        if (0 !== runTime_m) {
          content += runTime_m + i18n.t("lang.min2")
        }
        return content
      },

      //选择时间
      myTimeDesc(){
        if (utils.isNotNull(this.cur_time))
        {
          return this.cur_time;
        }
        else
        {
          return i18n.t("lang.device_his_seltime"); //选择时间
        }
      },

      //弹出时间选择
      showPopup() {
        this.pick_show = true;
      },

      //隐藏时间选择
      hidePopup() {
        this.pick_show = false;
      },

      //获取设备历史患者
      toGetHisPatient: async function () {
        let dr = await getDvHisPatient(this.dv_device.deviceId, this.cur_time);
        console.log(dr);

        this.dv_patients =dr.data;
        console.log(this.dv_patients);
      },

      onConfirm(value, index) {
        // Toast(`当前值：${value}, 当前索引：${index}`);
        console.log(`当前值：${value}, 当前索引：${index}`);
        this.cur_time = value;
        this.pick_show = false;
        this.toGetHisPatient();
      },
      onChange(picker, value, index) {
        // Toast(`当前值：${value}, 当前索引：${index}`);
        console.log(`当前值：${value}, 当前索引：${index}`);
        this.cur_time = value;
        this.pick_show = false;
        this.toGetHisPatient();
      },
      onCancel() {
        // Toast('取消');
        this.pick_show = false;
      },

      //弹出导出选择
      showExportPopup() {
        this.export_show = true;
      },

      //隐藏导出选择
      hideExportPopup() {
        this.export_show = false;
      },

      //导出
      onClick2ExportData: async function () {

        console.log(this.doctor.doctorId, this.selectedData);

        this.mailErrorMsg = ""
        // 邮箱格式判断
        if (!utils.isEmail(this.mail_num)) {
          this.mailErrorMsg = i18n.t("lang.device_his_mailerrormsg"); //邮箱格式错误，请重新输入。
          return;
        }

        //  导出患者历史数据
        let resultData = await exportDeviceHisPati(this.mail_num, this.selectedData);
        console.log(resultData);
        if (constants.AJAX_OK === resultData.code) {
          Toast({
            message: i18n.t("lang.device_his_smailsuccess"), //监控数据发送邮件成功
            position: 'bottom',
          });
        }
        else
        {
          Toast({
            message: i18n.t("lang.device_his_smailfail"), // 监控数据发送邮件失败
            position: 'bottom',
          });
        }

        this.export_show = false;
      },

      singleChecked:function(dvPatientId){

        let id =  dvPatientId;
        if (this.selectedData.indexOf(id) > -1)
        {
          this.remove(this.selectedData, id);
        }
        else
        {
          this.selectedData.push(id);
        }

        // 判断checked的值是否还等于商品种类数目，
        if (this.checked == this.dv_patients.length)
        {
          this.checkAll = true;
        }else{
          this.checkAll = false;
        }
        console.log(this.selectedData);
      },

      //数组删除
      remove(arr, val) {
        var index = arr.indexOf(val);
        if (index > -1) {
          arr.splice(index, 1);
        }
      },

    },

    watch: {
      selectedData(value) {

        console.log(value);
        if (utils.isNotNull(value))
        {
          this.isShow = true;
        }
        else
        {
          this.isShow = false;
        }
      }
    }

  }
</script>

<style scoped>
.mt012{margin-top: -12px;}
.cell_setting .van-cell{background-color: #EDEDED; padding:10px 5px;font-size: 16px;}
.cell_setting .van-cell:active{background-color: #E3E1E1;}
.p0008{padding: 0px 8px;}
</style>