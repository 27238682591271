import utils from "@/utils";
import url from "./index"

// 查询实时数据
export function getLiveData(patientId, deviceId, prType, dfType, pfType, dContent) {
    return utils.getHttp(url.GET_LIVE_DATA, {
        patientId: patientId,
        deviceId: deviceId,
        prType: prType,
        dfType: dfType,
        pfType: pfType,
        dContent: dContent,
    }, true);
}
