import {getHttp, postHttp, patchHttp, putHttp, deleteHttp} from "./service/index"
import {isNull, isNotNull} from "@/utils/regexp/isNull";
import storage from './storage/index'
import md5 from 'js-md5';
import {scanQR} from "@/utils/weChat/scanQR";

const utils = {
    // 判断两个数组是否相等
    arrayEqual: require("./array/arrayEqual"),


    // 为元素添加class
    addClass: require("./class/addClass"),
    // 判断元素是否有某个class
    hasClass: require("./class/hasClass"),
    // 为元素移除class
    removeClass: require("./class/removeClass"),


    // 根据name读取Cookie
    getCookie: require("./cookie/getCookie"),
    // 根据name删除Cookie
    removeCookie: require("./cookie/removeCookie"),
    // 添加Cookie
    setCookie: require("./cookie/setCookie"),


    // 获取操作系统类型
    getOS: require("./device/getOS"),


    // 获取滚动条距顶部的距离
    getScrollTop: require("./dom/getScrollTop"),
    // 获取一个元素的距离文档(document)的位置，类似jQ中的offset()
    offset: require("./dom/offset"),
    // 在${duration}时间内，滚动条平滑滚动到${to}指定位置
    scrollTo: require("./dom/scrollTo"),
    // 设置滚动条距顶部的距离
    setScrollTop: require("./dom/setScrollTop"),
    // H5软键盘缩回、弹起回调
    windowResize: require("./dom/windowResize"),


    // 函数防抖
    debounce: require("./function/debounce"),
    // 函数节流
    throttle: require("./function/throttle"),


    // 根据keycode获得键名
    getKeyName: require("./keycode/getKeyName"),


    // 深拷贝，支持常见类型
    deepClone: require("./object/deepClone"),
    // 判断Object是否为空
    isEmptyObject: require("./object/isEmptyObject"),


    // 随机生成颜色
    randomColor: require("./random/randomColor"),
    // 生成指定范围随机数
    randomNum: require("./random/randomNum"),


    // 判断是否为16进制颜色，rgb 或 rgba
    isColor: require("./regexp/isColor"),
    // 判断是否为邮箱地址
    isEmail: require("./regexp/isEmail"),
    // 判断是否为身份证号
    isIdCard: require("./regexp/isIdCard"),
    // 判断是否为手机号
    isPhoneNum: require("./regexp/isPhoneNum"),
    // null判断
    isNull,
    // 非null判断
    isNotNull,


    // 现金额转大写
    digitUppercase: require("./string/digitUppercase"),
    // 格式化字符串
    stringFormat: require("./string/stringFormat"),
    // 不足10前面补0
    addZero: require("./string/addZero"),
    // 替换全部
    replaceAll: require("./string/replaceAll"),


    // 判断浏览器是否支持webP格式图片
    isSupportWebP: require("./support/isSupportWebP"),


    // 格式化${startTime}距现在的已过时间
    formatPassTime: require("./time/formatPassTime"),
    // 格式化现在距${endTime}的剩余时间
    formatRemainTime: require("./time/formatRemainTime"),
    // 判断是否为闰年
    isLeapYear: require("./time/isLeapYear"),
    // 判断是否为同一天
    isSameDay: require("./time/isSameDay"),
    // 计算${startTime - endTime}的剩余时间
    timeLeft: require("./time/timeLeft"),
    // 获取指定日期月份的总天数
    monthDays: require("./time/monthDays"),
    // 格式化指定日期
    formatDate: require("./time/formatDate"),


    // url参数转对象
    parseQueryString: require("./url/parseQueryString"),
    // 对象序列化
    stringfyQueryString: require("./url/stringfyQueryString"),

    //从缓存获取医生
    getDoctorCache: require("./common/getDoctorCache"),
    //将医生添加到全局缓存
    setDoctorCache: require("./common/setDoctorCache"),
    //跳转到某个页面
    toForward: require("./common/toForward"),

    //扫描二维码
    scanQR,

    // get请求
    getHttp,
    // post请求
    postHttp,
    // patch请求
    patchHttp,
    // put请求
    putHttp,
    // delete请求
    deleteHttp,

    // 设置缓存
    setStorage: storage.setItem,
    // 获取缓存
    getStorage: storage.getItem,
    // 移除缓存
    removeStorage: storage.removeItem,

    // 16位md5加密
    md5_16: function (value) {
        return md5(value).substr(8, 16).toUpperCase();
    },

    // js 调用app
    jsUseApp: require("./app/jsUseApp")
}

export default utils
