<template>
  <section class="van-doc-demo-section">
    <div class="bb_gray bc_999 tip_sel">
      <div class="p10 of">
        <van-image class="left w14 mt4" :src="require('@/assets/img/sort.png')"/>
        <p class="left f14 lh24 ml5">{{ this.sort_title }}</p><!--病区号+床位号-->
        <van-button class="right" @click="showPopup" color="#CECECE" size="mini" round>
          <span class="p0008 fb c_gray1">{{ $t("lang.device_csel_sort") }}</span><!--排序-->
        </van-button>
      </div>
    </div>
    <div class="h40"></div>
    <div class="m15-00 dfr">
      <van-cell class="list_radius">
        <van-cell @click="toDeviceDetail(value)" v-for="(value,key,index) in doc_devices" :key="index" is-link center>
          <van-image class="pa w44 h44 mt10" :src="require('@/assets/img/electric_negative_pressure_aspirator.png')"/>
          <div class="ml44">
            <p class="fb">{{ value.titleDesc }}</p>
            <div class="fs80 of">
              <p class="left fs90 blue_tag">{{ value.deviceAlias }}</p>
              <p class="left ml5">{{ getDvTime(value) }}</p>
            </div>
            <p class="fs80 span-width c_gray1">{{getInstAndDeptDesc(value) }}</p>
          </div>
          <div v-if="value.redNote === showRedNote" class="right red_pot mt20"></div>
        </van-cell>
      </van-cell>
    </div>
    <van-button @click="onClick2AddDevice" class="van-bottom-btn" icon="plus" round type="primary" color="#F7C636">
      <span class="fb">{{ $t('lang.device_csel_attentnewdv') }}</span>
    </van-button>
    <!--选择排序方式-->
    <van-popup v-model="sort_show" round position="bottom" close-on-click-overlay>
      <van-cell-group>
        <!-- 病区号+床位号    优先按病区号排序，床位号其次-->
        <van-cell center clickable @click="toGetByBed" :title="$t('lang.device_csel_wandbedno')"
                  :icon="require('@/assets/img/patient_bed_number.png')" :label="$t('lang.device_csel_sortwtobed')">
          <template v-if="sort_type === '1'" #right-icon>
            <van-image class="right w14" :src="require('@/assets/img/cell_selected.png')"/>
          </template>
        </van-cell>
        <!--病区号+设备运行时间   优先按病区号排序，设备运行时间其次-->
        <van-cell center clickable @click="toGetByTime" :title="$t('lang.device_csel_wandruntime')"
                  :icon="require('@/assets/img/time.png')" :label="$t('lang.device_csel_sortwtotime')">
          <template v-if="sort_type === '2'" #right-icon>
            <van-image class="right w14" :src="require('@/assets/img/cell_selected.png')"/>
          </template>
        </van-cell>
      </van-cell-group>
    </van-popup>
  </section>
</template>

<script>
import {getDocDeviceList} from "@/services/dvDevice";
import utils from "@/utils";
import i18n from "@/lang";
import constants from "@/constants";

export default {
  mounted() {
    // 获取医生关注设备
    this.sort_type = "1";
    this.sort_title = i18n.t("lang.device_csel_wandbedno");
    this.onClick2GetDocDeviceList();
  },

  data() {
    return {
      doctor: this.$store.state.doctorWholeObj,
      doc_devices: "", //管理员绑定设备
      sort_show: false,
      sort_title: "", //排序标题
      sort_type: "1", //排序类型 1按床号  2按时间
      value: '',
      showRedNote: constants.RedNote.YES
    };
  },
  methods: {
    showPopup() {
      this.sort_show = true;
    },

    // 获取设备运行时间
    getDvTime: function (docPatObj) {
      //新设备无患者 beginTime为空
      if (!docPatObj.beginTime)
      {
        return docPatObj.deptName;
      }

      let content =  i18n.t("lang.run");
      let runTime_h = parseInt(docPatObj.runTime / 60)
      let runTime_m = docPatObj.runTime - runTime_h * 60
      if (0 !== runTime_h) {
        content += runTime_h + i18n.t("lang.hour2")
      }
      if (0 !== runTime_m) {
        content += runTime_m + i18n.t("lang.min2")
      }
      return content
    },

    // 设备的 科室 | 机构属性
    getInstAndDeptDesc: function (docPatObj) {

      if (docPatObj.patientId) {
        return docPatObj.patDeptName  + " | " + docPatObj.patInstName;
      }
      else{
        return docPatObj.deptName  + " | " + docPatObj.instName;
      }
    },

    //关注新设备
    onClick2AddDevice() {
      this.$router.push("/addClassSel");
    },

    // 获取设备，按床号排序
    toGetByBed() {
      this.sort_show = false;
      this.sort_type = "1";
      this.sort_title = i18n.t("lang.device_csel_wandbedno");

      this.onClick2GetDocDeviceList();
    },

    // 取设备，按监控时间排序
    toGetByTime() {
      this.sort_show = false;
      this.sort_type = "2";
      this.sort_title = i18n.t("lang.device_csel_wandruntime");
      this.onClick2GetDocDeviceList();
    },


    //获取医生关注设备
    onClick2GetDocDeviceList: async function () {

      let dr = await getDocDeviceList(this.doctor.doctorId, this.doctor.doctorId, "2", this.sort_type);
      this.doc_devices = dr.data;
    },

    //设备详情
    toDeviceDetail(docDevice) {
      if (utils.isNull(docDevice.patientId)) {
        this.$store.commit("setBindDeviceObj", docDevice);
        this.$router.push("/deviceDataHomeEmpty");
      } else {
        this.$store.commit("setBindDeviceObj", docDevice);
        this.$router.push("/deviceDataHome");
      }
    },
  },
}
</script>

<style scoped>
.p0008 {
  padding: 0px 8px;
}

.pot_position {
  right: 0px;
  z-index: 10;
  top: 32px;
}


</style>
