<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr">
      <van-cell class="list_radius">
        <div class="van_bg_zdy1">
          <van-cell>
            <van-image class="left w40" :src="require('@/assets/img/call_the_police_big.png')"/>
            <div class="left">
              <p class="fb f16">{{ $t("lang.analog1Info1") }}</p>
              <div class="of">
                <van-image class="left w14 h14 mt5" :src="require('@/assets/img/alarm_start.png')"/>
                <p class="left ml5 fs90 c_gray1">2021/5/2 11:33</p>
                <van-image class="left w14 h14 mt5 ml10" :src="require('@/assets/img/alarm_end.png')"/>
                <p class="left ml5 fs90 c_gray1">2021/5/2 11:36</p>
              </div>
            </div>
          </van-cell>
          <van-cell>
            <van-image class="left w40" :src="require('@/assets/img/call_the_police_big.png')"/>
            <div class="left">
              <p class="fb f16">{{ $t("lang.analog1Info2") }}</p>
              <div class="of">
                <van-image class="left w14 h14 mt5" :src="require('@/assets/img/alarm_start.png')"/>
                <p class="left ml5 fs90 c_gray1">2021/5/1 20:15</p>
                <van-image class="left w14 h14 mt5 ml10" :src="require('@/assets/img/alarm_end.png')"/>
                <p class="left ml5 fs90 c_gray1">2021/5/1 20:18</p>
              </div>
            </div>
          </van-cell>
          <van-cell>
            <van-image class="left w40" :src="require('@/assets/img/call_the_police_big.png')"/>
            <div class="left">
              <p class="fb f16">{{ $t("lang.analog1Info3") }}</p>
              <div class="of">
                <van-image class="left w14 h14 mt5" :src="require('@/assets/img/alarm_start.png')"/>
                <p class="left ml5 fs90 c_gray1">2021/4/29 18:05</p>
                <van-image class="left w14 h14 mt5 ml10" :src="require('@/assets/img/alarm_end.png')"/>
                <p class="left ml5 fs90 c_gray1">2021/4/29 18:07</p>
              </div>
            </div>
          </van-cell>
        </div>
      </van-cell>
    </div>
  </section>
</template>

<script>
export default {
  name: "AlarmRecord"
}
</script>

<style scoped>
.van_bg_zdy1 .van-cell {
  padding: 10px;
}
</style>
