<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr">
      <van-cell class="list_radius">
        <van-cell>
          <van-image class="left mt10 w30 h30" :src="doctor.headPortrait"/>
          <div class="left ml10">
            <div class="of">
              <p class="fb left">{{ doctor.nameAlias }}</p>
              <van-image class="left mt4 ml5 h15 w42" :src="require('@/assets/img/administration_tag.png')"/>
            </div>
            <div class="of">
              <van-image class="left w14 h17 mt4" :src="require('@/assets/img/doctor_phone.png')"/>
              <p class="left ml5 fs90 c_gray1">{{ doctor.mobile }}</p>
            </div>
          </div>
        </van-cell>
      </van-cell>
      <!--已授权医生-->
      <p class="f14 c_gray1 mt15 mb5 ml15" v-if="showDoctors">{{ $t("lang.uc_docOtherDet_doctor") }}</p>
      <van-cell class="list_radius" v-for="(value,key,index) in doctors" :key="index">
        <van-cell center>
          <van-image class="left mt10 w30 h30" :src="value.headPortrait"/>
          <div class="left ml10">
            <p class="fb">{{ value.nameAlias }}</p>
            <div class="of">
              <van-image class="left w14 h17 mt4" :src="require('@/assets/img/doctor_phone.png')"/>
              <p class="left ml5 fs90 c_gray1">{{ value.mobile }}</p>
            </div>
          </div>
        </van-cell>
      </van-cell>
    </div>
  </section>
</template>

<script>
import {getDoctorById, getGroupDoctor, getInviteDoctor} from "@/services/doctor";
import constants from "@/constants";
import {isNotNull} from "@/utils/regexp/isNull";

export default {
  async mounted() {
    this.doctor = this.$route.params.doctor;
    this.deptGroup = this.$route.params.deptGroup;

    document.title = this.doctor.deptName;

    let resultData = await getDoctorById(this.deptGroup.adminId);
    if (constants.AJAX_OK === resultData.code && resultData.data) {
      this.doctor = resultData.data;
    }
    resultData = await getGroupDoctor(this.doctor.instId, this.deptGroup.deptName);
    if (constants.AJAX_OK === resultData.code && resultData.data) {
      this.doctors = resultData.data;
    }

    //获取已授权的医生
    if (isNotNull(this.doctor.doctorId)) {
      let drs = await getInviteDoctor(this.doctor.doctorId);
      this.doctors = drs.data;
    }
    if (isNotNull(this.doctors)) {
      this.showDoctors = true;
    }
    console.log("doctorTeam doctors:" + this.doctors);
  },
  data() {
    return {
      doctor: '',
      deptGroup: '',
      doctors: '',
      showDoctors: false,
    }
  }
}
</script>

<style scoped>
.h17 {
  height: 17px;
}

.w42 {
  width: 42px;
}

.h15 {
  height: 15px;
}
</style>
