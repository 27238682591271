import utils from "@/utils";
import url from "./index"

// 据患者ID查询患者
export function getPatById(patientId) {
    return utils.getHttp(url.GET_PATIENT_PATH + patientId);
}

// 更新患者信息
export function updatePat(patObj) {
    return utils.putHttp(url.GET_PATIENT, patObj)
}
