class StorageService {
    constructor() {
        if (!localStorage) {
            throw new Error('Current brower does not support Local Storage');
        }
    }

    setItem(key, value) {
        return localStorage.setItem(key, JSON.stringify(value));
    }

    getItem(key) {
        return JSON.parse(localStorage.getItem(key));
    }

    removeItem(key) {
        return localStorage.removeItem(key);
    }
}
export default new StorageService()

