<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr">
      <!--管理员-->
      <van-cell class="list_radius">
        <van-cell>
          <van-image class="left mt10 w30 h30" :src="doctor.headPortrait"/>
          <div class="left ml10">
            <!--姓名-->
            <div class="of">
              <p class="fb left">{{ doctor.nameAlias }}</p>
              <van-image class="left mt4 ml5 h15 w42" :src="require('@/assets/img/administration_tag.png')"/>
            </div>
            <!--手机-->
            <div class="of">
              <van-image class="left w14 h17 mt4" :src="require('@/assets/img/doctor_phone.png')"/>
              <p class="left ml5 fs90 c_gray1">{{ doctor.mobile }}</p>
            </div>
          </div>
        </van-cell>
      </van-cell>

      <!--已授权医生-->
      <p class="f14 c_gray1 mt15 mb5 ml15" v-if="showDoctors">{{ $t("lang.uc_dTeam_alreadyDoc") }}</p>
      <van-cell class="list_radius">
        <van-cell @click="toDorDetail(doctor,value)" v-for="(value,key,index) in doctors" :key="index" is-link center>
          <!--头像-->
          <van-image class="left mt10 w30 h30" :src="value.headPortrait"/>
          <div class="left ml10">
            <!--昵称-->
            <p class="fb">{{ "" === value.aliasSet ? value.nameAlias : value.aliasSet }}</p>
            <div class="of">
              <van-image class="left w14 h17 mt4" :src="require('@/assets/img/doctor_phone.png')"/>
              <!--手机-->
              <p class="left ml5 fs90 c_gray1">{{ value.mobile }}</p>
            </div>
          </div>
        </van-cell>
      </van-cell>

      <!--邀请医生-->
      <div class="van-bottom">
        <div class="m00-15">
          <van-button @click="showPopup" class="fb mb10" type="primary" block>{{ $t("lang.uc_dTeam_inviteDoc") }}
          </van-button>
        </div>
      </div>
    </div>
    <!--邀请文本详情-->
    <van-popup class="h250" v-model="show" round position="bottom" close-on-click-overlay>
      <div class="bc_gray">
        <van-divider class="lh50 c_black f18">{{ $t("lang.uc_dTeam_inviteText") }}</van-divider>
      </div>
      <div class="p10">
        <!--邀请内容详情-->
        <p class="c_black f14 lh20">
          {{ inst.instName }}{{ doctor.deptName }}{{ $t("lang.uc_dTeam_tips") }}{{
            doctor.inviteCode
          }}（{{ $t("lang.uc_dTeam_time") }}
          {{ expiryDateFmt }}）。</p>
        <van-button class="mt20" block color="#E3E3E3"
                    v-clipboard:copy="inst.instName + doctor.deptName + $t('lang.uc_dTeam_tips') + doctor.inviteCode
        +'('+ $t('lang.uc_dTeam_time') + expiryDateFmt+'）。'"
                    v-clipboard:success="onCopy">
          <!--复制文本-->
          <span class="fb c_green">{{ $t("lang.uc_dTeam_copy") }}</span>
        </van-button>
      </div>
    </van-popup>
  </section>
</template>

<script>

import {getInviteCode, getInviteDoctor} from "@/services/doctor";
import constants from "@/constants";
import formatDate from "@/utils/time/formatDate";
import utils from "@/utils";
import {Toast} from "vant";
import i18n from "@/lang";
import {getInstById} from "@/services/inst";
import {isNotNull} from "@/utils/regexp/isNull";

export default {
  async mounted() {
    this.doctor = this.$store.state.doctorWholeObj;
    console.log("doctorTeam doctor:" + this.doctor);

    //获取已授权的医生
    if (isNotNull(this.doctor.doctorId)) {
      let drs = await getInviteDoctor(this.doctor.doctorId);
      this.doctors = drs.data;
    }
    if (isNotNull(this.doctors)) {
      this.showDoctors = true;
    }
    console.log("doctorTeam doctors:" + this.doctors);

    //获取医生所在的机构
    let resultData = await getInstById(this.doctor.instId);
    if (constants.AJAX_OK === resultData.code && resultData.data) {
      this.inst = resultData.data;
    }
    console.log("doctorTeam inst:" + this.inst);

  },
  data() {
    return {
      show: false,
      doctor: this.$store.state.doctorWholeObj,
      doctors: '',
      expiryDateFmt: '',
      inst: '',
      showDoctors: false,
    };
  },
  methods: {
    //邀请内容
    async showPopup() {
      //如果没获取过邀请码，并且邀请码时间没超过7天，则不再请求
      if ((this.doctor.expiryDate == null) || (new Date() > new Date(this.doctor.expiryDate))) {
        let resultData = await getInviteCode(this.doctor.doctorId);

        if (constants.AJAX_OK === resultData.code && resultData.data) {
          this.$store.commit("setDoctorWholeObj", resultData.data);
          this.doctor = resultData.data;
        }
      }

      //如果验证码有效期有值，则给前台页面赋值
      if (this.doctor.expiryDate != null) {
        this.expiryDateFmt = formatDate(new Date(this.doctor.expiryDate), 'yyyy/MM/dd');
      }
      this.show = true;
    },
    // 复制到粘贴板
    onCopy: function (e) {
      console.log(e.text);
      Toast(i18n.t("lang.uc_dTeam_copySucc"));
    },
    toDorDetail(mgr, doctor) {
      utils.toForward(this, 'doctorDetails', {mgr: mgr, doctor: doctor});
    }
  }
}
</script>

<style scoped>
.h17 {
  height: 17px;
}

.w42 {
  width: 42px;
}

.h15 {
  height: 15px;
}

</style>
