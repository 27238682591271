<template>
  <section class="van-doc-demo-section">
    <div class="m15 dfr ta">
      <div class="page_faq f14 mt15">
        <p class="em_text">【重要须知】 您点击同意/激活程序或使用新跃胸腔负压吸引器相关服务均视为您已阅读、理解并同意签署本协议。如果您未满18周岁，请在法定监护人的陪同下阅读、理解和确认签署本协议。</p>
        <p>本协议由您与新跃胸腔负压吸引器平台的经营者共同签订，本协议具有合同效力。</p>
        <p>新跃胸腔负压吸引器平台的经营者是指法律和行业认可的经营该应用的责任主体，新跃胸腔负压吸引器平台经营者为宁波新跃医疗科技股份有限公司。新跃胸腔负压吸引器平台指由新跃胸腔负压吸引器运营的新跃胸腔负压吸引器应用，应用名为“新跃胸腔负压吸引器”。</p>
        <br>
        <p class="sectionTitle fs120">一、【协议内容及签署】</p>
        <p><b>1.1</b>	本协议内容包括协议正文及所有新跃胸腔负压吸引器经营者已经发布的或将来可能发布的各类规则。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。</p>
        <p><b>1.2</b>	<b>您应当在使用新跃胸腔负压吸引器服务之前认真阅读全部协议内容。</b>如您对协议有任何疑问，应向新跃胸腔负压吸引器经营者咨询。但无论您事实上是否在使用新跃胸腔负压吸引器服务之前认真阅读了本协议内容，只要您注册/激活程序或使用新跃胸腔负压吸引器服务，则本协议即对您产生约束，届时您不应以未阅读本协议内容或者未获得新跃胸腔负压吸引器经营者对您问询的解答等任何理由，主张本协议或本协议任何部分无效，或要求撤销本协议或本协议的任何部分。</p>
        <p><b>1.3</b>	您承诺接受并遵守本协议的规定。如果您不同意本协议的约定，您应立即停止注册/激活程序并停止使用新跃胸腔负压吸引器服务。</p>
        <p><b>1.4</b>	新跃胸腔负压吸引器经营者有权根据需要不时地制订、修改本协议及/或各类规则，并以网站公示的方式进行公告，不再单独通知您。变更后的协议和规则一经在网站公布后，立即自动生效。如您不同意相关变更，应当立即停止使用新跃胸腔负压吸引器服务。您继续使用新跃胸腔负压吸引器服务，即表示您接受经修订的协议和规则。</p>
        <br>
        <p class="sectionTitle fs120">二、【注册与账户】</p>
        <p><b>2.1</b> 【注册者资格】</p>
        <p>您确认，在您完成注册程序或以其他新跃胸腔负压吸引器经营者允许的方式实际使用新跃胸腔负压吸引器服务时，您应当是具备完全民事权利能力和完全民事行为能力的自然人，当您代理其他自然人完成注册时，您应是其法定代理人，或已征得其法定代理人的同意。若您不具备前述主体资格，则您及您的监护人应承担因此而导致的一切后果，且新跃胸腔负压吸引器经营者有权注销或永久冻结您的账户，并向您及您的监护人索偿。</p>
        <p><b>2.2</b> 【注册和账户】</p>
        <p class="t2"><b>2.2.1</b> 在您按照页面的注册流程提示填写信息、阅读并同意本协议且完成全部注册和激活程序后，或您以其他新跃胸腔负压吸引器经营者允许的方式实际使用新跃胸腔负压吸引器服务时，您即接受本协议约束。您可以使用您提供和确认的手机号码或者新跃胸腔负压吸引器经营者允许的其它方式作为登录手段进入新跃胸腔负压吸引器平台。</p>
        <p class="t2"><b>2.2.2</b> 您可以对新跃胸腔负压吸引器账户设置昵称，您设置的昵称不得侵犯或涉嫌侵犯他人合法权益。否则，新跃胸腔负压吸引器经营者有权终止向您提供新跃胸腔负压吸引器服务，注销您的账户昵称。</p>
        <p><b>2.3</b> 【用户信息】</p>
        <p class="t2"><b>2.3.1</b> 在完成注册和激活流程后，为获得来自官方医疗卫生服务机构的新跃胸腔负压吸引器服务，您应按相应页面提示准确提供并及时更新您的个人资料，确保之真实和完整。如有合理理由怀疑您提供的资料错误、虚假、过时或不完整，新跃胸腔负压吸引器经营者有权向您发出询问及/或要求改正的通知，并有权直接作出删除相应资料的处理，直至中止、终止对您提供部分或全部新跃胸腔负压吸引器平台服务。新跃胸腔负压吸引器经营者对此不承担任何责任，您将承担因此产生的任何直接或间接损失及不利后果。</p>
        <p class="t2"><b>2.3.2</b> 您应当准确填写并及时更新您提供的手机号码，以便新跃胸腔负压吸引器经营者与您进行有效联系，因通过该联系方式无法与您取得联系，导致您在获得新跃胸腔负压吸引器服务过程中产生任何损失或增加费用的，应由您完全独自承担。</p>
        <p><b>2.4</b> 【账户安全】</p>
        <p>您须自行负责对您的新跃胸腔负压吸引器账号和密码保密，且须对您在该账号下发生的所有活动（包括但不限于信息提交、报告浏览、同意各类规则协议、使用服务等）承担责任。您同意：a）如发现任何人未经授权使用您的新跃胸腔负压吸引器账号，或发生违反保密规定的任何其他情况，您会立即通知新跃胸腔负压吸引器经营者，并授权新跃胸腔负压吸引器经营者采取相应的账号冻结措施；b）确保您在每个上网时段结束时，以正确步骤离开网站/服务。新跃胸腔负压吸引器经营者不能也不会对因您未能遵守本款规定而发生的任何损失负责。您理解新跃胸腔负压吸引器经营者对您的请求采取行动需要合理时间，新跃胸腔负压吸引器经营者对在采取行动前已经产生的后果（包括但不限于您的任何损失）不承担任何责任。</p>
        <br>
        <p class="sectionTitle fs120">三、【新跃胸腔负压吸引器服务】</p>
        <p><b>3.1</b> 通过新跃胸腔负压吸引器经营者提供的新跃胸腔负压吸引器平台，用户可使用新跃胸腔负压吸引器的服务，在您使用该项服务时，意味您已接受相关的服务协议。</p>
        <p><b>3.2</b> 您了解并同意，新跃胸腔负压吸引器经营者有权应政府部门（包括司法、医疗卫生及相关行政部门）的要求，向其提供您在新跃胸腔负压吸引器中的必要信息。</p>
        <br>
        <p class="sectionTitle fs120">四、【特别授权】</p>
        <p>您完全理解并不可撤销地授权新跃胸腔负压吸引器经营者或您与新跃胸腔负压吸引器经营者一致同意的第三方，存储和处理您获得相关医疗、卫生、健康服务所必须的个人信息（包括但不限于健康信息）。</p>
        <br>
        <p class="sectionTitle fs120">五、【责任范围和责任限制】</p>
        <p><b>5.1</b> 新跃胸腔负压吸引器经营者负责按"现状"和"可得到"的状态向您提供新跃胸腔负压吸引器平台服务，但新跃胸腔负压吸引器经营者对新跃胸腔负压吸引器平台服务不作任何明示或暗示的保证，包括但不限于新跃胸腔负压吸引器平台服务的适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途。同时，新跃胸腔负压吸引器经营者也不对新跃胸腔负压吸引器平台服务所涉及的技术及信息的有效性、准确性、正确性、可靠性、质量、稳定、完整和及时性作出任何承诺和保证。</p>
        <p><b>5.2</b> 提供的数据，仅供参考，不对其科学性、正确性和完整性做任何形式的保证，不作为个人诊断、用药或治疗的依据，不能代替医务工作者的诊断或治疗建议，用户若因为参考信息造成实际偏差，新跃胸腔负压吸引器经营者不承担任何责任。</p>
        <p><b>5.3</b> 您了解并同意，新跃胸腔负压吸引器经营者不对因下述任一情况而导致您的损害承担任何直接、间接、附带、特别、衍生性或惩罚性赔偿责任，包括但不限于健康、利益、信誉、数据、金钱等方面的损失或其它无形损失的损害赔偿：</p>
        <p class="t2"><b>5.3.1</b> 平台根据法律规定或相关政府的要求提供您的个人信息。</p>
        <p class="t2"><b>5.3.2</b> 使用或未能使用新跃胸腔负压吸引器平台服务。</p>
        <p class="t2"><b>5.3.3</b> 由于您将个人信息告知他人或与他人共享，由此导致的任何个人信息的泄漏，或其他非因新跃胸腔负压吸引器平台原因导致的个人信息的泄漏。</p>
        <p class="t2"><b>5.3.4</b> 您对新跃胸腔负压吸引器平台服务的误解。</p>
        <p class="t2"><b>5.3.5</b> 任何非因新跃胸腔负压吸引器经营者的原因而引起的与新跃胸腔负压吸引器平台服务有关的其它损失。</p>
        <p><b>5.4</b> 不论在何种情况下，新跃胸腔负压吸引器经营者均不对由于信息网络正常的设备维护，信息网络连接故障，电脑、通讯或其他系统的故障，电力故障，罢工，劳动争议，暴乱，起义，骚乱，生产力或生产资料不足，火灾，洪水，风暴，爆炸，战争，政府行为，司法行政机关的命令或第三方的不作为而造成的不能服务或延迟服务承担责任。</p>
        <br>
        <p class="sectionTitle fs120">六、【协议终止】</p>
        <p><b>6.1</b> 您同意，新跃胸腔负压吸引器经营者有权自行全权决定以任何理由不经事先通知的中止、终止向您提供部分或全部新跃胸腔负压吸引器平台服务，暂时冻结或永久冻结（注销）您的账户在新跃胸腔负压吸引器平台的权限，且无须为此向您或任何第三方承担任何责任。</p>
        <p><b>6.2</b> 出现以下情况时，新跃胸腔负压吸引器经营者有权直接以注销账户的方式终止本协议，并有权永久冻结（注销）您的账户在新跃胸腔负压吸引器平台的权限：</p>
        <p class="t2"><b>6.2.1</b> 本协议（含规则）变更时，您明示并通知新跃胸腔负压吸引器经营者不愿接受新的服务协议的；</p>
        <p class="t2"><b>6.2.2</b> 其它新跃胸腔负压吸引器经营者认为应当终止服务的情况。</p>
        <p><b>6.3</b> 您的账户服务被终止或者账户在新跃胸腔负压吸引器平台的权限被永久冻结（注销）后，新跃胸腔负压吸引器经营者没有义务为您保留或向您披露您账户中的任何信息。</p>
        <br>
        <p class="sectionTitle fs120">七、【隐私权政策】</p>
        <p><b>7.1</b> 新跃胸腔负压吸引器经营者尊重并保护所有使用平台服务用户的个人隐私权。为了给用户提供更准确、更有个性化的服务，平台会按照本隐私权政策的规定使用和披露用户的相关信息。但新跃胸腔负压吸引器经营者将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，新跃胸腔负压吸引器经营者不会将这些信息对外披露或向第三方提供。新跃胸腔负压吸引器经营者会不时更新本隐私权政策。您在同意平台服务协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于法律声明中不可分割的一部分。</p>
        <p><b>7.2</b> 信息使用</p>
        <p class="t2"><b>7.2.1</b> 新跃胸腔负压吸引器经营者不会向任何无关第三方提供、出售、出租、分享或交易您的个人注册信息，除非事先得到您的许可。</p>
        <p class="t2"><b>7.2.2</b> 新跃胸腔负压吸引器经营者亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息和企业名称及相关注册信息。任何新跃胸腔负压吸引器平台用户如从事上述活动，一经发现，平台有权立即终止与该用户的服务协议。</p>
        <p class="t2"><b>7.2.3</b> 用户名和昵称的注册与使用应符合网络道德，遵守中华人民共和国的相关法律法规。</p>
        <p class="t2"><b>7.2.4</b> 用户名和昵称中不能含有非法和侵害他人权益的文字。</p>
        <p class="t2"><b>7.2.6</b> 注册成功后，用户必须保护好自己的帐号和密码，因用户泄露而造成的任何损失由本人负责。</p>
        <p class="t2"><b>7.2.7</b> 不得盗用他人帐号，不得未经授权注册他人信息，由此行为造成的后果自负。</p>
        <p><b>7.3</b> 信息披露</p>
        <p>在如下情况下，新跃胸腔负压吸引器经营者将依据您的个人意愿或法律的规定全部或部分的披露您的个人注册信息：</p>
        <p class="t2"><b>7.3.1</b> 根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露。</p>
        <p class="t2"><b>7.3.2</b> 如您出现违反中国有关法律、法规或者平台服务协议或相关规则的情况，需要向第三方披露。</p>
        <p class="t2"><b>7.3.3</b> 其它新跃胸腔负压吸引器经营者根据法律、法规或者平台政策认为合适的披露。</p>
        <p><b>7.4</b> 个人信息安全</p>
        <p>我们使用各种安全技术和程序来帮助保护您的个人信息免于未经授权的访问，使用和披露。</p>
        <p><b>7.5</b> 本隐私权政策的修改</p>
        <p>我们会不定时对本政策作出修改，我们鼓励您定期查看本政策，以便随时了解我们如何保护我们收集的个人信息，您继续使用本服务即表示您同意本隐私政策和任何更新。</p>
        <p><b>7.6</b> 本隐私权政策的执行</p>
        <p> 如果您对本声明有任何疑问，您应该先与我们联系。以上内容最终解释权由宁波新跃医疗科技股份有限公司所有。</p>
        <br>
        <p class="sectionTitle fs120">八、【知识产权声明】</p>
        <p><b>8.1</b> 新跃胸腔负压吸引器经营者拥有新跃胸腔负压吸引器平台所有信息内容（包括但不限于服务流程、产品UI、视觉设计、文字、图片、图表、音频、视频、版面框架、有关数据或电子文档等）以及非机构直接提供的原始信息的知识产权权利人。新跃胸腔负压吸引器经营者享有与此相关的著作权、商标权、专利权、商业秘密等知识产权及其他合法权益，但相关权利人依照法律规定应享有的权利除外。未经新跃胸腔负压吸引器经营者事先书面同意，任何机构和个人无权以任何方式使用新跃胸腔负压吸引器经营者的任何知识产权。</p>
        <p><b>8.2</b> 任何被授权的浏览、复制、打印和传播属于该软件内信息内容都不得用于商业目的，且所有信息内容及其任何部分的使用都必须包括此知识产权声明；</p>
        <p><b>8.3</b> 本公司保留对本声明作出不定时修改的权利。</p>
        <br>
        <p class="sectionTitle fs120">九、【其他】</p>
        <p><b>9.1</b> 本协议项下新跃胸腔负压吸引器经营者对于您的所有的通知均可通过网页公告、专题页、站内消息、客户端推送、公众号通知、您预留的电子邮件、手机短信或常规的信件传送等方式进行，该等通知于发送之日视为已送达于您。为便于您收取相关通知，您应确保预留的联系方式为正确及有效，并在变更后及时登录账户修改，否则将由您承担由此产生的损失及法律后果。</p>
        <p><b>9.2</b> 本协议签订地位中华人民共和国浙江省宁波市慈溪市。</p>
        <p><b>9.3</b> 本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国大陆地区法律，以及医疗卫生行业相关规定。</p>
        <p><b>9.4</b> 若您和新跃胸腔负压吸引器经营者之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。</p>
        <p><b>9.5</b> 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方均有约束力。</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
name: "ServiceAgreement"
}
</script>

<style scoped>
.page_faq p{margin: 0 0 10px;line-height: 24px;}
.em_text {
  font-style: normal;
  font-weight:1000;
}
</style>