<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr">
      <div>
        <van-cell class="list_radius">
          <!--设备名    电动负压吸引器-->
          <van-cell :title="$t('lang.device_edit_dname')" :icon="require('@/assets/img/device_full_name.png')"
                    :value="$t('lang.device_edit_alldevicename')"/>
          <!--序列号-->
          <van-cell :title="$t('lang.device_edit_seqnum')" :icon="require('@/assets/img/patient_bed_number.png')"
                    :value="dv_device.deviceNum"/>
        </van-cell>
      </div>
      <div class="mt5">
        <van-cell class="list_radius">
          <!--设备基本信息-->
          <van-cell center :title="$t('lang.device_edit_dbaseinfo')">
            <template #label>
              <!-- 限管理员“刘桥巧”修改-->
              <span class="f12 c_green1">{{ toAdminNameDesc() }}</span>
            </template>
          </van-cell>
          <!--别名-->
          <van-cell :title="$t('lang.device_edit_alias')" is-link @click="reviseName"
                    :icon="require('@/assets/img/patient_name.png')"
                    :value="dv_device.deviceAlias"/>
          <!--病区-->
          <van-cell :title="$t('lang.device_edit_ward')" is-link @click="reviseWard"
                    :icon="require('@/assets/img/patient_inpatient_area.png')"
                    :value="dv_device.wardName"/>
          <!--科室-->
          <van-cell :title="$t('lang.device_edit_dep')" :icon="require('@/assets/img/patient_department.png')"
                    :value="dv_device.deptName"/>
          <!--机构-->
          <van-cell :title="$t('lang.device_edit_inst')" :icon="require('@/assets/img/patient_hospital.png')"
                    :value="dv_device.instName"/>
        </van-cell>
      </div>
      <van-button v-if="doctor.admin===1" @click="onClick2ShowDeleteConfirm" class="mt10" block color="#E3E3E3">
        <!--解除绑定-->
        <span class="c_red fb">{{ $t('lang.device_edit_deletebind') }}</span>
      </van-button>
    </div>
    <van-popup class="h250" v-model="alias_show" round position="bottom" close-on-click-overlay>
      <div class="bc_gray">
        <!--修改别名-->
        <van-divider class="lh50 c_black f18">{{ $t('lang.device_edit_modifyalias') }}</van-divider>
      </div>
      <van-cell class="list_radius mt20 mb50">
        <van-field v-model="device_alias" label="" :placeholder="$t('lang.pleaseInput')"
                   :error-message="aliasErrorMsg"/>
      </van-cell>
      <van-row gutter="20" class="m1500">
        <van-col span="8">
          <van-button @click="cancelAlias" block color="#EDEDED">
            <!--取消-->
            <span class="c_green fb">{{ $t('lang.device_edit_cancel') }}</span>
          </van-button>
        </van-col>
        <van-col span="16">
          <van-button @click="onClick2UpdateAlias" block color="#42BD56">
            <!--保存-->
            <span class="fb">{{ $t('lang.device_edit_save') }}</span>
          </van-button>
        </van-col>
      </van-row>
    </van-popup>
    <van-popup class="h250" v-model="ward_show" round position="bottom" close-on-click-overlay>
      <div class="bc_gray">
        <!--修改病区-->
        <van-divider class="lh50 c_black f18">{{ $t('lang.device_edit_modifyward') }}</van-divider>
      </div>
      <van-cell class="list_radius mt20 mb50">
        <van-field v-model="ward_name" label="" :placeholder="$t('lang.pleaseInput')" :error-message="wardErrorMsg"/>
      </van-cell>
      <van-row gutter="20" class="m1500">
        <van-col span="8">
          <van-button @click="cancelWard" block color="#EDEDED">
            <!--取消-->
            <span class="c_green fb">{{ $t('lang.device_edit_cancel') }}</span>
          </van-button>
        </van-col>
        <van-col span="16">
          <van-button @click="onClick2UpdateWard" block color="#42BD56">
            <!--保存-->
            <span class="fb">{{ $t('lang.device_edit_save') }}</span>
          </van-button>
        </van-col>
      </van-row>
    </van-popup>
  </section>
</template>

<script>
import {deleteBindDvDoctor, getDeviceAdminDoc} from "@/services/dvDoctor";
import {updateDeviceInfo, getDeviceData} from "@/services/dvDevice";
import constants from "@/constants";
import utils from "@/utils";
import i18n from "@/lang";
import {Toast, Dialog} from "vant";

export default {
  name: "DeviceInformation",

  async mounted() {

    console.log(this.dv_device);
    let dr = await getDeviceAdminDoc(this.dv_device.deviceId);
    console.log(dr);
    this.doc_admin = dr.data;
    console.log(this.doc_admin);
  },

  data() {
    return {
      alias_show: false,
      ward_show: false,
      device_alias: "",
      ward_name: "",
      aliasErrorMsg: "", //格式错误，别名长度限6字符内
      wardErrorMsg: "",
      doctor: this.$store.state.doctorWholeObj,
      dv_device: this.$store.state.bindDeviceObj,
      doc_admin: "", //管理员医生
    };
  },
  methods: {

    //显示编辑别名
    reviseName() {
      console.log(this.dv_device);

      if (this.doctor.admin == constants.DocDeviceAdmin.YES) {
        this.alias_show = true;
        this.device_alias = this.dv_device.deviceAlias
      } else {
        Toast({
          message: i18n.t("lang.device_edit_limitadmin") + i18n.t("lang.device_edit_modify"), //限管理员修改
          position: 'bottom',
        });
      }
    },

    //显示编辑病区
    reviseWard() {
      if (this.doctor.admin == constants.DocDeviceAdmin.YES) {
        this.ward_show = true;
        this.ward_name = this.dv_device.wardName
      } else {
        Toast({
          message: i18n.t("lang.device_edit_limitadmin") + i18n.t("lang.device_edit_modify"), //限管理员修改
          position: 'bottom',
        });
      }
    },

    cancelAlias() {
      this.alias_show = false;
    },

    cancelWard() {
      this.ward_show = false;
    },

    onClick2ShowDeleteConfirm() {
      Dialog.confirm({
        title: i18n.t("lang.device_edit_comftodbind"), //确认解除绑定该设备
        message: i18n.t("lang.device_edit_comftodbinddesc"), //解除该设备可能影响其他医生监控数据
      })
          .then(() => {
            // on confirm
            this.onClick2DeleteBind();
          })
          .catch(() => {
            // on cancel
          });
    },

    // 更新设备别名
    onClick2UpdateAlias: async function () {

      //保存前判断
      this.aliasErrorMsg = "";

      // 别名格式判断
      if (utils.isNull(this.device_alias) || this.device_alias.length > 6) {
        this.aliasErrorMsg = i18n.t("lang.editAliasNameError");
        return;
      }
      //  请求服务端更新设备别名
      let resultData = await updateDeviceInfo(this.doctor.doctorId, this.dv_device.deviceId, null, this.device_alias);
      // eslint-disable-next-line no-empty
      if (constants.AJAX_OK === resultData.code && resultData.data) {
        this.device_alias = "";
        this.cancelAlias();
        this.onClick2GetDvData();
        Toast({
          message: i18n.t("lang.device_edit_updatesuccess"), //更新成功
          position: 'bottom',
        });
      } else {
        Toast({
          message: i18n.t("lang.device_edit_updatefail"), //更新失败
          position: 'bottom',
        });
      }
    },

    // 更新设备病区
    onClick2UpdateWard: async function () {

      //保存前判断
      this.wardErrorMsg = "";

      // 病区格式判断
      if (utils.isNull(this.ward_name) || this.ward_name.length > 10) {
        this.wardErrorMsg = i18n.t("lang.editWardNameError");
        return;
      }

      //  请求服务端更新设备病区
      let resultData = await updateDeviceInfo(this.doctor.doctorId, this.dv_device.deviceId, this.ward_name, null);
      // eslint-disable-next-line no-empty
      if (constants.AJAX_OK === resultData.code && resultData.data) {
        this.ward_name = "";
        this.cancelWard();
        this.onClick2GetDvData();

        Toast({
          message: i18n.t("lang.device_edit_updatesuccess"), //更新成功
          position: 'bottom',
        });
      } else {
        Toast({
          message: i18n.t("lang.device_edit_updatefail"), //更新失败
          position: 'bottom',
        });
      }
    },

    // 管理员解除绑定设备
    onClick2DeleteBind: async function () {

      console.log(this.doctor.doctorId, this.dv_device.deviceId);
      //  请求服务端解除绑定设备
      let resultData = await deleteBindDvDoctor(this.doctor.doctorId, this.dv_device.deviceId);

      // eslint-disable-next-line no-empty
      if (constants.AJAX_OK === resultData.code && resultData.data) {
        Toast({
          message: i18n.t("lang.device_edit_dbindsuccess"), //解除绑定成功
          position: 'bottom',
        });

        await this.$router.replace("/deviceCenter")

      } else {
        Toast({
          message: i18n.t("lang.device_edit_dbindfail"), //解除绑定失败
          position: 'bottom',
        });
      }
    },

    toAdminNameDesc() {
      if (null == this.doc_admin) {
        return i18n.t("lang.device_edit_limitadmin") + i18n.t("lang.device_edit_modify");
      }
      return i18n.t("lang.device_edit_limitadmin") + '“' + this.doc_admin.nameAlias + '”' + i18n.t("lang.device_edit_modify");
    },

    //获取设备监控数据
    onClick2GetDvData: async function () {

      console.log(this.dv_device.deviceId);

      let resultData = await getDeviceData(this.dv_device.deviceId);
      if (constants.AJAX_OK === resultData.code && resultData.data && utils.isNotNull(resultData.data)) {

        this.$store.commit("setBindDeviceObj", resultData.data);
        this.dv_device = this.$store.state.bindDeviceObj;
      }
    },

  },
}
</script>
