import axios from "axios";
import {Toast} from 'vant';
import i18n from "@/lang";
// import QS from 'qs';

axios.defaults.timeout = 10000;
axios.defaults.baseURL = "";

// http request 拦截器
// axios.interceptors.request.use(
//   config => {
//     // const token = getCookie('名称');注意使用的时候需要引入cookie方法，推荐js-cookie
//     config.data = JSON.stringify(config.data);
//     config.headers = {
//       'Content-Type': 'application/x-www-form-urlencoded'
//     }
//     // if(token){
//     //   config.params = {'token':token}
//     // }
//     return config;
//   },
//   error => {
//     return Promise.reject(error);
//   }
// );

// http response 拦截器
// axios.interceptors.response.use(
//   response => {
//     if (response.data.errCode == 2) {
//       router.push({
//         path: "/login",
//         querry: { redirect: router.currentRoute.fullPath }//从哪个页面跳转
//       })
//     }
//     return response;
//   },
//   error => {
//     return Promise.reject(error)
//   }
// )

/**
 * 封装get方法
 * @param url
 * @param params
 * @param hideLoading
 * @returns {Promise}
 */

export function getHttp(url, params = {}, hideLoading) {
    if (!hideLoading) {
        Toast.loading({
            forbidClick: true,
            duration: 0
        });
    }

    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: params
            })
            .then(response => {
                resolve(response.data);
                Toast.clear(); // // 关闭动画
            })
            .catch(err => {
                reject(err);
                Toast.clear(); // // 关闭动画

                Toast({
                    message: i18n.t("lang.serviceError"),
                    position: 'bottom',
                });
            });
    });
}

/**
 * 封装post方法
 * @param url
 * @param data
 * @param hideLoading
 * @returns {Promise}
 */

export function postHttp(url, data = {}, hideLoading) {
    if (!hideLoading) {
        Toast.loading({
            forbidClick: true,
            duration: 0
        });
    }

    return new Promise((resolve, reject) => {
        axios
            .post(url, data)
            .then(response => {
                resolve(response.data);
                Toast.clear(); // // 关闭动画
            })
            .catch(err => {
                reject(err);
                Toast.clear(); // // 关闭动画

                Toast({
                    message: i18n.t("lang.serviceError"),
                    position: 'bottom',
                });
            });
    });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @param hideLoading
 * @returns {Promise}
 */
export function patchHttp(url, data = {}, hideLoading) {
    if (!hideLoading) {
        Toast.loading({
            forbidClick: true,
            duration: 0
        });
    }

    return new Promise((resolve, reject) => {
        axios.patch(url, data)
            .then(response => {
                resolve(response.data);
                Toast.clear(); // // 关闭动画
            }, err => {
                reject(err)
                Toast.clear(); // // 关闭动画

                Toast({
                    message: i18n.t("lang.serviceError"),
                    position: 'bottom',
                });
            })
    })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @param hideLoading
 * @returns {Promise}
 */
export function putHttp(url, data = {}, hideLoading) {
    if (!hideLoading) {
        Toast.loading({
            forbidClick: true,
            duration: 0
        });
    }

    return new Promise((resolve, reject) => {
        axios.put(url, data)
            .then(response => {
                resolve(response.data);
                Toast.clear(); // // 关闭动画
            }, err => {
                reject(err)
                Toast.clear(); // // 关闭动画

                Toast({
                    message: i18n.t("lang.serviceError"),
                    position: 'bottom',
                });
            })
    })
}

/**
 * 封装delete请求
 * @param url
 * @param data
 * @param hideLoading
 * @returns {Promise}
 */
export function deleteHttp(url, data = {}, hideLoading) {
    if (!hideLoading) {
        Toast.loading({
            forbidClick: true,
            duration: 0
        });
    }

    return new Promise((resolve, reject) => {
        axios.delete(url, data).then(response => {
            resolve(response.data)
            Toast.clear(); // // 关闭动画
        }).catch(err => {
            reject(err)
            Toast.clear(); // // 关闭动画

            Toast({
                message: i18n.t("lang.serviceError"),
                position: 'bottom',
            });
        })
    })
}
