import Vue from 'vue'
import App from '@/App.vue'
import "amfe-flexible/index.js"
import router from '@/router/index'
import store from '@/store/index'
import i18n from '@/lang/index'
import VueClipboard from 'vue-clipboard2'
import ECharts from 'vue-echarts'
// 手动引入 ECharts 各模块来减小打包体积
import {use} from 'echarts/core'
import '@vant/touch-emulator';
import utils from "@/utils";

Vue.config.devtools = true
Vue.config.productionTip = false

// 开发环境下面使用vConsole进行调试
if (process.env.NODE_ENV === 'development') {
    console.log('development')
}

import {
    Button,
    Cell,
    CellGroup,
    Image as VanImage,
    Divider,
    Grid,
    GridItem,
    Popup,
    Field,
    Col,
    Row,
    SubmitBar,
    PasswordInput,
    NumberKeyboard,
    NoticeBar,
    Tabbar,
    TabbarItem,
    Empty,
    Loading,
    Badge,
    Checkbox,
    CheckboxGroup,
    CountDown,
    Step,
    Steps,
    ActionSheet,
    Picker,
    Switch,
    List,
    Uploader,
    PullRefresh
} from 'vant'

Vue.use(Picker);
Vue.use(Button);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(VanImage);
Vue.use(Divider);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Col);
Vue.use(Row);
Vue.use(SubmitBar);
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(NoticeBar);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Empty);
Vue.use(Loading);
Vue.use(Badge);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(CountDown);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Switch);
Vue.use(ActionSheet);
Vue.use(VueClipboard);
Vue.use(List);
Vue.use(Uploader);
Vue.use(PullRefresh);

import {
    CanvasRenderer
} from 'echarts/renderers'
import {
    BarChart
} from 'echarts/charts'
import {
    GridComponent,
    TooltipComponent,
    LegendComponent,
    TitleComponent,
    ToolboxComponent,
    VisualMapComponent,
    TimelineComponent,
    CalendarComponent,
    DataZoomComponent
} from 'echarts/components'

use([
    CanvasRenderer,
    BarChart,
    GridComponent,
    TooltipComponent,
    LegendComponent,
    TitleComponent,
    ToolboxComponent,
    VisualMapComponent,
    TimelineComponent,
    CalendarComponent,
    DataZoomComponent
]);
// 全局注册
Vue.component('v-chart', ECharts)

import '@/assets/css/base.css';
import '@/assets/css/device.css';
import constants from "@/constants";

// 路由拦截
router.beforeEach((to, from, next) => {
    // 标题更改
    if (to.meta.title) {
        document.title = to.meta.title;
    }

    // 未登录拦截
    if (!to.meta.excludeLogin && "" === store.state.doctorWholeObj.doctorId) {
        next({
            path: '/'
        })
    } else {
        next()
    }

    // APP
    if (constants.AccessType.APP == store.state.accessType) {
        // ios页面返回键处理
        let arr = store.state.specialPage;
        // 显示返回键
        let flag = true
        for (let i = 0; i < arr.length; i++) {
            if (to.path === arr[i]) {
                flag = false
                break
            }
        }
        utils.jsUseApp("appReturnKey", {
            show: flag
        })
    }

    next();
})

// 返回监听
window.addEventListener("popstate", function () {
    // 列表中页面点击返回退出程序
    let arr = store.state.specialPage;
    for (let i = 0; i < arr.length; i++) {
        if (router.currentRoute.path === arr[i]) {
            // 为了处理：ios网页会出现明显的跳转动作
            router.replace(router.currentRoute.path)

            store.dispatch("quit")
            break
        }
    }
}, false);

new Vue({
    render: h => h(App),
    router,
    store,
    i18n
}).$mount('#app')
