<template>
  <div class="pr">
    <div class="pa c_999 f12 mt10 ml10">× {{ 1 === trendType ? '500' : '10' }}mL</div>
    <!-- 引流液体累计趋势图 -->
    <v-chart v-if="realOptions" class="bc_black yuan6 min-height200" @datazoom="onDataZoom"
             :option="realOptions" autoresize/>
  </div>
</template>

<script>
import i18n from "@/lang";
import constants from "@/constants";

export default {
  name: "DrainageFluidChart",
  props: ['xList', 'dataList', 'trendType'],
  data() {
    return {
      // 选中的数据
      selData: '',
      // 选项
      realOptions: '',
      // 缩放
      zoomStart: 0,
      zoomEnd: 100,
    }
  },
  watch: {
    options(newValue) {
      if (newValue) {
        for (let i = 0; i < newValue.dataZoom.length; i++) {
          newValue.dataZoom[i].start = this.zoomStart;
          newValue.dataZoom[i].end = this.zoomEnd;
        }
        this.realOptions = newValue;
      }
    }
  },
  computed: {
    options() {
      return {
        title: {
          text: this.selData,
          right: '3%',
          top: 10,
          textStyle: {
            color: '#6EFF9B',
            fontWeight: 'normal',
            fontSize: 12
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          top: 40,
          bottom: 55,
          containLabel: true
        },
        dataZoom: [{
          type: 'inside',
          start: 0,
          end: 100,
          borderRadius: 20,
        }, {
          start: 0,
          end: 100,
          bottom: 30,
          brushSelect: false,
          showDataShadow: false,
          height: 20,
          backgroundColor: 'rgba(255,255,255,0.2)',
          borderColor: "#292F33",
          handleIcon: 'image://' + require("@/assets/img/drag_small.png"),
          fillerColor: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
              offset: 0, color: '#6EFF9B' // 0% 处的颜色
            }, {
              offset: 1, color: '#BCFFD1' // 100% 处的颜色
            }],
            globalCoord: false // 缺省为 false
          },
          handleStyle: {
            color: '#4E82D7',
          },
        }],
        xAxis: {
          type: 'category',
          data: this.xList,
          axisTick: {
            alignWithLabel: true
          },
          axisPointer: {
            show: true,
            triggerTooltip: false,
            type: 'shadow',
            label: {
              show: false,
              formatter: (params) => {
                let arr = params.value.replace("h", i18n.t("lang.hour")).replace("mins", i18n.t("lang.min"))

                let index = this.xList.indexOf(params.value);
                // 非最后一条数据
                if (this.xList.length - 1 !== index) {
                  arr += i18n.t("lang.before")
                }

                if (index < this.dataList.length) {
                  arr += " | " + (constants.TrendType.DAY === this.trendType ? i18n.t("lang.oneDayValue") : i18n.t("lang.oneHourValue"))
                  arr += ": " + (0 === this.dataList[index] || this.dataList[index] ? this.dataList[index] : '--')
                  arr += "、" + i18n.t("lang.totalValue") + ": "

                  let totalFlag = false
                  let totalNum = 0;
                  for (let i = index; i < this.dataList.length; i++) {
                    if (0 === this.dataList[i] || this.dataList[i]) {
                      totalFlag = true
                      totalNum += this.dataList[i]
                    }
                  }
                  if (totalFlag) {
                    arr += totalNum
                  } else {
                    arr += "--"
                  }
                } else {
                  arr += " | " + (constants.TrendType.DAY === this.trendType ? i18n.t("lang.oneDayValue") : i18n.t("lang.oneHourValue"))
                  arr += ": " + (0 === this.dataList[this.dataList.length - 1] || this.dataList[this.dataList.length - 1] ? this.dataList[this.dataList.length - 1] : '--')
                  arr += "、" + i18n.t("lang.totalValue") + ": " + (0 === this.dataList[this.dataList.length - 1] || this.dataList[this.dataList.length - 1] ? this.dataList[this.dataList.length - 1] : '--')
                }
                this.selData = arr
              }
            },
            handle: {
              show: true,
              color: '#7581BD',
              size: 22,
              margin: 55,
              shadowColor: 'rgba(0, 0, 0, 0)',
              icon: 'image://' + require("@/assets/img/drag_big.png")
            },
            lineStyle: {
              type: 'solid'
            }
          },
        },
        yAxis: {
          type: 'value',
          nameTextStyle: {
            color: "#999999",
          },
          axisLabel: {
            color: "#6EFF9B",
            formatter: (value) => {
              return value / (1 === this.trendType ? 500 : 10);
            }
          },
          splitLine: {
            lineStyle: {
              color: "#FFF",
              opacity: 0.2
            }
          },
          min: 0,
          max: 1 === this.trendType ? 2500 : 50,
          interval: 1 === this.trendType ? 500 : 10,
          splitNumber: 5,
        },
        series: [{
          data: this.dataList,
          type: 'bar',
          barWidth: 5,
          itemStyle: {
            borderRadius: 50,
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: '#BCFFD1' // 0% 处的颜色
              }, {
                offset: 1, color: '#6EFF9B' // 100% 处的颜色
              }],
              globalCoord: false // 缺省为 false
            }
          }
        }]
      }
    },
  },
  methods: {
    onDataZoom: function (action) {
      if ("batch" in action && Array.isArray(action.batch) && action.batch.length > 0) {
        this.zoomStart = action.batch[0].start;
        this.zoomEnd = action.batch[0].end;
      } else if ("start" in action && "end" in action) {
        this.zoomStart = action.start;
        this.zoomEnd = action.end;
      }
    },
  }
}
</script>

<style scoped>
.bc_black {
  background-color: #292F33;
}

.min-height200 {
  min-height: 200px;
}
</style>
