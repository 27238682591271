<template>
  <section class="van-doc-demo-section">
    <div class="bb_gray bc_999 tip_sel">
      <div class="p10 of">
        <van-image class="left w14 mt4" :src="require('@/assets/img/sort.png')"/>
        <p class="left f14 lh24 ml5">{{ this.sort_title }}</p><!--病区号+床位号-->
        <van-button class="right" @click="showPopup" color="#CECECE" size="mini" round>
          <span class="p0008 fb c_gray1">{{ $t("lang.device_csel_sort") }}</span><!--排序-->
        </van-button>
      </div>
    </div>
    <div class="h40"></div>
    <div class="m15-00 dfr">
      <van-cell class="list_radius">
        <van-cell @click="toDeviceDetail(value)" v-for="(value,key,index) in doc_devices" :key="index" is-link center>
          <van-image class="left w44 h44" :src="require('@/assets/img/electric_negative_pressure_aspirator.png')"/>
          <div class="left">
            <p class="fb">{{ value.titleDesc }}</p>
            <div class="fs80">
              <p class="left fs90 blue_tag">{{ value.deviceAlias }}</p>
              <p class="left ml5">{{ getDvTime(value) }}</p>
            </div>
          </div>
          <div v-if="value.redNote === showRedNote" class="right red_pot mt20"></div>
        </van-cell>
      </van-cell>
    </div>
    <!--绑定新设备-->
    <van-button @click="showBindPopup" class="van-bottom-btn" icon="plus" round type="primary" color="#F7C636">
      <span class="fb">{{ $t("lang.device_binding_bindnewdv") }}</span>
    </van-button>
    <!--选择排序方式-->
    <van-popup v-model="sort_show" round position="bottom" close-on-click-overlay>
      <van-cell-group>
        <!-- 病区号+床位号    优先按病区号排序，床位号其次-->
        <van-cell center clickable @click="toGetByBed" :title="$t('lang.device_csel_wandbedno')"
                  :icon="require('@/assets/img/patient_bed_number.png')" :label="$t('lang.device_csel_sortwtobed')">
          <template v-if="sort_type === '1'" #right-icon>
            <van-image class="right w14" :src="require('@/assets/img/cell_selected.png')"/>
          </template>
        </van-cell>
        <!--病区号+设备运行时间   优先按病区号排序，设备运行时间其次-->
        <van-cell center clickable @click="toGetByTime" :title="$t('lang.device_csel_wandruntime')"
                  :icon="require('@/assets/img/time.png')" :label="$t('lang.device_csel_sortwtotime')">
          <template v-if="sort_type === '2'" #right-icon>
            <van-image class="right w14" :src="require('@/assets/img/cell_selected.png')"/>
          </template>
        </van-cell>
      </van-cell-group>
    </van-popup>
    <!--选择添加方式  新绑定设备-->
    <van-popup v-model="binddv_show" round position="bottom" close-on-click-overlay>
      <van-cell-group>
        <!-- 扫码添加    扫描设备二维码，自动识别编码后完成添加-->
        <van-cell @click="onClick2QrBindDevice" center clickable is-link :title="$t('lang.device_add_scanqrbind')"
                  :label="$t('lang.device_add_scanqrnote')"/>
        <!--手动录入添加    动输入设备编码完成添加-->
        <van-cell @click="onClick2ManualBindDevice" center clickable is-link :title="$t('lang.device_add_manualbind')"
                  :label="$t('lang.device_add_manualbindnote')"/>
      </van-cell-group>
    </van-popup>
  </section>
</template>

<script>
import {getDocDeviceList} from "@/services/dvDevice";
import {getAdminDocDevice} from "@/services/dvDoctor";
import utils from "@/utils";
import i18n from "@/lang";
import constants from "@/constants";
import {Toast} from "vant";
import {getInstById} from "@/services/inst";
import store from "@/store";

export default {
  async mounted() {
    // app调用js
    window.appScanReturn = this.onClick2GetDevice

    // 获取管理员绑定设备
    this.sort_type = "1";
    this.sort_title = i18n.t("lang.device_csel_wandbedno");
    await this.onClick2GetDocDeviceList();

    //获取医生所在的机构
    let resultData = await getInstById(this.doctor.instId);
    if (constants.AJAX_OK === resultData.code && resultData.data) {
      document.title = resultData.data.instName;
    }
  },

  data() {
    return {
      doctor: this.$store.state.doctorWholeObj,
      doc_devices: "", //管理员绑定设备
      sort_title: "", //排序标题
      sort_type: "1", //排序类型 1按床号  2按时间
      binddv_show: false, //新绑定设备弹窗
      sort_show: false,
      showRedNote: constants.RedNote.YES,
      accountId: this.$store.state.cacheAccountId
    };
  },
  methods: {
    //排序弹窗
    showPopup() {
      this.sort_show = true;
    },

    // 获取设备运行时间
    getDvTime: function (docPatObj) {
      //新设备无患者 beginTime为空
      if (!docPatObj.beginTime) {
        return docPatObj.deptName;
      }

      let content =  i18n.t("lang.run");
      let runTime_h = parseInt(docPatObj.runTime / 60)
      let runTime_m = docPatObj.runTime - runTime_h * 60
      if (0 !== runTime_h) {
        content += runTime_h + i18n.t("lang.hour2")
      }
      if (0 !== runTime_m) {
        content += runTime_m + i18n.t("lang.min2")
      }
      return content
    },

    //绑定新设备弹窗
    showBindPopup() {
      this.binddv_show = true;
    },

    //手动录入绑定新设备
    onClick2ManualBindDevice() {
      this.binddv_show = false;
      this.$router.push("/addDeviceManual");
    },

    //扫码绑定新设备
    async onClick2QrBindDevice() {
      this.binddv_show = false;

      let accessType = this.$store.state.accessType
      // APP
      if (constants.AccessType.APP == accessType) {
        utils.jsUseApp("appScan")
      }
      // WECHAT
      else if (constants.AccessType.WECHAT == accessType) {
        // 扫描设备并跳转到页面
        let pageUrl = store.state.initUrl;
        await utils.scanQR(pageUrl, this.accountId, this.onClick2GetDevice);
      }
    },

    // 获取设备绑定信息
    onClick2GetDevice: async function (devicenum) {

      // 根据设备号获取设备
      let resultData = await getAdminDocDevice(this.doctor.doctorId, devicenum);
      if (constants.AJAX_OK === resultData.code || constants.SERVICE_NO_DEVICE === resultData.code) {

        Toast({
          message: i18n.t("lang.editNotFoundDevice"), //提示
          position: 'bottom',
        });

      } else if (constants.SERVICE_NO_BIND === resultData.code) {

        //有设备但是医生没关注
        this.$store.commit("setBindDeviceObj", resultData.data);
        this.$router.push("/addDeviceBing");

      } else if (constants.SERVICE_BIND === resultData.code) {

        //有设备+医生关注
        this.$store.commit("setBindDeviceObj", resultData.data);
        this.$router.push("/addedDeviceInfo");
      }
    },

    // 获取设备，按床号排序
    toGetByBed() {
      this.sort_show = false;
      this.sort_type = "1";
      this.sort_title = i18n.t("lang.device_csel_wandbedno");

      this.onClick2GetDocDeviceList();
    },

    // 取设备，按监控时间排序
    toGetByTime() {
      this.sort_show = false;
      this.sort_type = "2";
      this.sort_title = i18n.t("lang.device_csel_wandruntime");
      this.onClick2GetDocDeviceList();
    },

    //获取管理员绑定设备数组
    onClick2GetDocDeviceList: async function () {

      let dr = await getDocDeviceList(this.doctor.doctorId, this.doctor.doctorId, "1", this.sort_type);
      this.doc_devices = dr.data;
    },

    //设备详情
    toDeviceDetail(docDevice) {
      if (utils.isNull(docDevice.patientId)) {
        this.$store.commit("setBindDeviceObj", docDevice);
        this.$router.push("/deviceDataHomeEmpty");
      } else {
        this.$store.commit("setBindDeviceObj", docDevice);
        this.$router.push("/deviceDataHome");
      }
    },
  },
}
</script>

<style scoped>
.p0008 {
  padding: 0px 8px;
}
</style>
