<template>
  <div>
    <router-view></router-view>

    <!--底部tab页-->
    <van-tabbar v-model="tabActive" active-color="#42BD56" inactive-color="#828999">

      <!--设备-->
      <van-tabbar-item to="/deviceCenter">
        {{ $t('lang.device') }}
        <template #icon="props">
          <img :src="props.active ? tabIcon1.active : tabIcon1.inactive"/>
        </template>
      </van-tabbar-item>

      <!--消息-->
      <van-tabbar-item to="/msg">
        {{ $t('lang.msg') }}
        <template #icon="props">
          <img :src="props.active ? tabIcon2.active : tabIcon2.inactive"/>
        </template>
      </van-tabbar-item>

      <!--个人中心-->
      <van-tabbar-item to="/userCenter">
        {{ $t('lang.userCenter') }}
        <template #icon="props">
          <img :src="props.active ? tabIcon3.active : tabIcon3.inactive"/>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import constants from "@/constants";
import {getDoctorById} from "@/services/doctor";

export default {
  name: "MainHome",
  data() {
    return {
      tabActive: 0,
      tabIcon1: {
        active: require('@/assets/img/equipment_monitoring_sel.png'),
        inactive: require('@/assets/img/equipment_monitoring.png'),
      },
      tabIcon2: {
        active: require('@/assets/img/home_message_sel.png'),
        inactive: require('@/assets/img/home_message.png'),
      },
      tabIcon3: {
        active: require('@/assets/img/home_user_sel.png'),
        inactive: require('@/assets/img/home_user.png'),
      },
    }
  },
  mounted() {
    switch (this.$route.path) {
      case "/msg":
        this.tabActive = 1
        break;
      case "/userCenter":
        this.tabActive = 2
        break;
      default:
        this.getDoc()
        this.tabActive = 0
        break;
    }
  },
  beforeRouteUpdate(to, from, next) {
    switch (to.path) {
      case "/msg":
        this.tabActive = 1
        break;
      case "/userCenter":
        this.tabActive = 2
        break;
      default:
        this.getDoc()
        this.tabActive = 0
        break;
    }
    next()
  },
  methods: {
    // 获取医生信息
    getDoc: async function () {
      let resultData = await getDoctorById(this.$store.state.doctorWholeObj.doctorId);
      if (constants.AJAX_OK === resultData.code && resultData.data) {
        this.$store.commit("setDoctorWholeObj", resultData.data)
      }
    }
  }
}
</script>

<style scoped>

</style>
