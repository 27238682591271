<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr">
      <div class="yuan6 of p10 bc_fff mb5">
        <p class="left f14 lh24">{{ $t('lang.msg_wechatSent') }}</p>
        <van-switch active-color="#09BB07" class="right" v-model="wechatChecked"
                    size="24px"/>
      </div>
      <div class="yuan6 of p10 bc_fff mb5">
        <p class="left f14 lh24">{{ $t('lang.msg_msgSent') }}</p>
        <van-switch active-color="#09BB07" class="right" v-model="msgChecked" size="24px"/>
      </div>
      <p class="f12 c_gray1">{{ $t('lang.msg_tips') }}</p>
    </div>
  </section>
</template>

<script>
import utils from "@/utils";
import constants from "@/constants";
import {getSettingByDoctor, modSetting} from "@/services/stSetting";

export default {
  async mounted() {
    //从缓存中获取医生
    this.doctor = utils.getDoctorCache(this);
    console.log(this.doctor.doctorId);

    let resultData = await getSettingByDoctor(this.doctor.doctorId);
    if (constants.AJAX_OK === resultData.code && resultData.data) {
      console.log(resultData.data)
      this.setting = resultData.data;

      //是否发送微信消息
      if (this.setting.wechatPush == constants.MsgTips.SEND) {
        this.wechatChecked = true;
      } else {
        this.wechatChecked = false;
      }
      //是否发送短信
      if (this.setting.smsPush == constants.MsgTips.SEND) {
        this.msgChecked = true;
      } else {
        this.msgChecked = false
      }
    }
  },
  data() {
    return {
      doctor: utils.getDoctorCache(this),
      setting: "",
      wechatChecked: true,
      msgChecked: true,
    };
  },
  methods: {},
  watch: {
    async wechatChecked() {
      //是否发送微信
      if (this.wechatChecked) {
        this.setting.wechatPush = constants.MsgTips.SEND;
      } else {
        this.setting.wechatPush = constants.MsgTips.CLOSE;
      }
      await modSetting(this.setting);
    },
    async msgChecked() {
      //是否发送短信
      if (this.msgChecked) {
        this.setting.smsPush = constants.MsgTips.SEND;
      } else {
        this.setting.smsPush = constants.MsgTips.CLOSE;
      }
      await modSetting(this.setting);
    },
  },
};
</script>

<style scoped>
</style>
