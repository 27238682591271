import utils from "@/utils";
import url from "./index"

// 管理员根据设备号查询设备
export function getAdminDocDevice(doctorId, deviceNum) {
    return utils.getHttp(url.GET_ADMIN_DOC_DEVICE, {
        doctorId: doctorId,
        deviceNum: deviceNum,
    });
}

// 管理员绑定新设备
export function bindDvDoctor(doctorId, deviceId, wardName, deviceAlias) {
    let doctorObj = {doctorId: doctorId, deviceId: deviceId, wardName: wardName, deviceAlias: deviceAlias};
    return utils.postHttp(url.BIND_DV_DOCTOR, doctorObj)
}

// 管理员解除绑定设备
export function deleteBindDvDoctor(doctorId, deviceId) {
    let doctorObj = {doctorId: doctorId, deviceId: deviceId};
    return utils.putHttp(url.DELETE_BIND_DV_DOCTOR, doctorObj)
}

// 医生取消关注设备
export function cancelAttentDvDoctor(doctorId, deviceId) {
    let doctorObj = {doctorId: doctorId, deviceId: deviceId};
    return utils.putHttp(url.CANCEL_ATTENT_DV_DOCTOR, doctorObj)
}

// 新增医生关注设备
export function attentDvDoctor(doctorId, deviceIds) {
    let doctorObj = {doctorId: doctorId, deviceIds: deviceIds};
    return utils.postHttp(url.ATTENT_DV_DOCTOR, doctorObj)
}

// 获取设备的管理员
export function getDeviceAdminDoc(deviceId) {
    return utils.getHttp(url.GET_DEVICE_ADMIN_DOC, {
        deviceId: deviceId,
    });
}