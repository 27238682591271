import {getWXParam} from "@/services/wxAccount";
import constants from "@/constants";
import wx from "weixin-js-sdk";
import {Toast} from "vant";
import i18n from "@/lang";

/**
 * 扫描二维码
 * @param accountId
 * @param callBack
 * @returns {Promise<void>}
 */
export async function scanQR(pageUrl, accountId, callBack) {
    console.log("pageUrl:" + pageUrl);
    weChatSigConfig(accountId, pageUrl);
    wx.ready(function () {
        wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
                setTimeout(() => {
                    console.log(JSON.stringify(res));
                    let result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                    if (result) {
                        callBack(result);
                    } else {
                        Toast({
                            message: i18n.t("lang.editDeviceNumError"), //提示
                            position: 'bottom',
                        });
                    }
                }, 1000);
            }
        });
    });
}

/**
 *获取微网站签名
 * @return {Boolean}
 */
export async function weChatSigConfig(accountid, pageUrl) {
    console.log("accountid:" + accountid);

    //从服务端获取签名
    let resultData = await getWXParam(accountid, pageUrl);
    if (constants.AJAX_OK === resultData.code && resultData.data) {
        console.log("--------------wx_config---------------------");
        console.log("appId:" + resultData.data.appId);//appid
        console.log("timeStamp:" + resultData.data.timeStamp);//时间戳
        console.log("nonceStr:" + resultData.data.nonceStr);//秘钥
        console.log("signature:" + resultData.data.signature);//签名

        //根据返回值配置微信jssdk参数
        wx.config({
            debug: false,
            appId: resultData.data.appId,
            timestamp: resultData.data.timeStamp,
            nonceStr: resultData.data.nonceStr,
            signature: resultData.data.signature,
            jsApiList: ['scanQRCode']
        });
    }
}

