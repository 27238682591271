<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr">
      <van-cell class="list_radius" @click="toDoctorTeamOtherDetails(value)"
                v-for="(value,key,index) in doctorGroups" :key="index">
        <van-cell is-link center>
          <p class="fb">{{ value.deptName }}</p>
          <p class="fs90 c_gray1">{{ value.instName }}</p>
        </van-cell>
      </van-cell>
      <div class="van-bottom">
        <div class="m00-15">
          <!--录入邀请码获得授权-->
          <van-button @click="toCompleteAuthorization" class="fb mb10" type="primary" block>{{$t("lang.uc_docOther_getAuth")}}</van-button>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
import {getDoctorGroups} from "@/services/doctor";
import constants from "@/constants";

export default {
  async mounted() {
    this.doctor = this.$store.state.doctorWholeObj;
    console.log(this.doctor.doctorId);

    let resultData = await getDoctorGroups(this.doctor.doctorId);
    if (constants.AJAX_OK === resultData.code && resultData.data) {
      this.doctorGroups = resultData.data;
    }
  },
  data() {
    return {
      doctor: this.$store.state.doctorWholeObj,
      doctorGroups: ''
    }
  },
  methods: {
    toDoctorTeamOtherDetails(deptGroup) {
      this.$router.push({
        name: 'doctorTeamOtherDetails',
        params: {doctor: this.doctor, deptGroup: deptGroup}
      });
    },
    toCompleteAuthorization() {
      this.$router.push({name: 'completeAuthorization', params: {}});
    }
  }
}

</script>

<style scoped>
.h17 {
  height: 17px;
}

.w42 {
  width: 42px;
}

.h15 {
  height: 15px;
}
</style>
