import utils from "@/utils";
import url from "./index"

// 根据设备号查询设备
export function getByDeviceNum(deviceNum) {
    return utils.getHttp(url.GET_BY_DEVICE_NUM, {
        deviceNum: deviceNum,
    });
}

// 查管理员管理设备 或 查询医生关注设备
export function getDocDevice(doctorId, admin) {
    return utils.getHttp(url.GET_DOC_DEVICE, {
        doctorId: doctorId, //医生ID
        admin: admin,
    });
}

// 查询医生添加、关注的设备
export function getDocDeviceList(adminDocId, doctorId, operType, sortType) {
    console.log(adminDocId, doctorId, operType, sortType);
    return utils.getHttp(url.GET_DOC_DEVICE_LIST, {
        adminDocId: adminDocId, //管理员医生ID
        doctorId: doctorId, //医生ID
        operType: operType,
        sortType: sortType,
    });
}

// 根据设备主键查询设备
export function getDeviceById(deviceId) {
    return utils.getHttp(url.GET_DEVICE_BY_ID, {
        deviceId: deviceId,
    });
}

// 更新设备信息【病区 和别 名】
export function updateDeviceInfo(doctorId, deviceId, wardName, deviceAlias) {
    let doctorObj = {doctorId: doctorId, deviceId: deviceId, wardName: wardName, deviceAlias: deviceAlias};
    return utils.putHttp(url.UPDATE_DEVICE_INFO, doctorObj)
}

// 查询历史患者对应监控数据
export function getPatiDeviceData(deviceId, dvPatientId) {
    console.log(deviceId, dvPatientId);
    return utils.getHttp(url.GET_PATI_DEVICED_ATA, {
        deviceId: deviceId, //设备ID
        dvPatientId: dvPatientId, //患者设备ID
    });
}

// 查询设备对应监控数据
export function getDeviceData(deviceId) {
    return utils.getHttp(url.GET_DEVICE_DATA, {
        deviceId: deviceId, //设备ID
    });
}