<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr lh24">
      <!--原手机号校验-->
      <p class="f22 mt50 fb"> {{ $t("lang.uc_oldPhone_check") }}</p>
      <!--为保障账号安全，修改手机号前需进行身份校验-->
      <p class="f14 c_gray1 mt10">{{ $t("lang.uc_oldPhone_tips") }}</p>

      <!-- 手机 -->
      <div class="bb_gray mt30">
        <van-cell class="list_radius van_bg_zdy1">
          <van-field :label="$t('lang.uc_oldPhone_mobile')" readonly v-model="this.mobile"/>

        </van-cell>
      </div>

      <!-- 验证码获取前 -->
      <div class="bb_gray mt10" v-show="codeBefor">
        <van-cell class="list_radius van_bg_zdy1">
          <!-- 验证码 请输入 -->
          <van-field v-model="sms" type="password" center clearable :label="$t('lang.uc_oldPhone_code')"
                     :placeholder="$t('lang.uc_oldPhone_input')">
            <template #button>
              <!--发送验证码-->
              <van-button size="small" type="primary" @click="getSmsCode">{{
                  $t("lang.uc_oldPhone_sendCode")
                }}
              </van-button>
            </template>
          </van-field>
        </van-cell>
      </div>

      <!-- 验证码获取后 -->
      <div class="bb_gray mt10" v-show="codeAfter">
        <van-cell class="list_radius van_bg_zdy1">
          <!-- 验证码 请输入 -->
          <van-field v-model="sms" type="password" center clearable :label="$t('lang.uc_oldPhone_code')"
                     :placeholder="$t('lang.uc_oldPhone_input')">
            <template #button>
              <van-button size="small" type="default" disabled>
                <!--秒后重新获取-->
                <van-count-down ref="countDown" :auto-start="false" @finish="timeFinish" class="left mt-3" format="ss"
                                :time="time"/>
                {{ $t("lang.uc_oldPhone_reGetCode") }}
              </van-button>
            </template>
          </van-field>
        </van-cell>
      </div>

      <!-- 下一步 -->
      <van-button @click="toNewPhoneInput" class="mt30" type="primary" block>
        {{ $t("lang.uc_oldPhone_step") }}
      </van-button>

      <!--如原手机号收不到验证码请拨打客服电话-->
      <div class="mt10 f12 c_gray">
        {{ $t("lang.uc_oldPhone_tipService") }}
      </div>
    </div>
  </section>
</template>

<script>
import {getSms} from "@/services/doctor";
import constants from "@/constants";
import utils from "@/utils";
import {Toast} from "vant";
import i18n from "@/lang";

export default {
  async mounted() {
    this.doctor = this.$store.state.doctorWholeObj;
    this.mobile = this.doctor.mobile;
  },
  data() {
    return {
      doctor: this.$store.state.doctorWholeObj,
      mobile: '',
      sms: '',
      time: 60 * 1000,
      encryptionCode: '',
      codeBefor: true,
      codeAfter: false
    };
  },
  methods: {
    // 获取短信验证码
    getSmsCode: async function () {
      if (this.mobile) {
        let resultData = await getSms(this.mobile);
        if (constants.AJAX_OK === resultData.code && resultData.data) {
          this.$refs.countDown.reset();
          this.$refs.countDown.start();
          this.encryptionCode = resultData.data
          console.log("code:" + resultData.data);

          this.codeBefor = false;
          this.codeAfter = true;
        }
      }
    },
    // 验证码超时
    timeFinish: function () {
      this.encryptionCode = ""

      this.codeBefor = true;
      this.codeAfter = false;
    },
    //跳转到修改手机号
    toNewPhoneInput() {
      let smsMd5 = utils.md5_16(this.sms);
      console.log("encryptionCode:" + this.encryptionCode);
      console.log("smsMd5:" + smsMd5);
      if (this.encryptionCode.length > 0 && this.encryptionCode == smsMd5) {
        this.$router.push("/newPhoneInput");
      } else {
        Toast(i18n.t("lang.uc_oldPhone_checkFail"));
      }
    },
  }
}
</script>

<style scoped>
.van_bg_zdy1 .van-cell {
  background-color: #EDEDED;
  padding: 10px 0px;
}

.mt-3 {
  margin-top: -3px;
}
</style>
