import Vue from 'vue'
import Vuex from 'vuex'
import utils from '@/utils/index'
import constants from "@/constants/index";
import wx from "weixin-js-sdk";

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        // 访问来源
        accessType: constants.AccessType.WECHAT,

        // 缓存微信openId
        cacheOpenId: utils.getStorage(constants.WX_OPENID) ? utils.getStorage(constants.WX_OPENID) : "",
        // 缓存微信accountId
        cacheAccountId: utils.getStorage(constants.WX_ACCOUNT_ID) ? utils.getStorage(constants.WX_ACCOUNT_ID) : "",

        // 用户统一标识。针对一个微信开放平台帐号下的应用，同一用户是唯一的。（新增医生时使用）
        unionId: '',

        // 医生全局变量（存放服务端ItDoctor对象的JSON格式）
        doctorWholeObj: {
            // 医生ID
            doctorId: "",
            // 机构ID
            instId: "",
            // 手机号
            mobile: "",
            // 姓名昵称
            nameAlias: "",
            // 别名设置
            aliasSet: "",
            // 科室名称
            deptName: "",
            // 头像
            headPortrait: "",
            // 设备管理员 0初始状态 1 管理员 2 非管理员
            admin: "",
            // 6位邀请码
            inviteCode: "",
            // 邀请码有效期
            expiryDate: "",
        },

        // 新绑定设备信息
        bindDeviceObj: {
            // 设备ID
            deviceId: "",
            // 设备类型ID
            dvTypeId: "",
            // 设备号
            deviceNum: "",
            // 机构名称
            instName: "",
            // 科室名称
            deptName: "",
            // 病区名称
            wardName: "",
            // 设备别名
            deviceAlias: "",
            // 二维码
            qrCode: "",
            // 备注
            remark: "",
            // 起始记录时间
            beginTime: "",
            // 患者ID
            patientId: "",
            // 患者姓名
            patName: "",
            // 机构名称
            patInstName: "",
            // 科室名称
            patDeptName: "",
            // 病区名称
            patWardName: "",
            // 床位号
            patBedNum: "",
            // 显示标题
            titleDesc: "",
            //医生关注 1为关注
            docAttent: "",
            //
            danxuan: "",
            //红点提示 1提示
            redNote: "",
            //运行时差 精确到分钟
            runTime: "",

        },

        // 申请管理员信息
        applyInfo: {
            // 机构
            inst: {
                // 机构名称
                instName: "",
                // 机构地址
                address: "",
                // 省
                province: "",
                // 市
                city: "",
                // 区/县
                county: "",
                // 经度
                longitude: "",
                // 纬度
                latitude: "",
            },
            // 科室名称
            depName: ""
        },

        // 设备管理员ID
        adminDocId: "",

        // 初始url,微信扫码用
        initUrl: "",

        // 需要特殊处理的页面（android/web：点击返回退出程序，ios：没有返回按钮）
        specialPage: ['/', '/appLogin', '/login', '/deviceCenter', '/initHome', '/msg', '/userCenter', '/adminDeviceList', '/followDeviceList', '/errorPage']
    },
    mutations: {
        setAccessType(state, type) {
            state.accessType = type
        },
        setCacheOpenId(state, openId) {
            state.cacheOpenId = openId
        },
        setCacheAccountId(state, accountId) {
            state.cacheAccountId = accountId
        },
        setUnionId(state, unionId) {
            state.unionId = unionId
        },
        setDoctorWholeObj(state, doctorObj) {
            state.doctorWholeObj = doctorObj
        },
        setBindDeviceObj(state, deviceObj) {
            state.bindDeviceObj = deviceObj
        },
        setApplyInfo(state, applyInfo) {
            state.applyInfo = applyInfo
        },
        setAdminDocId(state, adminDocId) {
            state.adminDocId = adminDocId
        },
        setInitUrl(state, url) {
            state.initUrl = url
        }
    },
    actions: {
        // 退出程序
        quit() {
            let accessType = store.state.accessType
            // APP
            if (constants.AccessType.APP == accessType) {
                utils.jsUseApp("appQuit")
            }
            // WECHAT
            else if (constants.AccessType.WECHAT == accessType) {
                wx.closeWindow()
            }
        }
    },
    modules: {}
})

export default store
