import { render, staticRenderFns } from "./PatientInfoRegistration.vue?vue&type=template&id=f73a18b6&scoped=true&"
import script from "./PatientInfoRegistration.vue?vue&type=script&lang=js&"
export * from "./PatientInfoRegistration.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f73a18b6",
  null
  
)

export default component.exports