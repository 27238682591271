<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr lh24">
      <!--输入验证码-->
      <p class="f22 mt50 fb">{{ $t("lang.uc_phoneAuth_inputSms") }}</p>
      <!--验证码已发送至-->
      <p class="f14 c_gray1 mt10">{{ $t("lang.uc_phoneAuth_smsSend") }}{{ mobile }}</p>
      <div class="pas_area mt20">
        <!-- 密码输入框 -->
        <van-password-input
            :value="smsCode"
            :gutter="10"
            :focused="showKeyboard"
            @focus="showKeyboard = true"
        />

        <!-- 数字键盘 -->
        <van-number-keyboard
            v-model="smsCode"
            :show="showKeyboard"
            @blur="showKeyboard = false"
        />
      </div>
      <div v-show="showCountDown" class="mt15 f13 c_gray of">
        <van-count-down ref="countDown" :auto-start="false" @finish="timeFinish" class="left mt2" format="ss"
                        :time="time"/>
        <!--秒后重新获取验证码-->
        <p class="left">{{ $t("lang.uc_phoneAuth_smsLast") }}</p>
      </div>
      <div v-show="!showCountDown" class="mt15 f13 c_gray">
        <!--重新获取验证码-->
        <a @click="getSmsCode">{{ $t("lang.uc_phoneAuth_reSendSms") }}</a>
      </div>
    </div>
  </section>
</template>

<script>
import {getSms, modDoctor} from "@/services/doctor";
import constants from "@/constants";
import {Toast} from 'vant';
import i18n from "@/lang";
import utils from "@/utils";

export default {
  name: "AuthCode",
  data() {
    return {
      showKeyboard: true,
      time: 60 * 1000,
      mobile: '',
      smsCode: '',
      encryptionCode: '',
      showCountDown: true,
    };
  },
  methods: {
    // 获取短信验证码
    getSmsCode: async function () {
      if (this.mobile) {
        let resultData = await getSms(this.mobile);
        if (constants.AJAX_OK === resultData.code && resultData.data) {
          this.showCountDown = true
          this.$refs.countDown.reset();
          this.$refs.countDown.start();

          this.encryptionCode = resultData.data
        }
      }
    },
    // 验证码超时
    timeFinish: function () {
      this.encryptionCode = ""
      this.showCountDown = false
    },
    // 错误提示
    errorTip: (tip) => {
      Toast({
        message: tip,
        position: 'bottom',
      });
    }
  },
  mounted() {
    this.doctor = this.$store.state.doctorWholeObj;    //缓存中的医生信息
    this.mobile = this.$route.params.mobile;    //修改后的新手机
    this.getSmsCode()
  },
  watch: {
    async smsCode(value) {
      if (value.length === 6) {
        // 验证验证码
        if (utils.md5_16(value) == this.encryptionCode) {
          this.doctor.mobile = this.mobile;
          let resultData = await modDoctor(this.doctor);
          //修改手机
          if (constants.AJAX_OK === resultData.code && resultData.data) {
            this.$store.commit("setDoctorWholeObj", resultData.data);
            this.doctor = resultData.data
            await this.$router.push("/userCenter");
            Toast(i18n.t("lang.uc_phoneAuth_success"));
          }
        } else {
          this.errorTip(i18n.t("lang.uc_phoneAuth_smsError"));
        }
      }
    },
  },
}
</script>

<style scoped>
.pas_area .van-password-input {
  margin: 0px;
}

.mt2 {
  margin-top: 2px;
}
</style>
