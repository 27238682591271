import Vue from 'vue'
import VueI18n from 'vue-i18n'
import storage from "@/utils/storage"
import constants from "@/constants";

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: storage.getItem(constants.LANGUAGE) || 'zh',    // 语言标识, 通过切换locale的值来实现语言切换,this.$i18n.locale
    messages: {
        'zh': require('./zh'),   // 中文语言包
        'en': require('./en')    // 英文语言包
    }
})

export default i18n
