// 缓存根名称
const CACHE_BASE = "AX_SED_";

const constants = {
    // AJAX请求成功
    AJAX_OK: 200,
    // 登录超时
    LOGIN_ERR: 10000,
    // 验证码错误
    SMS_ERR: 10001,
    // 该科室已有设备管理员
    HAVE_ADMIN: 10100,

    // 版本标识（新版本更新记得去更改标识）
    VERSION_FLAG: 2,

    // 缓存微信openId
    WX_OPENID: CACHE_BASE + "WX_OPENID",
    // 缓存微信accountId
    WX_ACCOUNT_ID: CACHE_BASE + "WX_ACCOUNT_ID",
    // 缓存语言设置
    LANGUAGE: CACHE_BASE + "LANGUAGE",

    CODE_STATE_TRUE: 0, //验证码有效
    CODE_STATE_FALSE: 1, //验证码无效或已过期
    CODE_STATE_EXISTS: 2, //该医生已在群组中，请勿重复加入

    SERVICE_NO_DEVICE: 301,  // 没有设备
    SERVICE_NO_BIND: 302,  // 设备没有关注
    SERVICE_BIND: 303,  // 医生已关注设备

    // 设备管理员
    DocDeviceAdmin: {
        // 无身份
        INIT: 0,
        // 管理员
        YES: 1,
        // 非管理员
        NO: 2,
    },

    // 趋势图类型 0 小时 1 天
    TrendType: {
        // 小时
        HOUR: 0,
        // 天
        DAY: 1,
    },

    // 访问来源
    AccessType: {
        // android/ios
        APP: 1,
        // 微网站
        WECHAT: 2
    },

    // 红点提示 1提示
    RedNote: {
        // 显示
        YES: 1,
    },

    //是否发送消息 1发送，2关闭
    MsgTips: {
        SEND: "1",
        CLOSE: "0",
    },

    // 微信网页授权常量
    WechatAuthorize: {
        // 获取code
        GET_CODE: "https://open.weixin.qq.com/connect/oauth2/authorize?appid={0}&redirect_uri={1}&response_type=code&scope={2}#wechat_redirect",
        // 不弹出授权页面，直接跳转，只能获取用户openid
        SCOPE_BASE: "snsapi_base",
        // 弹出授权页面，可通过openid拿到昵称、性别、所在地。并且， 即使在未关注的情况下，只要用户授权，也能获取其信息
        SCOPE_USERINFO: "snsapi_userinfo",
        // 获取token
        GET_TOKEN: "https://api.weixin.qq.com/sns/oauth2/access_token?appid={0}&secret={1}&code={2}&grant_type=authorization_code",
        // 获取用户信息
        GET_USERINFO: "https://api.weixin.qq.com/sns/userinfo?access_token={0}&openid={1}&lang=zh_CN"
    }
}

export default constants
