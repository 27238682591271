<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr lh24">
      <p class="f22 mt50 fb">{{ $t("lang.infoSure") }}</p>
      <p class="f14 c_gray1 mt10">{{ $t("lang.infoSureTip") }}</p>
      <div class="bb_gray mt30 f14 of p15 lh32">
        <p class="left">{{ $t("lang.instName") }}</p>
        <p class="right c_gray1">{{ $store.state.applyInfo.inst.instName }}</p>
      </div>
      <div class="bb_gray f14 of p15 lh32">
        <p class="left">{{ $t("lang.dep") }}</p>
        <p class="right c_gray1">{{ $store.state.applyInfo.depName }}</p>
      </div>
      <van-button class="mt30 fb" type="primary" block @click="ensureInfo">{{$t("lang.device_his_confirmsubmit")}}</van-button>
    </div>
  </section>
</template>

<script>
import {Dialog} from 'vant';
import utils from "@/utils";
import {applyAdmin} from "@/services/doctor";
import constants from "@/constants";
import i18n from "@/lang";

export default {
  methods: {
    ensureInfo: async function () {
      let doctor = utils.deepClone(this.$store.state.doctorWholeObj)
      doctor.deptName = this.$store.state.applyInfo.depName
      doctor.inst = this.$store.state.applyInfo.inst

      let resultData = await applyAdmin(doctor)
      if (constants.AJAX_OK === resultData.code && resultData.data) {
        this.$store.commit("setDoctorWholeObj", resultData.data)
        await this.$router.push("/deviceCenter")
      } else if (constants.HAVE_ADMIN === resultData.code) {
        Dialog.alert({
          title: i18n.t("lang.applyFail"),
          message: i18n.t("lang.haveAdmin"),
        }).then(() => {
          // on close
        });
      }
    },
  },
}
</script>

<style scoped>
.van_bg_zdy1 .van-cell {
  background-color: #EDEDED;
  padding: 10px 0px;
}
</style>
