<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr">
      <div class="tc m20h">
        <img :src="require('@/assets/img/device_empty.png')" class="w60">
        <!--暂无设备-->
        <p class="f20 fb mt10" v-if="doctor.admin===1">{{ $t('lang.device_empty_nodv') }}</p>
        <!--暂无已关注设备-->
        <p class="f20 fb mt10" v-else>{{ $t('lang.device_empty_noattentdv') }}</p>
        <!--您是设备管理员，请绑定设备-->
        <p class="f14 mt5" v-show="doctor.admin===1">{{ $t('lang.device_empty_admintobind') }}</p>

        <van-button @click="onCLick2Bind" class="mt30" round type="primary" v-if="doctor.admin===1">
          <!-- 现在绑定-->
          <p class="fb p1020">{{ $t('lang.device_empty_nowbind') }}</p>
        </van-button>
        <van-button @click="onCLick2Add" class="mt30" round type="primary" v-else>
          <!--现在添加-->
          <p class="fb p1020">{{ $t('lang.device_empty_nowadd') }}</p>
        </van-button>
      </div>

      <!--选择添加方式  新绑定设备-->
      <van-popup v-model="binddv_show" round position="bottom" close-on-click-overlay>
        <van-cell-group>
          <!-- 扫码添加    扫描设备二维码，自动识别编码后完成添加-->
          <van-cell @click="onClick2QrBindDevice" center clickable is-link :title="$t('lang.device_add_scanqrbind')"
                    :label="$t('lang.device_add_scanqrnote')"/>
          <!--手动录入添加    动输入设备编码完成添加-->
          <van-cell @click="onClick2ManualBindDevice" center clickable is-link
                    :title="$t('lang.device_add_manualbind')" :label="$t('lang.device_add_manualbindnote')"/>
        </van-cell-group>
      </van-popup>
    </div>
  </section>
</template>

<script>
import {getDocDevice} from "@/services/dvDevice";
import {getAdminDocDevice} from "@/services/dvDoctor";
import utils from "@/utils";
import constants from "@/constants";
import {Toast} from "vant";
import i18n from "@/lang";
import {getInstById} from "@/services/inst";
import store from "@/store";

export default {
  async mounted() {
    // app调用js
    window.appScanReturn = this.onClick2GetDevice

    if (constants.DocDeviceAdmin.INIT == this.doctor.admin) {
      await this.$router.replace("/initHome")
    } else {
      let resultData = await getDocDevice(this.doctor.doctorId, this.doctor.admin);
      if (constants.AJAX_OK === resultData.code && resultData.data && utils.isNotNull(resultData.data)) {
        // 管理员
        if (constants.DocDeviceAdmin.YES == this.doctor.admin) {
          await this.$router.replace("/adminDeviceList")
        } else {
          await this.$router.replace("/followDeviceList")
        }
      } else {
        // 管理员
        if (constants.DocDeviceAdmin.YES == this.doctor.admin) {
          //获取医生所在的机构
          let resultData = await getInstById(this.doctor.instId);
          if (constants.AJAX_OK === resultData.code && resultData.data) {
            document.title = resultData.data.instName;
          }
        } else {
          document.title = i18n.t("lang.device_att");
        }
      }
    }
  },
  data() {
    return {
      show_phone: false,
      show_name: false,
      value: '',
      binddv_show: false, //新绑定设备弹窗
      doctor: this.$store.state.doctorWholeObj,
      accountId: this.$store.state.cacheAccountId
    };
  },
  methods: {
    // 管理员 现在添加
    onCLick2Bind: function () {
      this.binddv_show = true;
    },
    // 非管理员 现在绑定
    onCLick2Add: function () {
      this.$router.push("/addClassSel");
      // this.$router.push("/editDeviceInfo");
    },

    //手动录入绑定新设备
    onClick2ManualBindDevice() {
      this.binddv_show = false;
      this.$router.push("/addDeviceManual");
    },

    //扫码绑定新设备
    async onClick2QrBindDevice() {
      this.binddv_show = false;

      let accessType = this.$store.state.accessType
      // APP
      if (constants.AccessType.APP == accessType) {
        utils.jsUseApp("appScan")
      }
      // WECHAT
      else if (constants.AccessType.WECHAT == accessType) {
        // 扫描设备并跳转到页面
        let pageUrl = store.state.initUrl;
        await utils.scanQR(pageUrl, this.accountId, this.onClick2GetDevice);
      }
    },

    // 获取设备绑定信息
    onClick2GetDevice: async function (devicenum) {

      // 根据设备号获取设备
      let resultData = await getAdminDocDevice(this.doctor.doctorId, devicenum);
      console.log(resultData);
      if (constants.AJAX_OK === resultData.code || constants.SERVICE_NO_DEVICE === resultData.code) {

        Toast({
          message: i18n.t("lang.editNotFoundDevice"), //提示
          position: 'bottom',
        });

      } else if (constants.SERVICE_NO_BIND === resultData.code) {

        //有设备但是医生没绑定
        this.$store.commit("setBindDeviceObj", resultData.data);
        this.$router.push("/addDeviceBing");

      } else if (constants.SERVICE_BIND === resultData.code) {

        //有设备+医生绑定
        this.$store.commit("setBindDeviceObj", resultData.data);
        this.$router.push("/addedDeviceInfo");

      }
    },

  },
};
</script>

<style scoped>
.m20h {
  margin: 20vh auto auto auto;
}
</style>
