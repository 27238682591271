/**
 *
 * @desc 设置医生对象到缓存
 * @return {{expiryDate: string, instId: string, deptName: string, nameAlias: string, doctorId: string, inviteCode: string, mobile: string, admin: string, headPortrait: string, aliasSet: string}}
 */
function toForward(t, name, params) {
    if (params == null) {
        t.$router.push(name).then(r => r.name);
    } else {
        t.$router.push({name: name, params: params}).then(r => r.name);
    }
}

module.exports = toForward
