/**
 *
 * @desc 设置医生对象到缓存
 * @return {{expiryDate: string, instId: string, deptName: string, nameAlias: string, doctorId: string, inviteCode: string, mobile: string, admin: string, headPortrait: string, aliasSet: string}}
 */
function setDoctorCache(t, doctor) {
    t.$store.commit("setDoctorWholeObj", doctor);
}

module.exports = setDoctorCache
