import utils from "@/utils";
import url from "./index"

// 获取趋势图
export function getTrend(patientId, deviceId, prType, dfType, pfType, dContent) {
    return utils.getHttp(url.GET_TREND, {
        patientId: patientId,
        deviceId: deviceId,
        prType: prType,
        dfType: dfType,
        pfType: pfType,
        dContent: dContent,
    });
}
