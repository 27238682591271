<template>
  <section class="van-doc-demo-section">
    <div class="m15-00 dfr lh24">
      <!--手机验证码登录-->
      <p class="f22 mt50 fb">{{ $t("lang.mobileSmsLogin") }}</p>
      <!--登录前需在微信公众号"新跃医疗"-"胸腔引流"完成手机号激活-->
      <p class="f14 c-orange mt10">{{ $t("lang.mobileSmsLoginTip") }}</p>

      <!-- 手机 -->
      <div class="bb_gray mt30">
        <van-cell class="list_radius van_bg_zdy1">
          <van-field :label="$t('lang.loginMobile')" type="tel" :readonly="showCountDown" v-model="mobile"
                     :placeholder="$t('lang.pleaseInput')"
                     :error-message="mobileErrorTip"/>
        </van-cell>
      </div>

      <!-- 验证码获取前 -->
      <div class="bb_gray mt10">
        <van-cell class="list_radius van_bg_zdy1">
          <!-- 验证码 请输入 -->
          <van-field v-model="smsCode" type="password" center clearable :label="$t('lang.sms')"
                     :placeholder="$t('lang.pleaseInput')">
            <template #button>
              <!--发送验证码-->
              <van-button v-show="!showCountDown" @click="getSmsCode" size="small" type="primary">{{
                  smsMsg
                }}
              </van-button>

              <van-button v-show="showCountDown" size="small" class="of" type="default" disabled>
                <van-count-down class="left mt-3" ref="countDown" :auto-start="false" @finish="timeFinish" format="ss"
                                :time="time"/>
                <p class="left">{{ $t("lang.smsLast2") }}</p>
              </van-button>
            </template>
          </van-field>
        </van-cell>
      </div>

      <!-- 登录 -->
      <van-button @click="login" class="mt30" type="primary" block>{{ $t("lang.login") }}</van-button>

      <!--协议-->
      <div class="mt10 f12 c_gray">
        <p class="left">{{ $t("lang.loginAgree") }}</p>
        <van-button to="/serviceAgreement" class="left" size="mini" color="#EDEDED" type="primary">
          <span class="f12 c-blue1">{{ $t("lang.loginTerm") }}</span>
        </van-button>
        <p class="left">、</p>
        <van-button to="/privacyProtection" class="left" size="mini" color="#EDEDED" type="primary">
          <span class="f12 c-blue1">{{ $t("lang.loginPrivacy") }}</span>
        </van-button>
      </div>
    </div>
  </section>
</template>

<script>
import i18n from "@/lang";
import {getSms, getDoctorByMobile} from "@/services/doctor";
import {getOpenId} from "@/services/wxAccount";
import constants from "@/constants";
import {Toast} from "vant";
import utils from "@/utils";

export default {
  name: "LoginCode",
  // 直接访问appLogin
  mounted() {
    // 为扫码赋URL
    this.$store.commit("setInitUrl", window.location.href)

    let accessType = this.$route.query.accessType;
    let accountId = this.$route.query.accountId;
    if (accessType && accountId) {
      this.$store.commit("setAccessType", accessType);
      this.$store.commit("setCacheAccountId", accountId);
    }
  },
  data() {
    return {
      smsMsg: i18n.t("lang.sendSms"),
      time: 60 * 1000,
      mobile: '',
      smsCode: '',
      encryptionCode: '',
      showCountDown: false,
      mobileErrorTip: ''
    };
  },
  methods: {
    // 获取短信验证码
    getSmsCode: async function () {
      if (!utils.isPhoneNum(this.mobile)) {
        this.errorTip(i18n.t("lang.mobileInputError"));
        return;
      }

      this.mobileErrorTip = ""

      let resultData = await getDoctorByMobile(this.mobile);
      if (constants.AJAX_OK === resultData.code && resultData.data && resultData.data.length > 0) {
        this.$store.commit("setDoctorWholeObj", resultData.data[0]);

        resultData = await getSms(this.mobile);
        if (constants.AJAX_OK === resultData.code && resultData.data) {
          this.showCountDown = true
          this.$refs.countDown.reset();
          this.$refs.countDown.start();

          this.encryptionCode = resultData.data
        }
      } else {
        this.mobileErrorTip = i18n.t("lang.mobileErrorTip")
      }
    },
    // 验证码超时
    timeFinish: function () {
      this.encryptionCode = ""
      this.showCountDown = false
      this.smsMsg = i18n.t("lang.reSendSms")
    },
    // 登录
    login: async function () {
      // 验证验证码
      if (utils.isNull(this.smsCode) || utils.md5_16(this.smsCode) !== this.encryptionCode) {
        this.errorTip(i18n.t("lang.smsError"));
      } else {
        let doc = this.$store.state.doctorWholeObj
        let accountId = this.$store.state.cacheAccountId
        let resultData = await getOpenId(accountId, doc.doctorId);
        if (constants.AJAX_OK === resultData.code && resultData.data) {
          let openId = resultData.data.openId
          this.$store.commit("setCacheOpenId", openId)
          // 保存openId，accountId进入缓存
          utils.setStorage(constants.WX_OPENID, openId);
          utils.setStorage(constants.WX_ACCOUNT_ID, accountId);

          await this.$router.push("/deviceCenter")
        }
      }
    },
    // 错误提示
    errorTip: (tip) => {
      Toast({
        message: tip,
        position: 'bottom',
      });
    },
  }
}
</script>

<style scoped>
.van_bg_zdy1 .van-cell {
  background-color: #EDEDED;
  padding: 10px 0px;
}

.mt-3 {
  margin-top: -3px;
}
</style>
